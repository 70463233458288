import { Filter } from './filter.model';
import { Range } from './range.model';

export class AnalysisAggregatedThreatFilterModel {
	public 'attackId'?: string;
	public 'showScenarios'?: boolean;
	public 'singleLossAmount'!: Filter<Range<number>>;
	public 'annualizedLossAmount'!: Filter<Range<number>>;
	public 'probabilityOfSuccess'!: Filter<Range<number>>;
	public 'lossEventFrequency'!: Filter<Range<number>>;
}
