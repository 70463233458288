export const CACHE_KEY = {
	Token: 'token',
	Report: 'report',
	Notifications: 'notifications',
	ScenarioComparisonId: 'scenarioComparisonId',
	RiskAnalysisHasScenario: 'riskAnalysisHasScenario',
	RiskAnalysisLastStatus: 'riskAnalysisLastStatus',
	SidebarExpanded: 'sidebar-expanded',
	ApiPerformanceLogs: 'apiPerformanceLogs'
};
