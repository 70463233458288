<rq-card [title]="'Key Risks by Likelihood'"
         [isFlexHeight]="true"
         [isLoaded]="isComponentLoaded"
         [isNoDataVisible]="!chartConfig">
  <ng-template rqCardAction>
    <rq-dropdown class="dropdown-xs widget__dropdown"
                 [options]="dropdownOptions"
                 (ngModelChange)="dropdownOptionChange($event)"
                 [(ngModel)]="selectedOption">
    </rq-dropdown>
  </ng-template>
  <ng-template rqCardContent>
    <rq-count-legend [data]="legendData"></rq-count-legend>

    <rq-card [isContent]="true"
             [isLoaded]="chartData"
             minLoadingHeight="400px">
      <ng-template rqCardContent>
        <rq-bubble-chart *ngIf="chartData && isComponentLoaded"
                         [config]="chartConfig"
                         [data]="chartData">
        </rq-bubble-chart>
      </ng-template>
    </rq-card>
  </ng-template>
</rq-card>
