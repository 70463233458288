import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
	selector: 'rq-filter-number',
	templateUrl: './filter-number.component.html',
	styleUrls: ['./filter-number.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterNumberComponent),
			multi: true
		}
	]
})
export class FilterNumberComponent implements ControlValueAccessor, OnInit {
	@ViewChild(NgForm)
	public form!: NgForm;

	@Input()
	public trigger: 'change' | 'blur' = 'blur';

	@Input()
	public isDisabled!: boolean;

	@Input()
	public isFormatted = true;

	@Input()
	public allowNegativeNumbers = false;

	@Input()
	public decimals!: number;

	@Input()
	public prefix = '';

	@Input()
	public suffix = '';

	public innerValue!: number | null;

	public inputMask!: string;

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	private inputEl!: HTMLInputElement;

	public get hasValue() {
		return Boolean(this.value?.toString()?.length);
	}

	public get value(): number | null {
		return this.innerValue;
	}

	@Input()
	public set value(val: number | null) {
		const parseValue = this.parseValue(val?.toString());

		if (parseValue !== this.value && !isNaN(<number>parseValue)) {
			this.innerValue = parseValue as number | null;
		}
	}

	@ViewChild('inputEl', { static: false })
	public set inputElement(element: ElementRef) {
		if (element) {
			this.inputEl = element.nativeElement as HTMLInputElement;
		}
	}

	@HostListener('click')
	public setTouchedState() {
		this.onTouchedCallback(this.value);
	}

	public ngOnInit() {
		this.setupInputMask();
	}

	public writeValue(value: number | null) {
		this.innerValue = value;
	}

	public triggerFilterChange(trigger: 'change' | 'blur' = 'blur') {
		if (!this.form.dirty) {
			return;
		}

		if (this.trigger === trigger) {
			this.onChangeCallback(this.value);
			this.form.form.markAsPristine();
		}
	}

	public triggerChangeOnEnter(keyEvent: KeyboardEvent) {
		if (keyEvent.key === 'Enter') {
			const input = keyEvent.target as HTMLInputElement;
			this.form.form.markAsDirty();
			input?.blur();
		}
	}

	public clear(e: Event) {
		e.preventDefault();
		this.value = null;
		this.form.form.markAsDirty();
		if (this.inputEl !== document.activeElement) {
			this.triggerFilterChange();
		}
	}

	public registerOnChange(fn: (value: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value: unknown) => void): void {
		this.onTouchedCallback = fn;
	}

	private parseValue(val: string | number | undefined) {
		let parseValue = null;

		if (!val) {
			return parseValue;
		} else {
			parseValue = this.isFormatted ? parseFloat((<string>val).replace(/,/g, '')) : val;
		}

		return parseValue;
	}

	private setupInputMask() {
		this.inputMask = 'separator';

		if (this.decimals !== undefined) {
			this.inputMask += `.${this.decimals}`;
		}
	}
}
