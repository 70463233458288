<div class="title-group"
     (click)="sort()"
     [class.pointer]="isSortable">
  <div class="title"
       [title]="column.title | translate">
    {{ column.title | translate }}
  </div>
  <div class="sort"
       *ngIf="isSortable">
    <rq-icon *ngIf="isSortedDescending"
             color="color-primary"
             href="icon-arrow-down"></rq-icon>
    <rq-icon *ngIf="isSortedAscending"
             color="color-primary"
             href="icon-arrow-up"></rq-icon>
  </div>
</div>
