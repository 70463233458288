import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttackNavigatorItemViewModel } from '../../../../shared/models/attack-navigator-item.viewmodel';

@Component({
	selector: 'rq-attack-navigator-items',
	templateUrl: './attack-navigator-items.component.html',
	styleUrls: ['./attack-navigator-items.component.scss']
})
export class AttackNavigatorItemsComponent {
	@Input()
	public items?: Array<AttackNavigatorItemViewModel>;

	@Input()
	public isSubitem = false;

	@Input()
	public hasCheckboxSelection!: boolean;

	@Input()
	public isReadOnly = false;

	@Input()
	public showFinancialValue!: boolean;

	@Input()
	public hasDetails = false;

	@Output()
	public readonly selectChange = new EventEmitter<{ item: AttackNavigatorItemViewModel; isSelected: boolean }>();

	@Output()
	public readonly showDetailsAction = new EventEmitter<AttackNavigatorItemViewModel>();

	public toggleItem(item: AttackNavigatorItemViewModel) {
		item.isExpanded = !item.isExpanded;
	}

	public triggerSelectChange(item: AttackNavigatorItemViewModel, isSelected: boolean) {
		this.selectChange.emit({ item, isSelected });
	}

	public showDetails(item: AttackNavigatorItemViewModel) {
		if (!this.hasDetails) {
			return;
		}
		this.showDetailsAction.emit(item);
	}
}
