<div class="filter-group filter-selected"
     *ngIf="filter.groups?.length">
  <ng-container *ngFor="let group of filter.groups">
    <strong class="truncate-1 m-y-sm"
            *ngIf="group.title">{{group.title | translate}}</strong>
    <ng-container *ngFor="let option of group.options">
      <div class="filter-item">
        <label class="truncate-1">
          <input type="checkbox"
                 [disabled]="isDisabled"
                 (ngModelChange)="triggerSelectChange()"
                 [(ngModel)]="option.isSelected" />
          <span [title]="option.displayName">{{option.displayName}}</span>
        </label>
      </div>
    </ng-container>
  </ng-container>
</div>

<section class="filter-range-content m-t-lg">
  <rq-slider name="slider-filter"
             #slider
             [options]="sliderOptions"
             [delayResponse]="0"
             [(ngModel)]="sliderModel"
             (ngModelChange)="onSliderChange()"
             [formatter]="formatter">
  </rq-slider>

  <form [formGroup]="form"
        class="m-t-md">
    <div class="form-group m-b-none">
      <rq-form-control>
        <label>{{'global_minimum_label' | translate}}</label>
        <rq-input type="number"
                  size='xs'
                  [areNegativeValuesAllowed]="true"
                  [prefix]='formatterSymbol'
                  formControlName="min"
                  (blur)='blur()'
                  (keyup.enter)='triggerInputChange()'>
        </rq-input>
      </rq-form-control>
    </div>
    <div class="form-group m-b-none">
      <rq-form-control>
        <label>{{'global_maximum_label' | translate}}</label>
        <rq-input type="number"
                  size='xs'
                  [areNegativeValuesAllowed]="true"
                  [prefix]='formatterSymbol'
                  formControlName="max"
                  (blur)='blur()'
                  (keyup.enter)='triggerInputChange()'>
        </rq-input>
      </rq-form-control>
    </div>
  </form>
</section>

<section class="actions-wrapper m-t-sm">
  <button type="button"
          e2e-test="clear-button"
          class="btn-secondary-sm action-button"
          [class.disabled]="isDisabled"
          (click)="clear()">Clear</button>
  <button type="button"
          e2e-test="search-button"
          class="btn-primary-sm action-button"
          [class.disabled]="isDisabled || !isValidSearch"
          (click)="search()">Search</button>
</section>
