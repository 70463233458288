import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { NotificationType } from '../../../../server/enums/notification-type.enum';
import { NotificationListViewModel } from '../../../../shared/models/notification-list.viewmodel';
import { PaginationFilter } from '../../../../server/models/pagination-filter.model';
import { NotificationService } from '../../../../shared/services/notification.service';
import { BaseComponent } from '../../../../shared/components/base.component';
import { Customizer } from '../../../services/customizer';
import { ApplicationInstanceStore } from '../../../core/application.service';

@Component({
	selector: 'rq-notification-popup',
	templateUrl: './notification-popup.component.html',
	styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent extends BaseComponent implements OnInit {
	@Input()
	public newNotificationsCount = 0;

	@Output()
	public readonly navigateToViewAll = new EventEmitter();

	@Output()
	public readonly navigateToNotification = new EventEmitter<NotificationListViewModel>();

	public totalNotifications!: number;

	public notificationList: Array<NotificationListViewModel> = [];

	public NotificationType = NotificationType;

	public isLoading = true;

	public hasPageAccess = false;

	private pagination: PaginationFilter = { skip: 0, take: 5 };

	constructor(
		public customizer: Customizer,
		private notificationService: NotificationService,
		private applicationInstanceStore: ApplicationInstanceStore
	) {
		super();
	}

	public async ngOnInit() {
		this.subscribeToNotificationEvents();

		this.hasPageAccess = this.applicationInstanceStore.isEnterprise || this.applicationInstanceStore.isPro;

		await this.notificationService.loadNotifications(this.pagination, this.applicationInstanceStore.isEnterprise);
	}

	public async onScroll() {
		if (this.isLoading || this.notificationList.length >= this.totalNotifications) {
			return;
		}
		this.isLoading = true;

		await this.notificationService.loadNotifications(this.pagination);
	}

	public goToNotification(item: NotificationListViewModel) {
		if (this.hasPageAccess) {
			this.navigateToNotification.emit(item);
		}
	}

	public viewAll() {
		this.navigateToViewAll.emit();
	}

	public markAllAsSeen() {
		this.notificationService.markAllAsSeen();
		this.notificationList.forEach(x => (x.isSeen = true));
	}

	private setData(values: Array<NotificationListViewModel>) {
		this.notificationList = [...(this.notificationList ?? []), ...values];
		this.pagination.skip += this.pagination.take;
		this.isLoading = false;
	}

	private subscribeToNotificationEvents() {
		this.subscriptions.push(
			this.notificationService.notificationsData$.subscribe(value => {
				this.totalNotifications = value.count;
				this.setData(value.values);
			})
		);

		this.subscriptions.push(
			this.notificationService.increaseNotificationCount$.subscribe(value => {
				this.newNotificationsCount += value;
			})
		);
	}
}
