import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { PopoverComponent } from './components/popover/popover.component';
import { PopoverContentDirective } from './components/popover/popover.directive';
import { ModalComponent } from './components/modal/modal.component';
import { ModalBodyDirective, ModalFooterDirective, ModalHeaderDirective } from './components/modal/modal.directive';
import { ExpandableTextComponent } from './components/expandable-text/expandable-text.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogBodyDirective, DialogTitleDirective } from './components/dialog/dialog.directive';
import { ComparisonComponent } from './components/comparison/comparison.component';
import { ComparisonCollapsibleComponent } from './components/comparison/comparison-collapsible/comparison-collapsible.component';
import { ComparisonListComponent } from './components/comparison/comparison-list/comparison-list.component';
import { ComparisonTableComponent } from './components/comparison/comparison-table/comparison-table.component';
import { ComparerComponent } from './components/comparer/comparer.component';
import { ComparerCardComponent } from './components/comparer/comparer-card/comparer-card.component';
import { ComparerButtonComponent } from './components/comparer/comparer-button/comparer-button.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertBodyDirective, AlertTitleDirective } from './components/alert/alert.directive';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TableDataDirective } from './components/table/table-data.directive';
import { TableActionDirective } from './components/table/table-action.directive';
import { TableActionsComponent } from './components/table/table-actions/table-actions.component';
import { TableMoreActionsComponent } from './components/table/table-actions/table-more-actions/table-more-actions.component';
import { TableColumnComponent } from './components/table/table-column/table-column.component';
import { TableComponent } from './components/table/table.component';
import { TableHeadDataComponent } from './components/table/table-head-data/table-head-data.component';
import { LoadingBoxComponent } from './components/loading-box/loading-box.component';
import { TableSkeletonComponent } from './components/loading-box/table-skeleton/table-skeleton.component';
import { OverviewSkeletonComponent } from './components/loading-box/overview-skeleton/overview-skeleton.component';
import { ChartSkeletonComponent } from './components/loading-box/chart-skeleton/chart-skeleton.component';
import { CardSkeletonComponent } from './components/loading-box/card-skeleton/card-skeleton.component';
import { PagerComponent } from './components/pager/pager.component';
import { MathOperatorDropdownComponent } from './components/form-elements/math-operator-dropdown/math-operator-dropdown.component';
import { FileUploadComponent } from './components/form-elements/file-upload/file-upload.component';
import { SwitchComponent } from './components/form-elements/switch/switch.component';
import { TimePickerComponent } from './components/form-elements/time-picker/time-picker.component';
import { NgxMaskModule } from 'ngx-mask';
import { RqTimePipe } from './pipes/rq-time.pipe';
import { WizardComponent } from './components/wizard/wizard.component';
import { LoadingInlineDirective } from './directives/loading-inline.directive';
import { RouterModule } from '@angular/router';
import { PreventDefaultDirective } from './directives/prevent-default.directive';
import { CalendarComponent } from './components/form-elements/calendar/calendar.component';
import { FilterComponent } from './components/form-elements/filter/filter.component';
import { FilterSelectComponent } from './components/form-elements/filter/filter-select/filter-select.component';
import { FilterDropdownComponent } from './components/form-elements/filter/filter-dropdown/filter-dropdown.component';
import { FilterCalendarComponent } from './components/form-elements/filter/filter-calendar/filter-calendar.component';
import { FilterNumericComponent } from './components/form-elements/filter/filter-numeric/filter-numeric.component';
import { FilterRangeComponent } from './components/form-elements/filter/filter-range/filter-range.component';
import { FilterSearchComponent } from './components/form-elements/filter/filter-search/filter-search.component';
import { DatePickerComponent } from './components/form-elements/date-picker/date-picker.component';
import { DropdownComponent } from './components/form-elements/dropdown/dropdown.component';
import { SliderComponent } from './components/form-elements/slider/slider.component';
import { MultipleInputComponent } from './components/form-elements/multiple-input/multiple-input.component';
import { InputComponent } from './components/form-elements/input/input.component';
import { InputSpinnerComponent } from './components/form-elements/input/input-spinner/input-spinner.component';
import { FormGroupComponent } from './components/form-elements/form-group/form-group.component';
import { RqFormGroupDirective } from './components/form-elements/form-group/form-group.directive';
import { FormControlComponent } from './components/form-elements/form-control/form-control.component';
import { CardLayoutComponent } from './components/card-layout/card-layout.component';
import { CardLayoutMenuTabComponent } from './components/card-layout/card-layout-menu-tab/card-layout-menu-tab.component';
import { CardLayoutItemDirective } from './components/card-layout/card-layout-item.directive';
import { CardLayoutItemTitleDirective } from './components/card-layout/card-layout-item-title.directive';
import { CardLayoutItemHeaderDirective } from './components/card-layout/card-layout-item-header.directive';
import { CardLayoutMenuHeaderTabComponent } from './components/card-layout/card-layout-menu-header-tab/card-layout-menu-header-tab.component';
import { AreaChartComponent } from './components/charts/area-chart/area-chart.component';
import { BubbleChartComponent } from './components/charts/bubble-chart/bubble-chart.component';
import { CardTreeComponent } from './components/charts/card-tree/card-tree.component';
import { DependencyTreeComponent } from './components/charts/dependency-tree/dependency-tree.component';
import { DonutChartComponent } from './components/charts/donut-chart/donut-chart.component';
import { GaugeHalfChartComponent } from './components/charts/gauge-half-chart/gauge-half-chart.component';
import { GaugeRingChartComponent } from './components/charts/gauge-ring-chart/gauge-ring-chart.component';
import { HeatmapWithScatterPlotsChartComponent } from './components/charts/heatmap-with-scatter-plots-chart/heatmap-with-scatter-plots-chart.component';
import { LegendBarChartComponent } from './components/charts/legend-bar-chart/legend-bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { ProgressBarComponent } from './components/charts/progress-bar/progress-bar.component';
import { RangeChartComponent } from './components/charts/range-chart/range-chart.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { RqDatePipe } from './pipes/rq-date.pipe';
import { IconComponent } from './components/icon/icon.component';
import { TableHeadFilterComponent } from './components/table/table-head-filter/table-head-filter.component';
import { TableUiComponent } from './components/table/table-ui.component';
import { CardComponent } from './components/card/card.component';
import { CardTitleDirective } from './components/card/card-title.directive';
import { CardHeaderDirective } from './components/card/card-header.directive';
import { WidgetContentDirective } from './components/card/card-content.directive';
import { ListItemsComponent } from './components/list-items/list-items.component';
import { ListHeaderDirective } from './components/list-items/list-header.directive';
import { ListContentDirective } from './components/list-items/list-content.directive';
import { ThreeDotsMenuComponent } from './components/three-dots-menu/three-dots-menu.component';
import { ThreeDotsMenuItemComponent } from './components/three-dots-menu/three-dots-menu-item.component/three-dots-menu-item.component';
import { ThreeDotsMenuCustomNameDirective } from './components/three-dots-menu/directives/three-dots-menu-item-custom.directive';
import { ThreeDotsMenuElementDirective } from './components/three-dots-menu/directives/three-dots-menu-element.directive';
import { ThreeDotsMenuItemsComponent } from './components/three-dots-menu/three-dots-menu-items/three-dots-menu-items.component';
import { TabContentDirective } from './components/tabs/tab-content.directive';
import { CardActionDirective } from './components/card/card-action.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { CardNoDataContentDirective } from './components/card/card-no-data-content.directive';
import { E2eDirective } from './directives/e2e.directive';
import { TableActionItemComponent } from './components/table/table-actions/table-action-item/table-action-item.component';
import { SplitBarComponent } from './components/split-bar/split-bar.component';
import { FilterStringComponent } from './components/table/table-head-filter/filter-string/filter-string.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FilterNumberComponent } from './components/table/table-head-filter/filter-number/filter-number.component';
import { TableFilterDropdownComponent } from './components/table/table-head-filter/table-filter-dropdown/table-filter-dropdown.component';
import { TableFilterRangeComponent } from './components/table/table-head-filter/table-filter-range/table-filter-range.component';
import { TableFilterSelectComponent } from './components/table/table-head-filter/table-filter-select/table-filter-select.component';
import { TableFilterCalendarComponent } from './components/table/table-head-filter/table-filter-calendar/table-filter-calendar.component';
import { SingleProgressBarComponent } from './components/new-charts/single-progress-bar/single-progress-bar.component';
import { RqPercentPipe } from './pipes/rq-percent.pipe';
import { RqCurrencyPipe } from './pipes/rq-currency.pipe';
import { RqNumberPipe } from './pipes/rq-number.pipe';
import { CustomBarChartComponent } from './components/new-charts/custom-bar-chart/custom-bar-chart.component';
import { NotAvailableSignComponent } from './components/card/not-available-sign/not-available-sign.component';
import { NewBarChartComponent } from './components/new-charts/new-bar-chart/new-bar-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';

const components = [
	TabsComponent,
	TabComponent,
	PopoverComponent,
	PopoverContentDirective,
	ModalComponent,
	ModalBodyDirective,
	ModalHeaderDirective,
	ModalFooterDirective,
	ExpandableTextComponent,
	DialogComponent,
	DialogTitleDirective,
	DialogBodyDirective,
	ComparisonComponent,
	ComparerComponent,
	AlertComponent,
	AlertTitleDirective,
	AlertBodyDirective,
	AccordionComponent,
	TableDataDirective,
	TableActionDirective,
	TableActionsComponent,
	TableMoreActionsComponent,
	TableColumnComponent,
	TableComponent,
	TableUiComponent,
	LoadingBoxComponent,
	PagerComponent,
	MathOperatorDropdownComponent,
	FileUploadComponent,
	SwitchComponent,
	TimePickerComponent,
	WizardComponent,
	FilterComponent,
	FilterDropdownComponent,
	DatePickerComponent,
	DropdownComponent,
	SliderComponent,
	MultipleInputComponent,
	InputComponent,
	RqFormGroupDirective,
	FormGroupComponent,
	FormControlComponent,
	CardLayoutComponent,
	CardLayoutItemDirective,
	CardLayoutItemTitleDirective,
	CardLayoutItemHeaderDirective,
	AreaChartComponent,
	BarChartComponent,
	BubbleChartComponent,
	CardTreeComponent,
	DependencyTreeComponent,
	DonutChartComponent,
	GaugeHalfChartComponent,
	GaugeRingChartComponent,
	HeatmapWithScatterPlotsChartComponent,
	LegendBarChartComponent,
	LineChartComponent,
	PieChartComponent,
	ProgressBarComponent,
	RangeChartComponent,
	CardComponent,
	CardTitleDirective,
	CardHeaderDirective,
	CardActionDirective,
	WidgetContentDirective,
	CardNoDataContentDirective,
	ListItemsComponent,
	ListHeaderDirective,
	ListContentDirective,
	ThreeDotsMenuComponent,
	ThreeDotsMenuItemComponent,
	ThreeDotsMenuCustomNameDirective,
	ThreeDotsMenuElementDirective,
	ThreeDotsMenuItemsComponent,
	TabContentDirective,
	SplitBarComponent,
	TableActionItemComponent,
	SingleProgressBarComponent,
	CustomBarChartComponent,
	NewBarChartComponent
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxSliderModule,
		RouterModule,
		NgxMaskModule.forRoot(),
		EllipsisDirective,
		TranslatePipe,
		RqDatePipe,
		RqTimePipe,
		RqPercentPipe,
		RqCurrencyPipe,
		RqNumberPipe,
		LoadingInlineDirective,
		PreventDefaultDirective,
		E2eDirective,
		ClickOutsideDirective,
		IconComponent,
		LoaderComponent
	],
	declarations: [
		...components,
		ComparisonCollapsibleComponent,
		ComparisonListComponent,
		ComparisonTableComponent,
		ComparerCardComponent,
		ComparerButtonComponent,
		TableHeadDataComponent,
		TableSkeletonComponent,
		OverviewSkeletonComponent,
		ChartSkeletonComponent,
		CardSkeletonComponent,
		CalendarComponent,
		FilterSelectComponent,
		FilterCalendarComponent,
		FilterNumericComponent,
		FilterRangeComponent,
		FilterSearchComponent,
		InputSpinnerComponent,
		CardLayoutMenuTabComponent,
		CardLayoutMenuHeaderTabComponent,
		TableHeadFilterComponent,
		FilterStringComponent,
		FilterNumberComponent,
		FilterDropdownComponent,
		TableFilterDropdownComponent,
		TableFilterRangeComponent,
		TableFilterSelectComponent,
		TableFilterCalendarComponent,
		NotAvailableSignComponent,
		NewBarChartComponent
	],
	exports: [...components]
})
export class StandaloneModule {}
