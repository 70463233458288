<button *ngIf="canBeExpanded"
        class="btn-icon-sm btn-collapse"
        (click)="toggleSidebar()">
  <rq-icon [href]="isSidebarExpanded ? 'icon-arrow-exit' : 'icon-arrow-enter'"> </rq-icon>
</button>

<main class="navigation-sidebar"
      [class.hovered]="!isSidebarExpanded"
      [@sidebarAnimation]="isSidebarExpanded ? 'expanded' : 'collapsed'">
  <section class="navigation-sidebar__content">
    <ng-content select="[navigation-info-content]"></ng-content>

    <article class="navigation-sidebar__content_items">
      <ng-container *ngFor="let item of navigationList">
        <rq-navigation-item [item]="item"
                            e2e-test="menu-{{ item.name }}"
                            [states]="navigationStates">
        </rq-navigation-item>
      </ng-container>
    </article>

    <article class="navigation-sidebar__content_footer"
             *ngIf="navigationFooterItem">
      <rq-navigation-item [item]="navigationFooterItem"
                          [version]="version"
                          [isBeta]="isBeta"
                          e2e-test="menu-{{ navigationFooterItem.name }}"> </rq-navigation-item>
    </article>
  </section>
</main>
