import { Component, OnInit } from '@angular/core';
import { LongTermRecommendationsDisplayModel } from '../_common/models/long-term-recommendations.displaymodel';
import { RecommendationDataService } from 'src/app/server/services/analysis/recommendation-data.service';
import { LastAnalysisStore } from 'src/app/routes/control-analysis/_common/store/last-analysis.store';
import { RecommendationCostFilterModel } from 'src/app/server/models/recommendation-cost-filter-model.model';
import { WidgetsService } from '../widgets.service';
import { TranslationService } from '../../services/translation.service';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../../configs/routes.config';
import { BaseComponent } from '../../components/base.component';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';

@Component({
	selector: 'rq-long-term-recommendations-widget',
	templateUrl: './long-term-recommendations.widget.html',
	styleUrls: ['./long-term-recommendations.widget.scss']
})
export class LongTermRecommendationsWidget extends BaseComponent implements OnInit {
	public data!: Array<LongTermRecommendationsDisplayModel> | null;

	public tableCount!: number;

	constructor(
		private recommendationDataService: RecommendationDataService,
		private translationService: TranslationService,
		private router: Router,
		private lastAnalysisStore: LastAnalysisStore,
		private widgetService: WidgetsService
	) {
		super();
	}

	private get lastAnalysisId() {
		return this.lastAnalysisStore.lastAnalysis?.id ?? '';
	}

	public async ngOnInit() {
		this.subscribeToRefresh();
		await this.initData();
		this.isComponentLoaded = true;
	}

	public viewAll() {
		if (this.urlLegalEntityId && this.lastAnalysisId) {
			this.router.navigateByUrl(RQ_ROUTES.pro.recommendations.tabs.longTerm.url(this.urlLegalEntityId));
		}
	}

	private async initData() {
		const data = await this.recommendationDataService.getByAnalysis(
			this.lastAnalysisId,
			{ skip: 0, take: 5 },
			new RecommendationCostFilterModel()
		);
		this.data = data.values.map(item => {
			return <LongTermRecommendationsDisplayModel>{
				controlName: this.translationService.get(item.titleKey),
				controlContext: this.translationService.get(item.nameKey),
				controlType: item.controlSubType,
				oldControl: parseFloat(item.oldValue),
				proposedControl: parseFloat(item.proposedValue),
				averageAnnualImpactReduction: item.expectedLossReduction,
				likelihoodReduction: item.expectedLossProbabilityReduction
			};
		});

		this.tableCount = data.values.length;
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetService.refresh$.subscribe((type: UiWidgetType) => {
				if (type === UiWidgetType.LongTermRecommendations) {
					this.data = null;
					this.initData();
				}
			})
		);
	}
}
