<rq-table [dataSource]="data"
          [pagerState]="{skip: 0, take: 5}"
          [hasFilters]="false"
          [isSortable]="false"
          [count]="count"
          (pageChange)="load($event)">
  <rq-table-column field="name"
                   title="Name">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-link [name]="dataItem.name"
               (click)="redirectToDetails(dataItem)"></rq-link>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="attackType"
                   title="Attack">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.attackType }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="maximumImpact"
                   title="Maximum Impact">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.maximumImpact | rqCurrency }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="likelihood"
                   title="Likelihood">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-single-progress-bar [maxValue]="100"
                              [value]="dataItem.likelihood"
                              value.formatter="percent"></rq-single-progress-bar>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="frequency"
                   title="Frequency">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.frequency }}</span>
    </ng-template>
  </rq-table-column>

</rq-table>
