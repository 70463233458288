import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterCalendarValue, FilterRangeCalendarEnum, FilterRangeSelectOption } from '../../../form-elements/filter/filter.model';
import { CalendarDayModel } from '../../../form-elements/calendar/calendar.models';
import { DateFormatterService } from '../../../../../shared/services/date-formatter.service';
import { Customizer } from '../../../../../shared/services/customizer';

@Component({
	selector: 'rq-table-filter-calendar',
	templateUrl: './table-filter-calendar.component.html',
	styleUrls: ['./table-filter-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TableFilterCalendarComponent),
			multi: true
		}
	]
})
export class TableFilterCalendarComponent {
	@Input()
	public options: FilterRangeSelectOption[] = [];

	@Input()
	public isDisabled!: boolean;

	@Input()
	public format!: string;

	@Input()
	public min!: Date;

	@Input()
	public max!: Date;

	public savedState?: { start: Date; end: Date };

	public start!: Date;

	public end?: Date;

	public hoveredDate!: CalendarDayModel;

	public calendarModel!: { start?: string; end?: string };

	private onChangeCallback!: (_: unknown) => void;

	private previousOptionSelection?: FilterRangeSelectOption;

	private onTouchedCallback!: (_: unknown) => void;

	private _value!: { start?: string; end?: string } | null;

	constructor(private customizer: Customizer, private cd: ChangeDetectorRef, private dateService: DateFormatterService) {}

	public get isCustomRange() {
		return this.options.filter(o => o.value === FilterRangeCalendarEnum.Custom)[0].isSelected;
	}

	@HostBinding('class.custom-wrapper')
	public get hasCustomWrapper() {
		return this.isCustomRange;
	}

	public get value() {
		return this._value;
	}

	public set value(value: { start?: string; end?: string } | null) {
		this._value = value;
	}

	@HostListener('click')
	public setTouchedState() {
		this.onTouchedCallback(this.value);
	}

	public ngAfterViewInit() {
		this.cd.detectChanges();
	}

	public ngOnInit() {
		this.setupOptions();

		this.setupDefaults();

		this.resetRange();
	}

	public getSelectedOption() {
		return this.options.filter(o => o.isSelected)[0];
	}

	public writeValue(value: FilterCalendarValue): void {
		if (!value) {
			return;
		}

		this.previousOptionSelection = this.getSelectedOption();
		this.options.forEach(option => (option.isSelected = option.value === value.occurrence));

		this.options.forEach(option => {
			if (option.isSelected && !this.isCustomRange) {
				this.resetRange();
			}
		});
	}

	public triggerChange(option: FilterRangeSelectOption) {
		this.writeValue({ occurrence: option.value as FilterRangeCalendarEnum });

		if (option.value === FilterRangeCalendarEnum.AllTime) {
			this.updateModel(null);
		} else if (option.value !== FilterRangeCalendarEnum.Custom) {
			const model = <FilterCalendarValue>{
				occurrence: option.value,
				start: option.timeCallback(),
				end: undefined
			};
			this.updateModel(model);
		}
	}

	public registerOnChange(fn: (value: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value: unknown) => void): void {
		this.onTouchedCallback = fn;
	}

	public closeFilter() {
		this.refreshData();

		if (!this.savedState && this.previousOptionSelection) {
			this.triggerChange(this.previousOptionSelection);
		}
	}

	public applySettings() {
		if (this.end) {
			this.updateModel(<FilterCalendarValue>{
				occurrence: this.options.filter(o => o.isSelected)[0].value,
				start: this.dateService.setUtcIsoString(
					{ year: this.start.getFullYear(), month: this.start.getMonth(), date: this.start.getDate() },
					{ isStartOfDay: true }
				),
				end: this.end
					? this.dateService.setUtcIsoString(
							{ year: this.end.getFullYear(), month: this.end.getMonth(), date: this.end.getDate() },
							{ isEndOfDay: true }
					  )
					: undefined
			});

			this.savedState = {
				start: this.dateService.cloneDate(this.start),
				end: this.dateService.cloneDate(this.end)
			};
		}
	}

	public resetRange() {
		this.start = new Date();
		this.end = undefined;
		this.value = Object.assign({}, this.value);
		this.value.start = this.getSelectedOption().timeCallback();
		this.value.end = undefined;
		this.calendarModel = { start: undefined, end: undefined };
		this.savedState = undefined;
		this.previousOptionSelection = undefined;
	}

	public updateStart($event: Date) {
		this.start = $event;
	}

	public updateEnd($event: Date) {
		this.end = $event;
	}

	private updateModel(model: FilterCalendarValue | null) {
		this.value = model;
		this.onChangeCallback(this.value);
	}

	private refreshData() {
		if (this.savedState) {
			this.start = this.dateService.cloneDate(this.savedState.start);
			this.end = this.dateService.cloneDate(this.savedState.end);
			this.calendarModel.start = this.start.toISOString();
			this.calendarModel.end = this.end?.toISOString();
		}
	}

	private setupOptions() {
		if (!this.options || this.options.length === 0) {
			this.options = [
				new FilterRangeSelectOption(
					this.customizer.translate('global_filter_range_all_time_label'),
					FilterRangeCalendarEnum.AllTime,
					() => undefined,
					true
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_filter_range_last_hour_label'),
					FilterRangeCalendarEnum.LastHour,
					() => this.dateService.subtract(new Date(), 1, 'h').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_filter_range_last_8_hour_label'),
					FilterRangeCalendarEnum.Last8Hours,
					() => this.dateService.subtract(new Date(), 8, 'h').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_filter_range_last_day_label'),
					FilterRangeCalendarEnum.LastDay,
					() => this.dateService.subtract(new Date(), 1, 'd').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_lastWeek_label'),
					FilterRangeCalendarEnum.LastWeek,
					() => this.dateService.subtract(new Date(), 1, 'w').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_lastMonth_label'),
					FilterRangeCalendarEnum.LastMonth,
					() => this.dateService.subtract(new Date(), 1, 'M').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_lastYear_label'),
					FilterRangeCalendarEnum.LastYear,
					() => this.dateService.subtract(new Date(), 1, 'y').toISOString(),
					false
				),
				new FilterRangeSelectOption(
					this.customizer.translate('global_custom_label'),
					FilterRangeCalendarEnum.Custom,
					() => undefined,
					false
				)
			];
		}
	}

	private setupDefaults() {
		if (!this.format) {
			this.format = this.customizer.date.format;
		}
	}
}
