import { Component, EventEmitter, Output } from '@angular/core';
import { TableBase2Component } from 'src/app/standalone/components/table/table-base2.component';
import { LongTermRecommendationsDisplayModel } from '../../_common/models/long-term-recommendations.displaymodel';

@Component({
	selector: 'rq-long-term-recommendations-table-widget',
	templateUrl: './long-term-recommendations-table.widget.html',
	styleUrls: ['./long-term-recommendations-table.widget.scss']
})
export class LongTermRecommendationsTableWidget extends TableBase2Component<LongTermRecommendationsDisplayModel, unknown> {
	@Output()
	public readonly viewAll = new EventEmitter();

	public redirectToLongTermRecommendationsPage() {
		this.viewAll.emit();
	}
}
