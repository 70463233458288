<rq-card [title]="'Short Term Recommendations'"
         [isLoaded]="data"
         [isFlexHeight]="true"
         [minLoadingHeight]="'400px'"
         [isNoDataVisible]="!data">
  <ng-template rqCardContent>
    <rq-new-bar-chart *ngIf="data"
                      [data]="data"
                      [config]="config"></rq-new-bar-chart>
  </ng-template>
</rq-card>
