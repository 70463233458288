import { LabelType, Options } from '@angular-slider/ngx-slider';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderModel } from './slider.model';
import { isNumber } from '../../../utils/helpers.utils';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'rq-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SliderComponent),
			multi: true
		}
	]
})
export class SliderComponent implements ControlValueAccessor, OnInit {
	@Input()
	public formatter?: (value: number) => string;

	@Input()
	public isDisabled = false;

	@Input()
	public delayResponse = 500;

	@Input()
	public options: Options = {
		floor: 0,
		ceil: 100,
		showSelectionBar: true,
		disabled: true
	};

	public lowValue!: number;

	public highValue?: number;

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	private sliderChange$ = new BehaviorSubject<SliderModel | null>(null);

	@HostListener('click')
	public setTouchedState() {
		const value = <SliderModel>{
			min: this.lowValue,
			max: this.highValue
		};
		this.onTouchedCallback(value);
	}

	public ngOnInit(): void {
		this.setupFormatter();

		this.sliderChange$.pipe(debounceTime(this.delayResponse)).subscribe(val => {
			if (val !== null) {
				this.onChangeCallback(val);
			}
		});
	}

	public onChange(model?: SliderModel): void {
		// not using is isNullOrUndefined because the compiler cannot detect that the variable has been checked
		// and it can give false errors
		if (model?.min !== null && model?.max !== undefined && model?.max !== null) {
			this.lowValue = model.min;
			this.highValue = model.max;
		}

		const value = <SliderModel>{
			min: this.lowValue,
			max: this.highValue
		};

		this.sliderChange$.next(value);
	}

	public writeValue(value: SliderModel): void {
		if (value && isNumber(value.min)) {
			this.lowValue = value.min;
		} else if (this.options?.floor && isNumber(this.options.floor)) {
			this.lowValue = this.options.floor;
		} else {
			this.lowValue = 0;
		}

		this.highValue = value && isNumber(value.max) ? value.max : undefined;
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}

	private setupFormatter(): void {
		if (!this.options) {
			return;
		}

		if (this.options.translate) {
			return;
		}

		this.options.translate = (toFormat: number, _label: LabelType): string => {
			if (toFormat === undefined || toFormat === null) {
				return '';
			}

			if (this.formatter) {
				return this.formatter(toFormat);
			}

			return toFormat.toString();
		};
	}
}
