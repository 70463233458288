export enum AuditType {
	'BusinessApplication' = 1,
	'LegalEntity' = 2,
	'BaGeneralControl' = 3,
	'BaEnterpriseProfile' = 4,
	'EndpointProfile' = 5,
	'BaApplicationProfile' = 6,
	'ApplicationProfile' = 7,
	'EnterpriseProfile' = 8,
	'BaDataProfile' = 9,
	'BaContractProfile' = 10,
	'DataProfile' = 11,
	'ContractProfile' = 12,
	'RunAnalysis' = 13,
	'User' = 14,
	'LegalEntityUser' = 15,
	'LossValue' = 17,
	'BaEndpointProfile' = 18,
	'BaEndpoint' = 19,
	'ReputationDamageParameter' = 20,
	'AttackRateOfIncidence' = 21,
	'Configuration' = 22,
	'Account' = 23,
	'Currency' = 24,
	'ExchangeRate' = 25,
	'LegalEntityPreference' = 26,
	'CveTechnicalWeighting' = 27,
	'CveScoreWeighting' = 28,
	'CveFinancialWeighting' = 29,
	'CveExploitabilityWeighting' = 30,
	'FileUpload' = 31,
	'IntegrationSettings' = 32,
	'Instance' = 33,
	'Scenario' = 35,
	'ScenarioRateOfIncidence' = 36,
	'ScenarioEnterprise' = 37,
	'FairInput' = 38,
	'ModifiedFairInput' = 39,
	'FairLoss' = 40,
	'FairRecommendationInput' = 41,
	'FairScenario' = 42,
	'ModifiedFairScenario' = 43,
	'IntegrationArcherSettings' = 44,
	'LossExposure' = 45,
	'TPLossExposure' = 46,
	'IntegrationSureCloudSettings' = 47,
	'ThirdParty' = 48,
	'ThirdPartyGeneralControl' = 49,
	'TPRunAnalysis' = 50,
	'LossExposureVulnerability' = 51,
	'LossExposureTef' = 52,
	'LossExposureMagnitude' = 53,
	'LossLimit' = 54,
	'IntegrationSchedulerSettings' = 55,
	'IntegrationFileSettings' = 56,
	'IntegrationTenableSettings' = 57,
	'FairMagnitudeValues' = 58,
	'FairTEFValues' = 59,
	'BaLossValues' = 60,
	'OpenIdConfig' = 61,
	'SecurityScorecard' = 62,
	'SecurityScorecardScan' = 63,
	'BusinessEntity' = 64,
	'ApplicationCharacteristic' = 65,
	'ApplicationType' = 66,
	'RevenueLossExposure' = 67,
	'RevenueLossExposureToBe' = 68,
	'RevenueApplicationType' = 69,
	'RevenueApplicationCharacteristic' = 70,
	'BaRevenue' = 71,
	'AggregatedFairScenario' = 72,
	'UserPreferences' = 73,
	'BaLossExposure' = 74,
	'MachineLearningScenario' = 75,
	'ControlProfile' = 76,
	'RecommendationImplementationCost' = 77,
	'MultipleControlProfilesScenario' = 78,
	'ApplicationThreat' = 79,
	'BaCustomModels' = 80,
	'CustomVariable' = 81,
	'CustomModel' = 82,
	'EnterpriseLossParameter' = 83,
	'IntegrationSnowflakeSettings' = 84,
	'SamlConfig' = 85,
	'IntegrationAppSocSettings' = 86,
	'BaLibrary' = 87,
	'LookupTable' = 88,
	'EndpointScoreWeighting' = 89,
	'IntegrationWindowsDefenderSettings' = 90,
	'QuantitativeScales' = 91,
	'HeatMapSelection' = 92,
	'FeatureFlag' = 93,
	'MitreCustomAttackScenario' = 94,
	'MitreHistoricalAttackScenario' = 95,
	'MitreBusinessAsset' = 96,
	'MitreConfig' = 97,
	'MitreTechnique' = 98,
	'MitreObservation' = 99,
	'Mitre' = 100,
	'MitreTechniqueFrequency' = 101,
	'IntegrationQualysSettings' = 102,
	'DeleteLegalEntity' = 103
}
