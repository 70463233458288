import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { RecommendationTopByProbabilityReductionViewModel } from '../../../routes/control-analysis/_common/models/top-controls-reduction-by-percentage.model';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { IBubbleChart, IBubbleChartGroup } from '../../../standalone/components/charts/bubble-chart/models/bubble-chart.interface';
import { Customizer } from '../../services/customizer';
import { BubbleChartConfig } from '../../../standalone/components/charts/bubble-chart/models/bubble-chart.config';
import { FormatterType } from '../../enums/formatter-type.enum';

@Component({
	selector: 'rq-top-controls-reduction-by-percentage',
	templateUrl: './top-controls-reduction-by-percentage.component.html',
	styleUrls: ['./top-controls-reduction-by-percentage.component.scss']
})
export class TopControlsReductionByPercentageComponent implements OnChanges {
	@Input()
	public data?: Array<RecommendationTopByProbabilityReductionViewModel>;

	public chartData!: IBubbleChart;

	public chartConfig!: BubbleChartConfig;

	constructor(
		public translationService: TranslationService,
		public customizer: Customizer,
		public numberFormatterService: NumberFormatterService
	) {}

	public get hasNoControls() {
		return this.chartData && this.chartData?.groups?.length === 0;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes?.data && changes.data.currentValue) {
			this.setupChart(changes.data.currentValue as Array<RecommendationTopByProbabilityReductionViewModel>);
		}
	}

	private setupChart(values: Array<RecommendationTopByProbabilityReductionViewModel>) {
		const config = new BubbleChartConfig();
		config.isLegendVisible = true;
		config.isLegendTooltipVisible = true;
		config.yAxisFormatter = FormatterType.Currency;
		config.bubbleSizeMultiplier = 10;
		this.chartConfig = config;

		this.chartData = {
			groups: values.map(
				(val, i) =>
					<IBubbleChartGroup>{
						name: this.getLabel(val),
						items: [
							{
								id: `${i}-${val.titleKey}`,
								xAxiesValue: i,
								yAxiesValue: val.expectedAleReduction * -1,
								bubbleValue: val.lossProbabilityReduction * -1,
								tooltipTemplate: this.getLabel(val)
							}
						]
					}
			)
		};
	}

	private getLabel(value: RecommendationTopByProbabilityReductionViewModel) {
		const title = this.translationService.get(value.titleKey);
		const control = this.translationService.get(value.controlKey);

		const levelLabel = this.translationService.get('global_level_label');
		const level = value.proposedValue ? ` (${levelLabel} ${value.oldValue} -> ${levelLabel} ${value.proposedValue})` : '';

		const label = `${title} - \n${control}${level} - ${this.numberFormatterService.currency(value.expectedAleReduction * -1)}`;

		return value.legalEntityName ? `${value.legalEntityName} - \n${label}` : `${label}`;
	}
}
