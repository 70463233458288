<section class="boxed-wrapper">
  <rq-page-title>
    <ng-template>
      <div class="title-wrapper">
        <button class="btn-icon"
                (click)="backToApp()">
          <rq-icon href="icon-arrow-left"></rq-icon>
        </button>

        <span>API MONITOR</span>
      </div>
    </ng-template>

    <ng-template pageTitleAction>
      <button class="btn-secondary"
              (click)="clearLogs()">
        <span>Clear Logs</span>
      </button>
    </ng-template>
  </rq-page-title>

  <section class="content">
    <rq-card [title]="'Top 5 slowest api calls'"
             [isLoaded]="true"
             [isNoDataVisible]="!slowestApiCalls.length"
             class="m-b-lg one-column">
      <ng-template rqCardContent>
        <rq-table-ui [dataSource]="slowestApiCalls"
                     [pagerState]="{ skip: 0, take: 5 }"
                     [hasFilters]="false"
                     [isSortable]="false"
                     [count]="apiCalls.length">
          <rq-table-column field="url"
                           title="URL">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.url }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="method"
                           title="Method">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span [ngClass]="getMethodColor(dataItem.method)">{{ dataItem.method }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="time"
                           title="Time(ms)">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.time | rqNumber }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="status"
                           title="Status">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.status }}</span>
            </ng-template>
          </rq-table-column>

        </rq-table-ui>
      </ng-template>
    </rq-card>

    <rq-card [title]="'Top 5 most used api calls'"
             [isLoaded]="true"
             [isNoDataVisible]="!mostUsedApiCalls.length"
             class="m-b-lg one-column">
      <ng-template rqCardContent>
        <rq-table-ui [dataSource]="mostUsedApiCalls"
                     [pagerState]="{ skip: 0, take: 5 }"
                     [hasFilters]="false"
                     [isSortable]="false"
                     [count]="apiCalls.length">
          <rq-table-column field="url"
                           title="URL">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.url }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="method"
                           title="Method">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span [ngClass]="getMethodColor(dataItem.method)">{{ dataItem.method }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="count"
                           title="Count">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.count | rqNumber }}</span>
            </ng-template>
          </rq-table-column>
        </rq-table-ui>
      </ng-template>
    </rq-card>

    <rq-card [title]="'All API calls'"
             [isLoaded]="true"
             [isNoDataVisible]="!apiCalls.length"
             class="m-b-lg two-columns">
      <ng-template rqCardContent>
        <rq-table-ui [dataSource]="apiCalls"
                     [pagerState]="{ skip: 0, take: 10 }"
                     [count]="apiCalls.length"
                     [hasFilters]="false">
          <rq-table-column field="url"
                           title="URL"
                           filter="string">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.url }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="method"
                           title="Method"
                           filter="string">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span [ngClass]="getMethodColor(dataItem.method)">{{ dataItem.method }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="time"
                           title="Time(ms)">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.time | rqNumber }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="count"
                           title="Count">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.count | rqNumber }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="status"
                           title="Status"
                           filter="string">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.status }}</span>
            </ng-template>
          </rq-table-column>

          <rq-table-column field="timestamp"
                           title="Timestamp">
            <ng-template tableData
                         let-dataItem="dataItem">
              <span>{{ dataItem.timestamp | rqDate: {pattern: datePattern} }}</span>
            </ng-template>
          </rq-table-column>
        </rq-table-ui>
      </ng-template>
    </rq-card>
  </section>
</section>
