import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthorizeInputModel } from '../../core/roles/authorize-input.model';
import { ROLE_CONFIG } from '../../core/roles/role.config';
import { ControlStatus } from '../../../shared/enums/control-status.enum';
import { AnalysisPortfolioLegalEntityModel } from '../../../server/models/analysis-portfolio-legal-entity-model.model';
import { RQ_ROUTES } from '../../configs/routes.config';
import { IdentityService } from '../../../shared/services/identity.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/components/base.component';
import { LegalEntityStatusType } from '../../../server/enums/legal-entity-status-type.enum';
import { StartingPageService } from '../../../shared/services/starting-page.service';
import { ShellService } from '../../../shared/services/shell.service';
import { LegalEntityType } from '../../../server/enums/legal-entity-type.enum';
import { DropdownOption } from '../../../standalone/components/form-elements/dropdown/dropdown.model';

@Component({
	selector: 'rq-legal-entities-dropdown',
	templateUrl: './legal-entities-dropdown.component.html',
	styleUrls: ['./legal-entities-dropdown.component.scss']
})
export class LegalEntitiesDropdownComponent extends BaseComponent implements OnChanges, OnInit {
	@Input()
	public config!: { portfolioName: string; hasEntepriseAccess: boolean; isAtLeaseOneRiskAnalysisConfigured: boolean };

	@Input()
	public availableLegalEntities: Array<AnalysisPortfolioLegalEntityModel> = [];

	public formGroup!: UntypedFormGroup;

	public authorizeState: AuthorizeInputModel[] = [];

	public options: DropdownOption<string | null>[] = [];

	private selectedOption: string | null = null;

	constructor(
		private identityService: IdentityService,
		private startingPageService: StartingPageService,
		private shellService: ShellService,
		private router: Router
	) {
		super();
	}

	public ngOnChanges(changes: SimpleChanges) {
		const availableLegalEntities = changes.availableLegalEntities.currentValue as Array<AnalysisPortfolioLegalEntityModel>;
		if (availableLegalEntities && this.config) {
			this.setupOptions();
			this.selectedOption = this.getActiveOption();
		}
	}

	public ngOnInit() {
		this.setupForm();

		this.setupAuthoriseState();

		this.subscribeToFormChange();

		this.subscribeToEvents();
	}

	private setupForm() {
		this.selectedOption = this.getActiveOption();
		this.formGroup = new UntypedFormGroup({
			legalEntity: new UntypedFormControl(this.selectedOption)
		});
	}

	private getActiveOption() {
		const url = this.router.url;
		const activeOption = this.options.find(
			option => option.value && (url.includes(RQ_ROUTES.pro.url(option.value)) || url.includes(RQ_ROUTES.fidelityOne.url(option.value)))
		);

		if (activeOption) {
			activeOption.isSelected = true;
			return activeOption.value;
		}

		this.options[0].isSelected = true;
		return this.options[0].value;
	}

	private setupOptions() {
		let options: DropdownOption<string | null>[] = [];
		if (this.config.hasEntepriseAccess) {
			options.push(new DropdownOption(this.config.portfolioName, null));
		}

		const filteredLE = this.availableLegalEntities.filter(
			x => x.statusType !== LegalEntityStatusType.InDeletion && x.statusType !== LegalEntityStatusType.Archived
		);
		options = [...options, ...filteredLE.map(x => new DropdownOption(x.displayName, x.legalEntityId))];

		this.options = [...options];
	}

	private getRoute(legalEntityId: string | null) {
		const legalEntity = this.availableLegalEntities.find(x => x.legalEntityId === legalEntityId);

		if (legalEntity) {
			switch (legalEntity.type) {
				case LegalEntityType.FinancialImpacts:
					return RQ_ROUTES.fidelityOne.dashboard.url(legalEntity.legalEntityId);
				case LegalEntityType.Mitre:
					return legalEntity.analysisId
						? RQ_ROUTES.pro.url(legalEntity.legalEntityId)
						: RQ_ROUTES.pro.configure.legalEntity.url(legalEntity.legalEntityId);
				default:
					return this.getControlsAnalysisRoute(legalEntity);
			}
		} else {
			return this.getPortfolioRoute();
		}
	}

	private getControlsAnalysisRoute(legalEntity: AnalysisPortfolioLegalEntityModel) {
		if (this.identityService.isFairOnly) {
			return RQ_ROUTES.pro.scenarios.url(legalEntity.legalEntityId);
		} else {
			return legalEntity.analysisId ? RQ_ROUTES.pro.url(legalEntity.legalEntityId) : RQ_ROUTES.pro.configure.url(legalEntity.legalEntityId);
		}
	}

	private getPortfolioRoute() {
		return this.config.isAtLeaseOneRiskAnalysisConfigured
			? RQ_ROUTES.enterprise.portfolioAnalysis.url()
			: RQ_ROUTES.enterprise.configuration.legalEntities.url();
	}

	private setupAuthoriseState() {
		this.authorizeState.push({
			role: ROLE_CONFIG.enterprise.readOnly,
			status: ControlStatus.Disabled
		});
	}

	private subscribeToFormChange() {
		this.subscriptions.push(
			this.formGroup.controls.legalEntity.valueChanges.subscribe((value: string | null) => {
				if (this.selectedOption !== value) {
					const legalEntity = this.availableLegalEntities.find(le => value === le.legalEntityId);

					if (
						!legalEntity ||
						(legalEntity.statusType !== LegalEntityStatusType.Archived && legalEntity.type !== LegalEntityType.FinancialImpacts)
					) {
						this.startingPageService.setStartingPageStateWhenAppIsSwitched(this.isAppSwitched(this.selectedOption, value));
					}

					this.selectedOption = value;
					this.navigateTo(value);
				}
			})
		);
	}

	private subscribeToEvents() {
		this.shellService.portfolioNameChangeEvent.subscribe(x => {
			this.updatePortfolioName(x);
		});
	}

	private isAppSwitched(currentOption: string | null, nextOption: string | null) {
		const currentUrl = this.getRoute(currentOption);
		const nextUrl = this.getRoute(nextOption);
		const firstAppBaseUrl = currentUrl.split('/');
		const secondAppBaseUrl = nextUrl.split('/');

		return firstAppBaseUrl[1] !== secondAppBaseUrl[1];
	}

	private updatePortfolioName(portfolioName: string) {
		this.config.portfolioName = portfolioName;

		this.setupOptions();
		this.selectedOption = this.getActiveOption();
	}

	private navigateTo(legalEntityId: string | null) {
		const route = this.getRoute(legalEntityId);
		window.location.href = route;
	}
}
