import { Component, OnInit } from '@angular/core';
import { WidgetsService } from '../widgets.service';
import { StandardBase } from 'src/app/routes/control-analysis/_common/standard.base';
import { RiskOverviewDisplayModel } from '../_common/models/risk-overview.diplaymodel';
import { RiskOverviewDetailsDisplayModel } from '../_common/models/risk-overview-details.diplaymodel';
import { Router } from '@angular/router';
import { ScenarioDataService } from 'src/app/server/services/scenario/scenario-data.service';
import { ScenarioType } from 'src/app/server/enums/scenario-type.enum';
import { RQ_ROUTES } from '../../configs/routes.config';
import { ScenarioModel } from 'src/app/server/models/scenario-model.model';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';
import { AnalysisRiskOverviewDataService } from 'src/app/server/services/analysis/analysis-risk-overview-data.service';
import { RiskOverViewSourceType } from 'src/app/server/enums/risk-over-view-source-type.enum';
import { RiskOverViewFinancialModel } from 'src/app/server/models/risk-over-view-financial-model.model';

@Component({
	selector: 'rq-risk-overview-widget',
	templateUrl: './risk-overview.widget.html',
	styleUrls: ['./risk-overview.widget.scss']
})
export class RiskOverviewWidget extends StandardBase implements OnInit {
	public data!: RiskOverviewDisplayModel | null;

	public minHeight = '240px';

	constructor(
		private widgetService: WidgetsService,
		private router: Router,
		private scenarioDataService: ScenarioDataService,
		private analysisRiskOverviewDataService: AnalysisRiskOverviewDataService
	) {
		super();
	}

	public async ngOnInit() {
		this.subscribeToRefresh();
		await this.initData();
	}

	public async redirectRisk(data: RiskOverviewDetailsDisplayModel) {
		if (data.scenarioId) {
			const scenario = await this.scenarioDataService.getById(data.scenarioId);

			return this.viewScenario(scenario);
		} else {
			return this.router.navigateByUrl(RQ_ROUTES.pro.financial.tabs.applications.url(this.legalEntityId));
		}
	}

	private async initData() {
		const data = await this.analysisRiskOverviewDataService.get(this.legalEntityId);

		this.data = <RiskOverviewDisplayModel>{
			revenue: data.revenue,
			maximumImpact: this.mapNewModel(data.maxImpact),
			likelihood: this.mapNewModel(data.likelihood),
			frequency: <RiskOverviewDetailsDisplayModel>{
				frequency: data.frequency.currentFrequency,
				riskName: data.frequency.sourceName,
				attackType: data.frequency.attack,
				scenarioId: data.frequency.sourceType === RiskOverViewSourceType.Scenario ? data.frequency.sourceId : undefined
			}
		};
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetService.refresh$.subscribe((type: UiWidgetType) => {
				if (type === UiWidgetType.RiskOverview) {
					this.data = null;
					this.initData();
				}
			})
		);
	}

	private mapNewModel(data: RiskOverViewFinancialModel): RiskOverviewDetailsDisplayModel {
		return {
			currentValue: data.currentValue,
			previousValue: data.previousValue,
			riskName: data.sourceName,
			attackType: data.attack,
			scenarioId: data.sourceType === RiskOverViewSourceType.Scenario ? data.sourceId : undefined
		};
	}

	private async viewScenario(model: ScenarioModel) {
		const urls = [
			{
				type: ScenarioType.EnterpriseControl,
				url: RQ_ROUTES.pro.scenarios.enterprise.details.url(this.legalEntityId, model.id, model.type)
			},
			{ type: ScenarioType.Fair, url: RQ_ROUTES.pro.scenarios.fair.details.url(this.legalEntityId, model.id, model.type) },
			{ type: ScenarioType.SemiFair, url: RQ_ROUTES.pro.scenarios.modifiedFair.details.url(this.legalEntityId, model.id, model.type) },
			{
				type: ScenarioType.RateOfIncidence,
				url: RQ_ROUTES.pro.scenarios.rateOfIncident.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.AggregatedFair,
				url: RQ_ROUTES.pro.scenarios.aggregatedFair.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MachineLearning,
				url: RQ_ROUTES.pro.scenarios.machineLearning.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MitreCustomAttack,
				url: RQ_ROUTES.pro.scenarios.mitre.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MitreHistoricalAttack,
				url: RQ_ROUTES.pro.scenarios.mitre.details.url(this.legalEntityId, model.id, model.type)
			}
		];

		const url = urls.find(x => x.type === model.type) as { type: ScenarioType; url: string };
		await this.router.navigateByUrl(url.url);
	}
}
