import { Injectable } from '@angular/core';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { DateFormatterService } from '../../../shared/services/date-formatter.service';
import { FormatterType } from '../../../shared/enums/formatter-type.enum';
import { CurrencyConfig, PercentConfig } from '../../../shared/models/number.config';
import { Customizer } from '../../../shared/services/customizer';

@Injectable()
export class ChartService {
	constructor(
		private numberFormatterService: NumberFormatterService,
		private dateFormatterService: DateFormatterService,
		private customizer: Customizer
	) {}

	public getFormatter(type?: FormatterType | string): (value: number) => string {
		switch (type) {
			case FormatterType.Currency:
				return value => (isNaN(value) ? `${value}` : this.numberFormatterService.currency(value, { isAbbr: true } as CurrencyConfig));
			case FormatterType.Percent:
				return value => (isNaN(value) ? `${value}` : this.numberFormatterService.percent(value, { decimals: 0 } as PercentConfig));
			case FormatterType.WholeNumber:
				return value => (isNaN(value) ? `${value}` : `${Math.trunc(value)}`);
			case FormatterType.Date:
				return value =>
					isNaN(value) ? `${value}` : this.dateFormatterService.format(new Date(Number(value)), this.customizer.date.format);

			case FormatterType.Custom:
			default:
				return value => `${value}`;
		}
	}
}
