import { Component, Input } from '@angular/core';

@Component({
	selector: 'rq-risk-overview-revenue-widget',
	templateUrl: './risk-overview-revenue.widget.html',
	styleUrls: ['./risk-overview-revenue.widget.scss']
})
export class RiskOverviewRevenueWidget {
	@Input()
	public data?: number;
}
