<span #filterButton
      [e2e-test]="column.title| translate"
      class="pointer filter-button"
      [class.disabled]="isDisabled"
      (click)="openFilter()">
  <rq-icon [class.disabled]="isDisabled"
           href='icon-filter'></rq-icon>
  <div *ngIf="isActive"
       class="active-bullet"></div>
</span>

<section class="filter-popup"
         *ngIf="isPopupExpanded"
         (rqClickOutside)="closePopup()"
         [excludeElement]="filterButtonRef?.nativeElement"
         [class.align-left]="alignLeft"
         [class.align-right]="!alignLeft">
  <ng-container [ngSwitch]="column.filter">
    <ng-container *ngSwitchCase="'string'">
      <rq-filter-string (clearAction)="clear()"
                        (ngModelChange)="triggerValueChange($event)"
                        [isDisabled]="isDisabled"
                        [(ngModel)]="filterOutput[column.field].value"></rq-filter-string>
    </ng-container>

    <ng-container *ngSwitchCase="'numeric'">
      <rq-filter-number [suffix]="numberFilter(column.filterSetup).suffix"
                        [allowNegativeNumbers]="numberFilter(column.filterSetup).allowNegativeNumbers"
                        [isDisabled]="isDisabled"
                        (ngModelChange)="triggerValueChange($event)"
                        [(ngModel)]="filterOutput[column.field].value"></rq-filter-number>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdown'">
      <rq-table-filter-dropdown [isDisabled]="isDisabled"
                                [options]="dropdownFilter(column.filterSetup).options"
                                (clearAction)="clear()"
                                (ngModelChange)="triggerValueChange($event)"
                                [(ngModel)]="filterOutput[column.field].value"></rq-table-filter-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <rq-table-filter-select [isDisabled]="isDisabled"
                              (clearAction)="clear()"
                              (ngModelChange)="triggerValueChange($event, false)"
                              [maxSelected]="selectFilter(column.filterSetup).maxSelected"
                              [groups]="selectFilter(column.filterSetup).groups"
                              [(ngModel)]="filterOutput[column.field].value"></rq-table-filter-select>
    </ng-container>

    <ng-container *ngSwitchCase="'range'">
      <rq-table-filter-range [isDisabled]="isDisabled"
                             (clearAction)="clear()"
                             [filter]="rangeFilter(column.filterSetup)"
                             (ngModelChange)="triggerValueChange($event)"
                             [(ngModel)]="filterOutput[column.field].value"></rq-table-filter-range>
    </ng-container>

    <ng-container *ngSwitchCase="'calendar'">
      <rq-table-filter-calendar [isDisabled]="isDisabled"
                                [format]="calendarFilter(column.filterSetup).datePattern"
                                [options]="calendarFilter(column.filterSetup).options"
                                (ngModelChange)="triggerValueChange($event)"
                                [(ngModel)]="filterOutput[column.field].value"></rq-table-filter-calendar>
    </ng-container>
  </ng-container>
</section>
