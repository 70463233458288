import { Component, OnInit, ViewChild } from '@angular/core';
import { StandardBase } from 'src/app/routes/control-analysis/_common/standard.base';
import { WidgetsService } from '../widgets.service';
import { MostImpactfulRisksDisplayModel } from '../_common/models/most-impactful-risks.displaymodel';
import { DropdownOption } from 'src/app/standalone/components/form-elements/dropdown/dropdown.model';
import { MostImpactfulRisksTypesEnum } from '../_common/models/most-impactful-risks-type.enum';
import { ScenarioDataService } from 'src/app/server/services/scenario/scenario-data.service';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../../configs/routes.config';
import { ScenarioType } from 'src/app/server/enums/scenario-type.enum';
import { ScenarioModel } from 'src/app/server/models/scenario-model.model';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';
import { MostImpactfulRisksTableWidget } from './most-impactful-risks-table/most-impactful-risks-table.widget';
import { AttackDataService } from 'src/app/server/services/configuration/attack-data.service';
import { AnalysisAggregatedThreatDataService } from 'src/app/server/services/analysis/analysis-aggregated-threat-data.service';
import { AttackViewModel } from 'src/app/server/models/attack-view-model.model';
import { AnalysisAggregatedThreatFilterModel } from 'src/app/server/models/analysis-aggregated-threat-filter-model.model';
import { Filter } from 'src/app/server/models/filter.model';

@Component({
	selector: 'rq-most-impactful-risks',
	templateUrl: './most-impactful-risks.widget.html',
	styleUrls: ['./most-impactful-risks.widget.scss']
})
export class MostImpactfulRisksWidget extends StandardBase implements OnInit {
	@ViewChild(MostImpactfulRisksTableWidget)
	public table!: MostImpactfulRisksTableWidget;

	public data!: Array<MostImpactfulRisksDisplayModel> | null;

	public options: Array<DropdownOption<MostImpactfulRisksTypesEnum>> = [];

	public selectedOption!: MostImpactfulRisksTypesEnum;

	public tableCount!: number;

	private attacks!: Array<AttackViewModel>;

	constructor(
		private widgetsService: WidgetsService,
		private scenarioDataService: ScenarioDataService,
		private router: Router,
		private analysisAggregatedThreatDataService: AnalysisAggregatedThreatDataService,
		private attackDataService: AttackDataService
	) {
		super();
	}

	public async ngOnInit() {
		this.subscribeToRefresh();

		this.setupDropdownOptions();

		await this.initData();

		this.isComponentLoaded = true;
	}

	public async dropdownOptionChange(value: MostImpactfulRisksTypesEnum) {
		this.table.triggerTableLoading(true);

		this.selectedOption = value;

		await this.loadData(this.selectedOption);
	}

	public async redirectRisk(data: MostImpactfulRisksDisplayModel) {
		if (data.scenarioId) {
			const scenario = await this.scenarioDataService.getById(data.scenarioId);
			return this.viewScenario(scenario);
		} else {
			return this.router.navigateByUrl(RQ_ROUTES.pro.financial.tabs.applications.url(this.legalEntityId));
		}
	}

	private async loadData(selection: MostImpactfulRisksTypesEnum) {
		const filter = this.filterData(selection);

		const data = await this.analysisAggregatedThreatDataService.getAnalysisAggregatedThreats(this.legalEntityId, filter, {
			skip: 0,
			take: 5
		});

		this.data = data.values.map(item => {
			return <MostImpactfulRisksDisplayModel>{
				name: item.displayName,
				attackType: this.attacks.find(attack => attack.id === item.attackId)?.name,
				maximumImpact: item.singleLossAmount,
				likelihood: item.probabilityOfSuccess,
				frequency: item.lossEventFrequencyLabel,
				scenarioId: item.scenarioId
			};
		});

		this.tableCount = this.data.length;
	}

	private filterData(selection: MostImpactfulRisksTypesEnum): AnalysisAggregatedThreatFilterModel {
		const filter = new AnalysisAggregatedThreatFilterModel();

		if (selection === MostImpactfulRisksTypesEnum.Frequency) {
			filter.lossEventFrequency = new Filter();
			filter.lossEventFrequency.isSortedAscending = false;
		} else if (selection === MostImpactfulRisksTypesEnum.Likelihood) {
			filter.probabilityOfSuccess = new Filter();
			filter.probabilityOfSuccess.isSortedAscending = false;
		} else {
			filter.singleLossAmount = new Filter();
			filter.singleLossAmount.isSortedAscending = false;
		}
		return filter;
	}

	private async initData() {
		this.attacks = await this.attackDataService.getByLegalEntity(this.legalEntityId);
		this.selectedOption = MostImpactfulRisksTypesEnum.MaximumImpact;
		await this.loadData(this.selectedOption);
	}

	private setupDropdownOptions() {
		this.options = [
			new DropdownOption('Maximum Impact', MostImpactfulRisksTypesEnum.MaximumImpact),
			new DropdownOption('Likelihood', MostImpactfulRisksTypesEnum.Likelihood),
			new DropdownOption('Frequency', MostImpactfulRisksTypesEnum.Frequency)
		];
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetsService.refresh$.subscribe(async (type: UiWidgetType) => {
				if (type === UiWidgetType.MostImpactfulRisks) {
					this.isComponentLoaded = false;
					await this.initData();
				}
			})
		);
	}

	private async viewScenario(model: ScenarioModel) {
		const urls = [
			{
				type: ScenarioType.EnterpriseControl,
				url: RQ_ROUTES.pro.scenarios.enterprise.details.url(this.legalEntityId, model.id, model.type)
			},
			{ type: ScenarioType.Fair, url: RQ_ROUTES.pro.scenarios.fair.details.url(this.legalEntityId, model.id, model.type) },
			{ type: ScenarioType.SemiFair, url: RQ_ROUTES.pro.scenarios.modifiedFair.details.url(this.legalEntityId, model.id, model.type) },
			{
				type: ScenarioType.RateOfIncidence,
				url: RQ_ROUTES.pro.scenarios.rateOfIncident.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.AggregatedFair,
				url: RQ_ROUTES.pro.scenarios.aggregatedFair.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MachineLearning,
				url: RQ_ROUTES.pro.scenarios.machineLearning.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MitreCustomAttack,
				url: RQ_ROUTES.pro.scenarios.mitre.details.url(this.legalEntityId, model.id, model.type)
			},
			{
				type: ScenarioType.MitreHistoricalAttack,
				url: RQ_ROUTES.pro.scenarios.mitre.details.url(this.legalEntityId, model.id, model.type)
			}
		];

		const url = urls.find(x => x.type === model.type) as { type: ScenarioType; url: string };
		await this.router.navigateByUrl(url.url);
	}
}
