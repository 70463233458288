<rq-card [title]="'Most Impactful Risks'"
         [isLoaded]="isComponentLoaded"
         [isFlexHeight]="true"
         [minLoadingHeight]="'400px'"
         [isNoDataVisible]="!data">
  <ng-template rqCardAction>
    <rq-dropdown class="dropdown-xs widget__dropdown"
                 [options]="options"
                 (ngModelChange)="dropdownOptionChange($event)"
                 [(ngModel)]="selectedOption">
    </rq-dropdown>
  </ng-template>
  <ng-template rqCardContent>
    <rq-most-impactful-risks-table *ngIf="data"
                                   [data]="data"
                                   [count]="tableCount"
                                   (viewThreat)="redirectRisk($event)"></rq-most-impactful-risks-table>
  </ng-template>
</rq-card>
