<ng-container *ngIf="!isExpanded">
  <button #analysisButton
          class="btn-icon"
          (click)="cardClicked()">
    <rq-icon title="{{ 'Compare' | translate }}"
             href="icon-check-badge"></rq-icon>

  </button>
</ng-container>

<ng-container *ngIf="isExpanded">
  <div class="compare-card-description p-r-sm expanded text-xs">
    <h5 class="m-b-xs"> {{ config ? config.buttonText : '' }}</h5>
    <button class="btn-primary-sm action p-none"
            (click)="compare($event)"
            [ngClass]="{ 'disabled': !buttonEnabled }">
      {{ config ? config.messageText : '' }}
    </button>
  </div>
</ng-container>
