<rq-card [title]="'Endpoints Distribution By Score'"
         [isLoaded]="data"
         [isFlexHeight]="true"
         [isNoDataVisible]="!totalCount">
  <ng-template rqCardContent>
    <section class="m-b-xl">
      <p class="total-label">Total Endpoints</p>
      <p class="total-count">{{totalCount | rqNumber}}</p>
    </section>

    <rq-custom-bar-chart *ngIf="data"
                         [data]="data"
                         [growDirection]="'horizontal'"></rq-custom-bar-chart>

    <section class="m-t-xl">
      <button class="btn-secondary"
              (click)="viewAll()">
        {{ 'View All' | translate }}
        <rq-icon color="color-primary"
                 href="icon-arrow-right"></rq-icon>
      </button>
    </section>
  </ng-template>
</rq-card>
