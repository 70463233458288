import { SecurityScorecardScansDataService } from './integration/security-scorecard-scans-data.service';
import { SecurityScorecardSettingsDataService } from './integration/security-scorecard-settings-data.service';
import { LossExposureMagnitudeDataService } from './tpconfig/loss-exposure-magnitude-data.service';
import { LossExposureTEFDataService } from './tpconfig/loss-exposure-tef-data.service';
import { LossExposureVulnerabilityDataService } from './tpconfig/loss-exposure-vulnerability-data.service';
import { ThirdPartyDataService } from './tpconfig/third-party-data.service';
import { TPControlProfileDataService } from './tpconfig/tpcontrol-profile-data.service';
import { TPLossExposureDataService } from './tpconfig/tploss-exposure-data.service';
import { ThirdPartyAnalysisDataService } from './tpanalysis/third-party-analysis-data.service';
import { TPAnalysisDataService } from './tpanalysis/tpanalysis-data.service';
import { TPAnalysisLossDataService } from './tpanalysis/tpanalysis-loss-data.service';
import { TPRecommendationDataService } from './tpanalysis/tprecommendation-data.service';
import { TPScenarioLossDataService } from './tpanalysis/tpscenario-loss-data.service';
import { TPVectorDataService } from './tpanalysis/tpvector-data.service';
import { EndpointScoreWeightingDataService } from './settings/endpoint-score-weighting-data.service';
import { TechnicalRiskConfigurationDataService } from './settings/technical-risk-configuration-data.service';
import { AnalysisCveDataService } from './analysis/analysis-cve-data.service';
import { AnalysisEndpointDataService } from './analysis/analysis-endpoint-data.service';
import { ActorRateOfIncidenceDataService } from './settings/actor-rate-of-incidence-data.service';
import { BaLossValueDataService } from './settings/ba-loss-value-data.service';
import { ConfigurationDataService } from './settings/configuration-data.service';
import { CveExploitabilityWeightingDataService } from './settings/cve-exploitability-weighting-data.service';
import { CveFinancialWeightingDataService } from './settings/cve-financial-weighting-data.service';
import { CveScoreWeightingDataService } from './settings/cve-score-weighting-data.service';
import { CveTechnicalWeightingDataService } from './settings/cve-technical-weighting-data.service';
import { ExchangeRateDataService } from './settings/exchange-rate-data.service';
import { LegalEntityPreferenceDataService } from './settings/legal-entity-preference-data.service';
import { LicenseDataService } from './settings/license-data.service';
import { LosslimitsDataService } from './settings/losslimits-data.service';
import { LossValueDataService } from './settings/loss-value-data.service';
import { MitreTechniqueFrequencyDataService } from './settings/mitre-technique-frequency-data.service';
import { RateOfIncidenceDataService } from './settings/rate-of-incidence-data.service';
import { RecommendationsConfigParametersDataService } from './settings/recommendations-config-parameters-data.service';
import { ScenarioDataService } from './scenario/scenario-data.service';
import { ScenarioEnterpriseControlDataService } from './scenario/scenario-enterprise-control-data.service';
import { ScenarioMachineLearningDataService } from './scenario/scenario-machine-learning-data.service';
import { ScenarioMitreBusinessAssetDataService } from './scenario/scenario-mitre-business-asset-data.service';
import { ScenarioMitreDataService } from './scenario/scenario-mitre-data.service';
import { ScenarioMitreObservationDataService } from './scenario/scenario-mitre-observation-data.service';
import { ScenarioMitreQualitativeScaleDataService } from './scenario/scenario-mitre-qualitative-scale-data.service';
import { ScenarioMitreTechniquesDataService } from './scenario/scenario-mitre-techniques-data.service';
import { ScenarioMcpDataService } from './scenario/scenario-mcp-data.service';
import { ScenarioRateOfIncidenceDataService } from './scenario/scenario-rate-of-incidence-data.service';
import { ScenarioSchedulerDataService } from './scenario/scenario-scheduler-data.service';
import { MitreAnalysisDataService } from './scenario/mitre-analysis-data.service';
import { MitreAnalysisObservationDataService } from './scenario/mitre-analysis-observation-data.service';
import { MitreAnalysisTacticDataService } from './scenario/mitre-analysis-tactic-data.service';
import { MitreAnalysisTechniqueDataService } from './scenario/mitre-analysis-technique-data.service';
import { MitreLossImpactDataService } from './scenario/mitre-loss-impact-data.service';
import { MitreRunnerDataService } from './scenario/mitre-runner-data.service';
import { ScenarioAnalysisApplicationDataService } from './scenario/scenario-analysis-application-data.service';
import { ScenarioAnalysisComparisonDataService } from './scenario/scenario-analysis-comparison-data.service';
import { ScenarioAnalysisConfigurationDataService } from './scenario/scenario-analysis-configuration-data.service';
import { ScenarioAnalysisDataService } from './scenario/scenario-analysis-data.service';
import { ScenarioAnalysisMcpDataService } from './scenario/scenario-analysis-mcp-data.service';
import { ScenarioAnalysisMcpCsvDataService } from './scenario/scenario-analysis-mcp-csv-data.service';
import { ScenarioAnalysisMcpRecommendationDataService } from './scenario/scenario-analysis-mcp-recommendation-data.service';
import { ScenarioAnalysisMLDataService } from './scenario/scenario-analysis-ml-data.service';
import { ScenarioDetailsControlDataService } from './scenario/scenario-details-control-data.service';
import { ScenarioDetailsRateOfIncidentDataService } from './scenario/scenario-details-rate-of-incident-data.service';
import { ScenarioMcpRunnerDataService } from './scenario/scenario-mcp-runner-data.service';
import { ScenarioSummaryDataService } from './scenario/scenario-summary-data.service';
import { ReportDataService } from './reporting/report-data.service';
import { IntegrationAppSocSettingsDataService } from './integration/integration-app-soc-settings-data.service';
import { IntegrationArcherSettingsDataService } from './integration/integration-archer-settings-data.service';
import { IntegrationFileSettingsDataService } from './integration/integration-file-settings-data.service';
import { IntegrationJobDataService } from './integration/integration-job-data.service';
import { IntegrationQualysSettingsDataService } from './integration/integration-qualys-settings-data.service';
import { IntegrationSchedulerSettingsDataService } from './integration/integration-scheduler-settings-data.service';
import { IntegrationSettingsDataService } from './integration/integration-settings-data.service';
import { IntegrationSnowflakeDataService } from './integration/integration-snowflake-data.service';
import { IntegrationSureCloudSettingsDataService } from './integration/integration-sure-cloud-settings-data.service';
import { IntegrationTenableSettingsDataService } from './integration/integration-tenable-settings-data.service';
import { IntegrationWindowsDefenderSettingsDataService } from './integration/integration-windows-defender-settings-data.service';
import { ApplicationDataService } from './infrastructure/application-data.service';
import { ApplicationLegalEntityMigrationDataService } from './infrastructure/application-legal-entity-migration-data.service';
import { AuditDataService } from './infrastructure/audit-data.service';
import { FeatureFlagDataService } from './infrastructure/feature-flag-data.service';
import { FilesDataService } from './infrastructure/files-data.service';
import { HealthDataService } from './infrastructure/health-data.service';
import { LogoDataService } from './infrastructure/logo-data.service';
import { LogsDataService } from './infrastructure/logs-data.service';
import { MetadataDataService } from './infrastructure/metadata-data.service';
import { NotificationDataService } from './infrastructure/notification-data.service';
import { SettingsDataService } from './infrastructure/settings-data.service';
import { TranslationDataService } from './infrastructure/translation-data.service';
import { AccountDataService } from './identity/account-data.service';
import { AuthorizationDataService } from './identity/authorization-data.service';
import { UserDataService } from './identity/user-data.service';
import { UserPreferencesDataService } from './identity/user-preferences-data.service';
import { UserRolesDataService } from './identity/user-roles-data.service';
import { AnalysisSynchronizationDataService } from './health/analysis-synchronization-data.service';
import { SystemHealthDataService } from './health/system-health-data.service';
import { FinancialLegalEntityDataService } from './financialanalysis/financial-legal-entity-data.service';
import { PeerAnalyticsDataService } from './financialanalysis/peer-analytics-data.service';
import { AggregatedFairDataService } from './scenario/aggregated-fair-data.service';
import { FairConfidenceTypeDataService } from './scenario/fair-confidence-type-data.service';
import { FairDataService } from './scenario/fair-data.service';
import { FairInputDataService } from './scenario/fair-input-data.service';
import { FairLossDataService } from './scenario/fair-loss-data.service';
import { FairMagnitudeValueDataService } from './fair/fair-magnitude-value-data.service';
import { FairRateOfIncidenceDataService } from './scenario/fair-rate-of-incidence-data.service';
import { FairTEFValueDataService } from './fair/fair-tefvalue-data.service';
import { ModifiedFairDataService } from './fair/modified-fair-data.service';
import { AggregatedFairAnalysisDataService } from './fair/aggregated-fair-analysis-data.service';
import { FairAnalysisDataService } from './fair/fair-analysis-data.service';
import { FairAnalysisDetailsDataService } from './fair/fair-analysis-details-data.service';
import { FairAnalysisInputDataService } from './fair/fair-analysis-input-data.service';
import { FairAnalysisLossDataService } from './fair/fair-analysis-loss-data.service';
import { FairRecommendationDataService } from './fair/fair-recommendation-data.service';
import { FairRecommendationCostDataService } from './fair/fair-recommendation-cost-data.service';
import { ModifiedFairAnalysisDataService } from './fair/modified-fair-analysis-data.service';
import { ApplicationCharacteristicDataService } from './enterprise/application-characteristic-data.service';
import { ApplicationTypeDataService } from './enterprise/application-type-data.service';
import { BusinessEntityDataService } from './enterprise/business-entity-data.service';
import { ConfigurationManagementDataService } from './enterprise/configuration-management-data.service';
import { EnterpriseCurrencyDataService } from './enterprise/enterprise-currency-data.service';
import { EnterpriseExchangeRateDataService } from './enterprise/enterprise-exchange-rate-data.service';
import { EnterpriseSSODataService } from './enterprise/enterprise-sso-data.service';
import { EnterpriseActorDataService } from './enterprise/enterprise-actor-data.service';
import { EnterpriseAttackDataService } from './enterprise/enterprise-attack-data.service';
import { EnterpriseCustomModelsDataService } from './enterprise/enterprise-custom-models-data.service';
import { EnterpriseCustomVariablesDataService } from './enterprise/enterprise-custom-variables-data.service';
import { EnterpriseLegalEntityDataService } from './enterprise/enterprise-legal-entity-data.service';
import { EnterpriseLegalEntityUserDataService } from './enterprise/enterprise-legal-entity-user-data.service';
import { EnterpriseLookupTableDataService } from './enterprise/enterprise-lookup-table-data.service';
import { EnterpriseLossFormulaDataService } from './enterprise/enterprise-loss-formula-data.service';
import { EnterpriseLossLimitsDataService } from './enterprise/enterprise-loss-limits-data.service';
import { EnterpriseNotificationDataService } from './enterprise/enterprise-notification-data.service';
import { EnterpriseScenarioDataService } from './enterprise/enterprise-scenario-data.service';
import { EnterpriseUserLegalEntityDataService } from './enterprise/enterprise-user-legal-entity-data.service';
import { EnterpriseAggregatedThreatsDataService } from './enterprise/enterprise-aggregated-threats-data.service';
import { EnterpriseAnalysisApplicationDataService } from './enterprise/enterprise-analysis-application-data.service';
import { EnterpriseAnalysisLegalEntityDataService } from './enterprise/enterprise-analysis-legal-entity-data.service';
import { EnterpriseFairAnalysisDetailsDataService } from './enterprise/enterprise-fair-analysis-details-data.service';
import { EnterpriseImpactVectorDataService } from './enterprise/enterprise-impact-vector-data.service';
import { EnterpriseRecommendationDataService } from './enterprise/enterprise-recommendation-data.service';
import { EnterpriseRecommendationCostDataService } from './enterprise/enterprise-recommendation-cost-data.service';
import { EnterpriseScenarioAnalysisDataService } from './enterprise/enterprise-scenario-analysis-data.service';
import { ActorDataService } from './configuration/actor-data.service';
import { ApplicationProfileDataService } from './configuration/application-profile-data.service';
import { ApplicationThreatsDataService } from './configuration/application-threats-data.service';
import { AttackDataService } from './configuration/attack-data.service';
import { BaApplicationProfileDataService } from './configuration/ba-application-profile-data.service';
import { BaContractProfileDataService } from './configuration/ba-contract-profile-data.service';
import { BaCustomModelDataService } from './configuration/ba-custom-model-data.service';
import { BaEndpointDataService } from './configuration/ba-endpoint-data.service';
import { BaEnterpriseProfileDataService } from './configuration/ba-enterprise-profile-data.service';
import { BaExploitabilityLibraryDataService } from './configuration/ba-exploitability-library-data.service';
import { BaGeneralControlDataService } from './configuration/ba-general-control-data.service';
import { BaLossExposureDataService } from './configuration/ba-loss-exposure-data.service';
import { BaRevenueDataService } from './configuration/ba-revenue-data.service';
import { BusinessApplicationDataService } from './configuration/business-application-data.service';
import { ContractProfileDataService } from './configuration/contract-profile-data.service';
import { ControlDefensebilityDataService } from './configuration/control-defensebility-data.service';
import { ControlFrameworkDataService } from './configuration/control-framework-data.service';
import { ControlFrameworkDescriptionDataService } from './configuration/control-framework-description-data.service';
import { ControlProfileDataService } from './configuration/control-profile-data.service';
import { CurrencyDataService } from './configuration/currency-data.service';
import { CveBaDataService } from './configuration/cve-ba-data.service';
import { CveDataService } from './configuration/cve-data.service';
import { CweDataService } from './configuration/cwe-data.service';
import { DataProfileDataService } from './configuration/data-profile-data.service';
import { EndpointDataService } from './configuration/endpoint-data.service';
import { EndpointCveDataService } from './configuration/endpoint-cve-data.service';
import { EndpointProfileDataService } from './configuration/endpoint-profile-data.service';
import { EnterpriseProfileDataService } from './configuration/enterprise-profile-data.service';
import { ExploitabilityDataService } from './configuration/exploitability-data.service';
import { ExploitabilityLibraryDataService } from './configuration/exploitability-library-data.service';
import { IndustryDataService } from './configuration/industry-data.service';
import { LegalEntityDataService } from './configuration/legal-entity-data.service';
import { LegalEntityUserDataService } from './configuration/legal-entity-user-data.service';
import { LocationDataService } from './configuration/location-data.service';
import { LossExposureDataService } from './configuration/loss-exposure-data.service';
import { QualitativeScaleDataService } from './configuration/qualitative-scale-data.service';
import { RevenueApplicationCharacteristicDataService } from './configuration/revenue-application-characteristic-data.service';
import { RevenueApplicationTypeDataService } from './configuration/revenue-application-type-data.service';
import { RevenueBusinessEntityDataService } from './configuration/revenue-business-entity-data.service';
import { RevenueLossExposureDataService } from './configuration/revenue-loss-exposure-data.service';
import { AnalysisAggregatedThreatDataService } from './analysis/analysis-aggregated-threat-data.service';
import { AnalysisBusinessApplicationDataService } from './analysis/analysis-business-application-data.service';
import { AnalysisCustomLossDataService } from './analysis/analysis-custom-loss-data.service';
import { AnalysisCveScoreWeightingDataService } from './analysis/analysis-cve-score-weighting-data.service';
import { AnalysisEnterpriseControlDataService } from './analysis/analysis-enterprise-control-data.service';
import { AnalysisHeatMapDataService } from './analysis/analysis-heat-map-data.service';
import { AnalysisImpactVectorDataService } from './analysis/analysis-impact-vector-data.service';
import { AnalysisLegalEntityDataService } from './analysis/analysis-legal-entity-data.service';
import { AnalysisLossImpactDataService } from './analysis/analysis-loss-impact-data.service';
import { AnalysisResultsDataService } from './analysis/analysis-results-data.service';
import { AnalysisRiskOverviewDataService } from './analysis/analysis-risk-overview-data.service';
import { AnalysisRunnerDataService } from './analysis/analysis-runner-data.service';
import { AnalysisSummaryDataService } from './analysis/analysis-summary-data.service';
import { AnalysisThreatsDataService } from './analysis/analysis-threats-data.service';
import { BusinessApplicationLossDataService } from './analysis/business-application-loss-data.service';
import { CveRecommendationDataService } from './analysis/cve-recommendation-data.service';
import { ImpactVectorLossDataService } from './analysis/impact-vector-loss-data.service';
import { InstanceDataService } from './analysis/instance-data.service';
import { LegalEntityLossDataService } from './analysis/legal-entity-loss-data.service';
import { RecomendationTTPDataService } from './analysis/recomendation-ttp-data.service';
import { RecommendationDataService } from './analysis/recommendation-data.service';
import { RecommendationCostDataService } from './analysis/recommendation-cost-data.service';
import { NgModule } from '@angular/core';

@NgModule({
	providers: [
		SecurityScorecardScansDataService,
		SecurityScorecardSettingsDataService,
		LossExposureMagnitudeDataService,
		LossExposureTEFDataService,
		LossExposureVulnerabilityDataService,
		ThirdPartyDataService,
		TPControlProfileDataService,
		TPLossExposureDataService,
		ThirdPartyAnalysisDataService,
		TPAnalysisDataService,
		TPAnalysisLossDataService,
		TPRecommendationDataService,
		TPScenarioLossDataService,
		TPVectorDataService,
		EndpointScoreWeightingDataService,
		TechnicalRiskConfigurationDataService,
		AnalysisCveDataService,
		AnalysisEndpointDataService,
		ActorRateOfIncidenceDataService,
		BaLossValueDataService,
		ConfigurationDataService,
		CveExploitabilityWeightingDataService,
		CveFinancialWeightingDataService,
		CveScoreWeightingDataService,
		CveTechnicalWeightingDataService,
		ExchangeRateDataService,
		LegalEntityPreferenceDataService,
		LicenseDataService,
		LosslimitsDataService,
		LossValueDataService,
		MitreTechniqueFrequencyDataService,
		RateOfIncidenceDataService,
		RecommendationsConfigParametersDataService,
		ScenarioDataService,
		ScenarioEnterpriseControlDataService,
		ScenarioMachineLearningDataService,
		ScenarioMitreBusinessAssetDataService,
		ScenarioMitreDataService,
		ScenarioMitreObservationDataService,
		ScenarioMitreQualitativeScaleDataService,
		ScenarioMitreTechniquesDataService,
		ScenarioMcpDataService,
		ScenarioRateOfIncidenceDataService,
		ScenarioSchedulerDataService,
		MitreAnalysisDataService,
		MitreAnalysisObservationDataService,
		MitreAnalysisTacticDataService,
		MitreAnalysisTechniqueDataService,
		MitreLossImpactDataService,
		MitreRunnerDataService,
		ScenarioAnalysisApplicationDataService,
		ScenarioAnalysisComparisonDataService,
		ScenarioAnalysisConfigurationDataService,
		ScenarioAnalysisDataService,
		ScenarioAnalysisMcpDataService,
		ScenarioAnalysisMcpCsvDataService,
		ScenarioAnalysisMcpRecommendationDataService,
		ScenarioAnalysisMLDataService,
		ScenarioDetailsControlDataService,
		ScenarioDetailsRateOfIncidentDataService,
		ScenarioMcpRunnerDataService,
		ScenarioSummaryDataService,
		ReportDataService,
		IntegrationAppSocSettingsDataService,
		IntegrationArcherSettingsDataService,
		IntegrationFileSettingsDataService,
		IntegrationJobDataService,
		IntegrationQualysSettingsDataService,
		IntegrationSchedulerSettingsDataService,
		IntegrationSettingsDataService,
		IntegrationSnowflakeDataService,
		IntegrationSureCloudSettingsDataService,
		IntegrationTenableSettingsDataService,
		IntegrationWindowsDefenderSettingsDataService,
		ApplicationDataService,
		ApplicationLegalEntityMigrationDataService,
		AuditDataService,
		FeatureFlagDataService,
		FilesDataService,
		HealthDataService,
		LogoDataService,
		LogsDataService,
		MetadataDataService,
		NotificationDataService,
		SettingsDataService,
		TranslationDataService,
		AccountDataService,
		AuthorizationDataService,
		UserDataService,
		UserPreferencesDataService,
		UserRolesDataService,
		AnalysisSynchronizationDataService,
		SystemHealthDataService,
		FinancialLegalEntityDataService,
		PeerAnalyticsDataService,
		AggregatedFairDataService,
		FairConfidenceTypeDataService,
		FairDataService,
		FairInputDataService,
		FairLossDataService,
		FairMagnitudeValueDataService,
		FairRateOfIncidenceDataService,
		FairTEFValueDataService,
		ModifiedFairDataService,
		AggregatedFairAnalysisDataService,
		FairAnalysisDataService,
		FairAnalysisDetailsDataService,
		FairAnalysisInputDataService,
		FairAnalysisLossDataService,
		FairRecommendationDataService,
		FairRecommendationCostDataService,
		ModifiedFairAnalysisDataService,
		ApplicationCharacteristicDataService,
		ApplicationTypeDataService,
		BusinessEntityDataService,
		ConfigurationManagementDataService,
		EnterpriseCurrencyDataService,
		EnterpriseExchangeRateDataService,
		EnterpriseSSODataService,
		EnterpriseActorDataService,
		EnterpriseAttackDataService,
		EnterpriseCustomModelsDataService,
		EnterpriseCustomVariablesDataService,
		EnterpriseLegalEntityDataService,
		EnterpriseLegalEntityUserDataService,
		EnterpriseLookupTableDataService,
		EnterpriseLossFormulaDataService,
		EnterpriseLossLimitsDataService,
		EnterpriseNotificationDataService,
		EnterpriseScenarioDataService,
		EnterpriseUserLegalEntityDataService,
		EnterpriseAggregatedThreatsDataService,
		EnterpriseAnalysisApplicationDataService,
		EnterpriseAnalysisLegalEntityDataService,
		EnterpriseFairAnalysisDetailsDataService,
		EnterpriseImpactVectorDataService,
		EnterpriseRecommendationDataService,
		EnterpriseRecommendationCostDataService,
		EnterpriseScenarioAnalysisDataService,
		ActorDataService,
		ApplicationProfileDataService,
		ApplicationThreatsDataService,
		AttackDataService,
		BaApplicationProfileDataService,
		BaContractProfileDataService,
		BaCustomModelDataService,
		BaEndpointDataService,
		BaEnterpriseProfileDataService,
		BaExploitabilityLibraryDataService,
		BaGeneralControlDataService,
		BaLossExposureDataService,
		BaRevenueDataService,
		BusinessApplicationDataService,
		ContractProfileDataService,
		ControlDefensebilityDataService,
		ControlFrameworkDataService,
		ControlFrameworkDescriptionDataService,
		ControlProfileDataService,
		CurrencyDataService,
		CveBaDataService,
		CveDataService,
		CweDataService,
		DataProfileDataService,
		EndpointDataService,
		EndpointCveDataService,
		EndpointProfileDataService,
		EnterpriseProfileDataService,
		ExploitabilityDataService,
		ExploitabilityLibraryDataService,
		IndustryDataService,
		LegalEntityDataService,
		LegalEntityUserDataService,
		LocationDataService,
		LossExposureDataService,
		QualitativeScaleDataService,
		RevenueApplicationCharacteristicDataService,
		RevenueApplicationTypeDataService,
		RevenueBusinessEntityDataService,
		RevenueLossExposureDataService,
		AnalysisAggregatedThreatDataService,
		AnalysisBusinessApplicationDataService,
		AnalysisCustomLossDataService,
		AnalysisCveScoreWeightingDataService,
		AnalysisEnterpriseControlDataService,
		AnalysisHeatMapDataService,
		AnalysisImpactVectorDataService,
		AnalysisLegalEntityDataService,
		AnalysisLossImpactDataService,
		AnalysisResultsDataService,
		AnalysisRiskOverviewDataService,
		AnalysisRunnerDataService,
		AnalysisSummaryDataService,
		AnalysisThreatsDataService,
		BusinessApplicationLossDataService,
		CveRecommendationDataService,
		ImpactVectorLossDataService,
		InstanceDataService,
		LegalEntityLossDataService,
		RecomendationTTPDataService,
		RecommendationDataService,
		RecommendationCostDataService
	]
})
export class ServerServicesModule {}
