import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HttpStatusCode } from '../../system/enums/http-status-code.enum';
import { of } from 'rxjs';

export function errorValidator(err: { status: HttpStatusCode }) {
	if (err.status !== HttpStatusCode.NOT_FOUND) {
		throw err;
	}

	return of(null);
}

export function noWhiteSpaceAllowedValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value) {
			const hasWhiteSpaces = (control.value as string).includes(' ');

			return hasWhiteSpaces ? { whitespaceNotAllowed: true } : null;
		}

		return null;
	};
}

export function integerValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		const integerRegex = /^\d+$/;
		const value = control.value as string;

		if (value === '') {
			return null;
		}

		return !integerRegex.test(value) ? { integerNumber: { integerRegex } } : null;
	};
}

export function noWhiteSpaceValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value) {
			const isWhitespace = (control.value || '').trim().length === 0;

			return isWhitespace ? { whitespace: true } : null;
		}

		return null;
	};
}

export function requiredGroup(formGroup: FormGroup): ValidatorFn {
	return (_control: AbstractControl): ValidationErrors | null => {
		let checked = 0;

		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.controls[key];

			if (control.value) {
				checked++;
			}
		});

		if (checked < 1) {
			return <ValidationErrors>{
				required: true
			};
		}

		return null;
	};
}

export function emailValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		const emailRegex =
			/^ *(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})) *$/;

		const value = control.value as string;

		if (value === '') {
			return null;
		}

		return !emailRegex.test(value) ? { email: { emailRegex } } : null;
	};
}

export function atLeastOneRequiredValidator(): ValidatorFn {
	return (control: AbstractControl): { atLeastOneRequired: true } | null => {
		const existingValues = Object.keys((control as FormGroup).controls)
			.map(key => Boolean((control as FormGroup).get(key)?.value?.length))
			.filter(val => val);

		return existingValues.length ? null : { atLeastOneRequired: true };
	};
}

export function emailCharValidator() {
	return (control: AbstractControl): ValidationErrors | null => {
		const validChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+';

		if (!control.value) {
			return null;
		}

		const emailChars = control.value.split('') as string[];

		const invalidChars = Array.from(new Set(emailChars.filter(char => !validChars.includes(char))));
		const charMessage = invalidChars.join(', ');
		const message = `${charMessage} are not allowed in email address!`;

		return invalidChars.length > 0 ? { emailChar: { value: message } } : null;
	};
}

export function equalValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const target = control.get(targetKey) as FormControl;
		const toMatch = control.get(toMatchKey) as FormControl;

		if (target.touched && toMatch.touched) {
			const isMatch = target.value === toMatch.value;

			if (!isMatch && target.valid && toMatch.valid) {
				toMatch.setErrors({ equalValue: '' });
				const message = `${targetKey} != ${toMatchKey}`;
				return { equalValue: message };
			}

			if (isMatch && toMatch.hasError('equalValue')) {
				toMatch.setErrors(null);
			}
		}

		return null;
	};
}

export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const isOk = /^(?=.*\d)(?=.*\W)(?=.*[A-Z])(?=.*[a-z])[^\n]*$/.test(control.value as string);

		return !isOk ? { invalidPassword: { value: control.value as string } } : null;
	};
}

export function twoDecimalValidator() {
	return ({ value }: AbstractControl) => {
		if (value === null || value === undefined) {
			return null;
		}

		if (!/^-?[\d,?]+(\.\d{0,2})?$/.test(value as string)) {
			return {
				max_two_decimal_number: true
			};
		}
		return null;
	};
}

export function urlProtocolsNotAllowedValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const notAllowedProtocols = ['http://', 'https://'];
		const value: string = (control.value as string) || '';

		const hasError = notAllowedProtocols.some(url => value.includes(url));

		return hasError ? { notAllowedUrlProtocol: true } : null;
	};
}
