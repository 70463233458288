import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CACHE_KEY } from '../../shared/configs/cache.config';
import { CachingService } from '../services/caching.service';
import { IApiMonitor } from '../../routes/api-monitor/api-monitor.interface';

@Injectable({
	providedIn: 'root'
})
export class ApiPerformanceInterceptor implements HttpInterceptor {
	constructor(private cachingService: CachingService) {}

	public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
		const startTime = performance.now();

		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					const elapsedTime = performance.now() - startTime;
					const logEntry = {
						url: req.url,
						method: req.method,
						time: parseFloat(elapsedTime.toFixed(2)),
						status: event.status,
						timestamp: new Date().toISOString(),
						count: 0
					};

					try {
						const logs = (this.cachingService.getItem(CACHE_KEY.ApiPerformanceLogs) as Array<IApiMonitor>) || [];
						const filteredItems = logs.filter(x => x.url === logEntry.url);
						const count = filteredItems.length ? filteredItems[0].count + 1 : 1;
						filteredItems.forEach(x => (x.count = count));

						if (filteredItems.length < 10) {
							logEntry.count = count;
							logs.push(logEntry);
						} else {
							const fastestEntry = filteredItems.reduce((min, obj) => (obj.time < min.time ? obj : min), filteredItems[0]);
							fastestEntry.time = logEntry.time;
							fastestEntry.status = logEntry.status;
							fastestEntry.timestamp = logEntry.timestamp;
						}

						this.cachingService.setItem(CACHE_KEY.ApiPerformanceLogs, logs, false);
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error(error);
					}
				}
			})
		);
	}
}
