<section class="widgets">
  <rq-card class="widgets__widget"
           [title]="'Revenue'"
           [isLoaded]="data"
           [isFlexHeight]="true"
           [minLoadingHeight]="minHeight"
           [isNoDataVisible]="!data?.revenue">
    <ng-template rqCardContent>
      <rq-risk-overview-revenue-widget [data]="data?.revenue"></rq-risk-overview-revenue-widget>
    </ng-template>
  </rq-card>

  <rq-card class="widgets__widget"
           [title]="'Maximum Impact'"
           [isLoaded]="data"
           [minLoadingHeight]="minHeight"
           [isNoDataVisible]="!data?.maximumImpact">
    <ng-template rqCardContent>
      <rq-risk-overview-maximum-impact-widget [data]="data?.maximumImpact"
                                              (redirect)="redirectRisk($event)">
      </rq-risk-overview-maximum-impact-widget>
    </ng-template>
  </rq-card>

  <rq-card class="widgets__widget"
           [title]="'Likelihood'"
           [isLoaded]="data"
           [minLoadingHeight]="minHeight"
           [isNoDataVisible]="!data?.likelihood">
    <ng-template rqCardContent>
      <rq-risk-overview-likelihood-widget [data]="data?.likelihood"
                                          (redirect)="redirectRisk($event)">
      </rq-risk-overview-likelihood-widget>
    </ng-template>
  </rq-card>

  <rq-card class="widgets__widget"
           [title]="'Frequency'"
           [isLoaded]="data"
           [minLoadingHeight]="minHeight"
           [isNoDataVisible]="!data?.likelihood">
    <ng-template rqCardContent>
      <rq-risk-overview-frequency-widget [data]="data?.frequency"
                                         (redirect)="redirectRisk($event)">
      </rq-risk-overview-frequency-widget>
    </ng-template>
  </rq-card>
</section>
