import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { WidgetsCustomizerItemDisplayModel } from '../../models/widgets-customizer-item.displaymodel';
import { WidgetsService } from '../../widgets/widgets.service';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'rq-widgets-customizer',
	templateUrl: './widgets-customizer.component.html',
	styleUrls: ['./widgets-customizer.component.scss']
})
export class WidgetsCustomizerComponent extends BaseComponent implements OnChanges {
	@Input()
	public widgets!: Array<WidgetsCustomizerItemDisplayModel>;

	@Input()
	public excludeElement!: HTMLElement;

	@Output()
	public readonly close = new EventEmitter();

	@Output()
	public readonly update = new EventEmitter<Array<WidgetsCustomizerItemDisplayModel>>();

	@Output()
	public readonly reset = new EventEmitter();

	@Output()
	public readonly redirectToHelp = new EventEmitter();

	public isDisabled!: boolean;

	constructor(private widgetsService: WidgetsService) {
		super();
	}

	public ngOnChanges(changes: SimpleChanges) {
		const widgets = changes.widgets?.currentValue as Array<WidgetsCustomizerItemDisplayModel>;

		if (widgets) {
			this.isDisabled = false;
			this.isComponentLoaded = true;
		}
	}

	public trackByOrder(_index: number, widget: WidgetsCustomizerItemDisplayModel) {
		return widget.order;
	}

	public closeWidgetsCustomizer() {
		this.close.emit();
	}

	public redirectToWidgetsGuide() {
		this.redirectToHelp.emit();
	}

	public returnToDefault() {
		this.reset.emit();
		this.updateWidgets();
	}

	public widgetsDrop(event: CdkDragDrop<Array<WidgetsCustomizerItemDisplayModel>>) {
		moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);

		this.widgets.forEach((widget, i) => (widget.order = i + 1));

		this.updateWidgets();
	}

	public toggleWidgetsFullWidth(widget: WidgetsCustomizerItemDisplayModel) {
		widget.isFullWidth = !widget.isFullWidth;
		this.widgetsService.triggerRefresh(widget.type);
		this.updateWidgets();
	}

	public toggleWidgetsVisibility(widget: WidgetsCustomizerItemDisplayModel) {
		widget.isVisible = !widget.isVisible;
		this.updateWidgets();
	}

	private updateWidgets() {
		this.isDisabled = true;
		this.update.emit(this.widgets);
	}
}
