<ng-container *ngIf="data">
  <main class="widget__content">
    <span class="widget-headline">{{ data.currentValue | rqPercent }}</span>

    <ng-container *ngIf="data.currentValue && data.previousValue">
      <section class="widget__details">
        <rq-icon [href]="data.currentValue > data.previousValue ? 'icon-arrow-top-right' : 'icon-arrow-bottom-right'"
                 [color]="data.currentValue > data.previousValue ? 'color-danger' : 'color-success'">
        </rq-icon>
        <span [ngClass]="data.currentValue > data.previousValue ? 'widget__danger' : 'widget__success'">{{
          (data.currentValue > data.previousValue ?
          data.currentValue - data.previousValue : data.previousValue -
          data.currentValue) | rqPercent }}</span>
        <span class="text-description">since previous report</span>
      </section>
    </ng-container>
  </main>

  <section class="widget__risk">
    <rq-link [name]="data.riskName"
             (click)="redirectToRisk(data)"></rq-link>
    <article class="text-description">
      {{ data.attackType }}
    </article>
  </section>
</ng-container>
