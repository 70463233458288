import { Filter } from './filter.model';
import { FilterGroupModel } from './filter-group-model.model';
import { RecommendationConfig } from './../enums/recommendation-config.enum';

export class RecommendationCostFilterModel {
	public 'name'!: Filter<string>;
	public 'expectedLossReduction'!: Filter<FilterGroupModel<string, number>>;
	public 'expectedLossProbabilityReduction'!: Filter<FilterGroupModel<string, number>>;
	public 'applications'!: Filter<string[]>;
	public 'implementation'!: Filter<FilterGroupModel<RecommendationConfig, number>>;
	public 'roi'!: Filter<FilterGroupModel<RecommendationConfig, number>>;
	public 'proposedValue'!: Filter<string>;
}
