<ng-container *ngFor="let item of data">
  <section class="item">
    <div class="item__details">
      <div class="item__color"
           [style.backgroundColor]="item.color"></div>
      <div>{{item.label}}</div>
    </div>
    <div class="item__count">{{item.value}}</div>
  </section>
</ng-container>
