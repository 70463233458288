<section *ngIf="hasRightActionsTemplate"
         class="table-main-actions">
  <section *ngIf="hasRightActionsTemplate">
    <ng-template *ngTemplateOutlet="rightHeaderTemplate"></ng-template>
  </section>
</section>

<section>
  <table class="table">
    <thead>
      <tr class="table-head-row">
        <td *ngIf="hasCheckboxSelection"
            class="selectable-cell">
          <label>
            <input (input)="triggerSelectAllChange($event.target)"
                   [checked]="isAllSelected"
                   [disabled]="isLoading"
                   type="checkbox" />
            <span></span>
          </label>
        </td>
        <ng-container *ngFor="let column of tableColumns">
          <td *ngIf="!column.isHidden"
              [style.textAlign]="column.textAlign"
              [style.width]="column.field === 'actions' ? '0px' : column.width">
            <div class="table-head-data-content">
              <rq-table-head-data [column]="column"
                                  [isSortable]="column.isSortable && isSortable"
                                  (sortAction)="triggerSort(column)"
                                  [filterOutput]="filterOutput"></rq-table-head-data>
              <rq-table-head-filter *ngIf="hasFilters && column.isFilterable && column.filter"
                                    [column]="column"
                                    [isDisabled]="isLoading || isFirstLoadingVisible"
                                    [filterOutput]="filterOutput"
                                    (filterChange)="triggerFilterChange($event)"></rq-table-head-filter>
            </div>
          </td>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="hasData">
        <ng-template *ngTemplateOutlet="dataTemplate"></ng-template>
      </ng-container>

      <ng-container *ngIf="isFirstLoadingVisible">
        <ng-template *ngTemplateOutlet="firstLoadingTemplate"></ng-template>
      </ng-container>

      <ng-container *ngIf="isMessageVisible">
        <ng-template *ngTemplateOutlet="emptyTableTemplate"></ng-template>
      </ng-container>
    </tbody>
  </table>
</section>

<section *ngIf="isPagerConfigured">
  <rq-pager (pageChange)="triggerPageChange($event)"
            [config]="pagerConfig"
            [count]="count"
            [state]="pagerState">
  </rq-pager>
</section>

<ng-template #dataTemplate>
  <tr *ngFor="let data of tableData; let rowIndex = index"
      class="table-body-row"
      [class.selectable-row]="isRowSelectable"
      [class.selected-row]="isRowSelectable && rowIndex === selectedRowIndex"
      (click)="triggerRowClick(data, rowIndex)">
    <td *ngIf="hasCheckboxSelection"
        class="selectable-cell">
      <div>
        <label>
          <input (ngModelChange)="triggerSelectChange(data, $event)"
                 [(ngModel)]="data.isSelected"
                 [disabled]="isLoading"
                 type="checkbox" />
          <span></span>
        </label>
      </div>
      <div class="loading-bar"></div>
    </td>

    <ng-container *ngFor="let column of tableColumns; trackBy: trackByFn; let columnIndex = index">
      <td *ngIf="!column.isHidden"
          [class.cell-loading]="isColumnLoading(data, columnIndex)"
          class="body-table-cell"
          [style.width]="column.field === 'actions' ? '0px' : column.width"
          [style.textAlign]="column.textAlign">
        <div class="cell-content">
          <ng-template [ngTemplateOutlet]="column.template"
                       [ngTemplateOutletContext]="{ dataItem: data }">
          </ng-template>
        </div>
        <div class="loading-bar"></div>
      </td>
    </ng-container>
  </tr>
</ng-template>

<ng-template #firstLoadingTemplate>
  <tr class="table-body-row"
      *ngFor="let item of [].constructor(numberOfLoadingLines)">
    <td *ngIf="hasCheckboxSelection"
        class="body-table-cell cell-loading">
      <div class="loading-bar"></div>
    </td>
    <ng-container *ngFor="let column of tableColumns">
      <td *ngIf="!column.isHidden"
          class="body-table-cell cell-loading">
        <div class="loading-container">
          <div class="loading-bar"></div>
        </div>
      </td>
    </ng-container>
  </tr>
</ng-template>

<ng-template #emptyTableTemplate>
  <tr class="table-body-row">
    <ng-container *ngIf="hasCheckboxSelection">
      <td [attr.colspan]="tableColumns.length + 1">
        <span>{{'global_table_noData_with_filter' | translate}}</span>
        <div class="loading-bar"></div>
      </td>
    </ng-container>
    <ng-container *ngIf="!hasCheckboxSelection">
      <td [attr.colspan]="tableColumns.length">
        <span>{{'global_table_noData_with_filter' | translate}}</span>
        <div class="loading-bar"></div>
      </td>
    </ng-container>
  </tr>
</ng-template>
