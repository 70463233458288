<ng-container *ngIf="data && data.frequency">
  <section class="widget__content">
    <span class="widget-headline">{{ data.frequency }}</span>
  </section>

  <section class="widget__risk">
    <rq-link [name]="data.riskName"
             (click)="redirectToRisk(data)"></rq-link>

    <article class="text-description">
      {{ data.attackType }}
    </article>
  </section>
</ng-container>
