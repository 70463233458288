<rq-table [dataSource]="data"
          [pagerState]="{skip: 0, take: 5}"
          [hasFilters]="false"
          [isSortable]="false"
          [count]="count"
          (pageChange)="load($event)">
  <rq-table-column field="controlName"
                   title="Name">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p class="text-label"
         [title]="dataItem.controlName">{{ dataItem.controlName }}</p>
      <p class="text-description truncate-3"
         [title]="dataItem.controlContext">{{dataItem.controlContext}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="controlType"
                   title="Type">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p [title]="dataItem.controlType | controlSubTypeLabel">{{ dataItem.controlType | controlSubTypeLabel }}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="oldControl"
                   title="Recommendation">
    <ng-template tableData
                 let-dataItem="dataItem">
      <section class="controls__container">
        <rq-enterprise-controls-badge [value]="dataItem.oldControl"
                                      [isHighlighted]="true"></rq-enterprise-controls-badge>
        <rq-icon color="color-secondary"
                 href="icon-arrow-right"></rq-icon>
        <rq-enterprise-controls-badge [value]="dataItem.proposedControl"
                                      [isHighlighted]="true"></rq-enterprise-controls-badge>
      </section>

    </ng-template>
  </rq-table-column>

  <rq-table-column field="averageAnnualImpactReduction"
                   title="Average Annual Impact Reduction">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.averageAnnualImpactReduction | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="likelihoodReduction"
                   title="Likelihood Reduction">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.likelihoodReduction | rqPercent}}</span>
    </ng-template>
  </rq-table-column>
</rq-table>
<section class="m-t-xl">
  <button class="btn-secondary"
          (click)="redirectToLongTermRecommendationsPage()">
    {{ 'View All' | translate }}
    <rq-icon color="color-primary"
             href="icon-arrow-right"></rq-icon>
  </button>
</section>
