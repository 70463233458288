<label class="filter-label"
       *ngIf="displayName"><strong>{{displayName}}</strong></label>
<form #myForm="ngForm"
      class="search-wrapper">
  <input #inputEl
         name="inputEl"
         type="text"
         autocomplete="off"
         [class.disabled]="isDisabled"
         [disabled]="isDisabled"
         [(ngModel)]="value"
         (ngModelChange)="triggerFilterChange('change')"
         (blur)="triggerFilterChange('blur')"
         (keyup)="triggerChangeOnEnter($event)"
         placeholder="{{placeholder}}"
         class="form-control">
  <rq-icon *ngIf="!hasValue"
           class="filter-icon"
           href="icon-search"></rq-icon>

  <rq-icon *ngIf="hasValue"
           (mousedown)="!isDisabled && clear($event, inputEl)"
           class="filter-icon pointer"
           href="icon-close"></rq-icon>
</form>
