import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';

@Injectable({
	providedIn: 'root'
})
export class WidgetsService {
	public readonly refresh$: Observable<UiWidgetType>;

	private _refresh = new Subject<UiWidgetType>();

	constructor() {
		this.refresh$ = this._refresh.asObservable();
	}

	public triggerRefresh(type: UiWidgetType) {
		this._refresh.next(type);
	}
}
