import { Injectable, OnDestroy } from '@angular/core';
import { AnalysisResultsDataService } from '../../../../server/services/analysis/analysis-results-data.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AnalysisNotificationService } from '../../../../shared/services/analysis-notification.service';
import { AnalysisStatusModel } from '../../../../server/models/analysis-status-model.model';
import { RQAnalysisStatus } from '../../../../server/enums/rqanalysis-status.enum';
import { LossImpactAnalysisModel } from '../../../../server/models/loss-impact-analysis-model.model';
import { AnalysisScope } from '../../../../server/enums/analysis-scope.enum';
import { AnalysisType } from '../../../../server/enums/analysis-type.enum';

@Injectable({
	providedIn: 'root'
})
export class LastAnalysisStore implements OnDestroy {
	public readonly lastAnalysis$: Observable<LossImpactAnalysisModel | undefined>;

	private _lastAnalysis$ = new BehaviorSubject<LossImpactAnalysisModel | undefined>(undefined);

	private subscriptions: Subscription[] = [];

	private legalEntityId!: string;

	constructor(
		private analysisResultsDataService: AnalysisResultsDataService,
		private analysisNotificationService: AnalysisNotificationService
	) {
		this.lastAnalysis$ = this._lastAnalysis$.asObservable();
	}

	public get lastAnalysis() {
		return this._lastAnalysis$.getValue();
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	public async init(legalEntityId: string) {
		this.legalEntityId = legalEntityId;

		await this.getLastAnalysis();
		this.subscriptions.push(
			this.analysisNotificationService.analysisChange.subscribe(async (model: AnalysisStatusModel) => {
				if (
					model.status === RQAnalysisStatus.Success &&
					model.scope === AnalysisScope.Default &&
					model.analysisType === AnalysisType.Analysis
				) {
					await this.getLastAnalysis();
				}
			})
		);
	}

	private async getLastAnalysis() {
		const data = await this.analysisResultsDataService.getLastAnalysis(this.legalEntityId);
		this._lastAnalysis$.next(data);
	}
}
