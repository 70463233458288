<form #myForm="ngForm"
      class="search-wrapper">
  <input name="inputEl"
         type="text"
         autocomplete="off"
         [class.disabled]="isDisabled"
         [disabled]="isDisabled"
         [(ngModel)]="value"
         (keyup)="triggerChangeOnEnter($event)"
         placeholder="Search"
         class="form-control">

  <rq-icon class="filter-icon"
           href="icon-search"></rq-icon>
</form>

<section class="actions-wrapper m-t-sm">
  <button type="button"
          e2e-test="clear-button"
          class="btn-secondary-sm action-button"
          [class.disabled]="isDisabled"
          (click)="clear()">Clear</button>
  <button type="button"
          e2e-test="search-button"
          class="btn-primary-sm action-button"
          [class.disabled]="!isValidForSearch || isDisabled"
          (click)="search()">Search</button>
</section>
