<header class="widgets-customizer__header">
  <button class="btn-icon-sm"
          (click)="closeWidgetsCustomizer()">
    <rq-icon href="icon-close"></rq-icon>
  </button>
  <button *ngIf="false"
          (click)="redirectToWidgetsGuide()"
          class="btn-secondary-sm">
    <rq-icon href="icon-help-circle"
             color="color-secondary"></rq-icon>
    <span>Widgets Guide</span>
  </button>
</header>


<rq-card [isContent]="true"
         [isLoaded]="isComponentLoaded"
         [isFlexHeight]="true">
  <ng-template rqCardContent>
    <section class="widgets-customizer__container">
      <section class="widgets-customizer__details">
        <p class="widgets-customizer__title">Widgets</p>
        <button class="btn-secondary"
                (click)="returnToDefault()"
                [disabled]="isDisabled">
          <rq-icon href="icon-refresh-repeat"></rq-icon><span>{{'tuning_button_backToDefault' | translate}}</span>
        </button>
      </section>

      <div (cdkDropListDropped)="widgetsDrop($event)"
           class="widgets-customizer__items"
           cdkDropList
           [cdkDropListDisabled]="isDisabled">
        <ng-container *ngFor="let widget of widgets; trackBy: trackByOrder">
          <div cdkDrag
               cdkDragLockAxis="y"
               class="widgets-customizer__item">
            <article class="widgets-customizer__left">
              <button class="btn-icon-ghost handle"
                      [disabled]="isDisabled">
                <rq-icon href="icon-menu-hambuger"></rq-icon>
              </button>
              <p class="widgets-customizer__name truncate-1 no-select"
                 [title]="widget.name">{{widget.name}}</p>
            </article>
            <article class="widgets-customizer__right">
              <div class="widgets-cutomizer__switch"
                   *ngIf="!widget.isFullWidthDisabled">
                <rq-switch (change)="toggleWidgetsFullWidth(widget)"
                           [ngModel]="widget.isFullWidth"
                           [isDisabled]="isDisabled"
                           [isRightLabelHidden]="true"
                           [leftLabelText]="'Full Width'"></rq-switch>
              </div>

              <button [ngClass]="widget.isVisible ? 'btn-icon-ghost' : 'btn-icon-ghost-danger'"
                      (click)="toggleWidgetsVisibility(widget)"
                      [disabled]="isDisabled">
                <rq-icon [href]="widget.isVisible ? 'icon-eye-password' : 'icon-eye-password-off'"></rq-icon>
              </button>
            </article>
          </div>
        </ng-container>
      </div>
    </section>
  </ng-template>
</rq-card>
