import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CountLegendUiModel } from './count-legend.ui-model';

@Component({
	selector: 'rq-count-legend',
	templateUrl: './count-legend.component.html',
	styleUrls: ['./count-legend.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountLegendComponent {
	@Input()
	public data!: Array<CountLegendUiModel>;
}
