import { AppInjector } from '../utils/app-injector.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { getRouteParams } from '../utils/route.utils';
import { LicensePermission } from '../core/licenses/license-permission.enum';
import { EFeatureFlag } from '../../server/enums/efeature-flag.enum';

@Directive()
export class BaseComponent implements OnDestroy {
	public subscriptions: Subscription[] = [];

	public LicensePermission = LicensePermission;

	public EFeatureFlag = EFeatureFlag;

	public isComponentLoaded!: boolean;

	public get params() {
		const activatedRoute = AppInjector.injector.get(ActivatedRoute);
		return getRouteParams(activatedRoute.snapshot);
	}

	public get urlLegalEntityId() {
		return (this.params.legalEntityId as string) || undefined;
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	public getLastUrlSegment() {
		const urlSegments = this.getUrlSegments();
		return urlSegments[urlSegments.length - 1];
	}

	public getUrlSegments() {
		const router = AppInjector.injector.get(Router);
		const urlWithoutQueryParams = router.url.split('?')[0];
		return urlWithoutQueryParams.split('/');
	}
}
