import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { IBubbleChart } from './models/bubble-chart.interface';
import { EChartsType, init } from 'echarts';
import { ChartService } from '../chart.service';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { BubbleChartConfig } from './models/bubble-chart.config';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-bubble-chart',
	templateUrl: './bubble-chart.component.html',
	styleUrls: ['./bubble-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChartService]
})
export class BubbleChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	@Input()
	public data!: IBubbleChart;

	@Input()
	public config!: BubbleChartConfig;

	private chart?: EChartsType;

	private resizeObserver!: ResizeObserver;

	private fontFamily = 'Inter, sans-serif';

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		this.resize();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const data = changes.data?.currentValue as IBubbleChart;

		if (data && this.config) {
			this.initChart(data);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private initChart(data: IBubbleChart) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				color: this.config.colors,
				tooltip: this.getTooltipConfig(),
				grid: this.getGridConfig(),
				legend: this.getLegendConfig(data),
				xAxis: this.getXAxisConfig(data),
				yAxis: this.getYAxisConfig(data),
				series: this.getSeriesConfig(data)
			};

			this.chart.setOption(options);
		} else {
			this.chart?.dispose();
		}
	}

	private getTooltipConfig() {
		return {
			borderColor: COLORS.neutralFill,
			borderRadius: 4,
			padding: 16,
			textStyle: {
				fontSize: 14,
				fontFamily: this.fontFamily
			},
			extraCssText: COLORS.shadowMedium,
			formatter: ({ value }: CallbackDataParams) => {
				const dataValues = value as Array<unknown>;
				const [_xValue, _yValue, _bubbleValue, id] = dataValues;

				let template = '';
				const item = this.findItemById(id as string);
				if (item?.tooltipTemplate) {
					template = item.tooltipTemplate;
				}

				return template;
			}
		};
	}

	private getGridConfig() {
		return {
			top: 40,
			bottom: this.config.isLegendVisible ? 80 : 55,
			left: 70,
			right: 20
		};
	}

	private getLegendConfig(data: IBubbleChart) {
		return {
			show: this.config.isLegendVisible,
			itemWidth: 22,
			itemHeight: 7,
			itemGap: 5,
			bottom: 0,
			icon: 'rect',
			formatter: (value: string) => value.replace(/\n/g, ''),
			textStyle: {
				width: (this.chartContainer.nativeElement.scrollWidth / data.groups.length) * (80 / 100),
				overflow: 'truncate'
			},
			tooltip: {
				show: this.config.isLegendTooltipVisible,
				formatter: ({ name }: CallbackDataParams) => `${name}`
			}
		};
	}

	private getXAxisConfig(data: IBubbleChart) {
		const axisLabelsLength = data?.xAxiesLabels?.length ?? data.groups.length;
		return [
			{
				type: 'category',
				triggerEvent: true,
				axisTick: { show: false },
				name: this.config.xAxisLabel,
				nameLocation: 'middle',
				nameGap: 35,
				nameTextStyle: {
					color: COLORS.neutralContrastBackground,
					fontSize: 12,
					fontWeight: 500,
					fontFamily: this.fontFamily
				},
				axisLine: {
					show: true,
					lineStyle: { color: COLORS.neutralContrastBackground }
				},
				axisLabel: {
					interval: 0,
					overflow: 'truncate',
					formatter: (value: string) => {
						return value.replace(/\n/g, '');
					},
					width: (this.chartContainer.nativeElement.scrollWidth / axisLabelsLength ?? 1) * (85 / 100)
				},
				splitLine: {
					show: false,
					lineStyle: {
						type: 'dashed'
					}
				},

				data: data.xAxiesLabels ?? data.groups.map(x => x.name)
			},
			{
				min: data.xMinValue,
				max: data.xMaxValue,
				inverse: this.config.invertXAxies,
				type: 'value',
				show: false
			}
		];
	}

	private getYAxisConfig(_data: IBubbleChart) {
		return [
			{
				type: 'value',
				axisTick: { show: false },
				name: this.config.yAxisLabel,
				nameLocation: 'middle',
				nameGap: 55,
				nameTextStyle: {
					color: COLORS.neutralContrastBackground,
					fontSize: 12,
					fontWeight: 500,
					fontFamily: this.fontFamily
				},
				axisLabel: {
					formatter: this.chartService.getFormatter(this.config.yAxisFormatter)
				},
				splitLine: {
					show: true,
					lineStyle: {
						color: COLORS.neutralFill,
						type: 'dashed'
					}
				}
			}
		];
	}

	private getSeriesConfig(data: IBubbleChart) {
		const maxBubbleSize = 80;
		const minBubbleSize = 20;
		const defaultBubbleSize = 45;

		return data.groups.map((group, _index) => ({
			type: 'scatter',
			name: group.name,
			xAxisIndex: data.xAxiesLabels ? 1 : 0,
			yAxisIndex: 0,
			//dataValues should be an array, [xValue, yValue, bubbleValue, label, displayValue, x.id]
			data: group.items.map(x => [x.xAxiesValue, x.yAxiesValue, x.bubbleValue, x.id]),
			symbolSize: (dataValues: Array<number>) => {
				const [_xValue, _yValue, bubbleValue, _id] = dataValues;
				return bubbleValue
					? Math.max(Math.min(bubbleValue * this.config.bubbleSizeMultiplier, maxBubbleSize), minBubbleSize)
					: defaultBubbleSize;
			},
			label: {
				show: false
			},
			itemStyle: {
				color: group.color
			}
		}));
	}

	private resize() {
		if (this.chart) {
			this.chart.resize();

			this.chart.setOption(
				{
					legend: this.getLegendConfig(this.data),
					xAxis: this.getXAxisConfig(this.data)
				},
				{ silent: true }
			);
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}

	private findItemById(id: string) {
		if (!id) {
			return null;
		}

		for (const group of this.data.groups) {
			const item = group.items.find(x => x.id === id);
			if (item) {
				return item;
			}
		}
		return null;
	}
}
