<rq-card [title]="'Top Controls Likelihood Reduction'"
         [isLoaded]="data"
         [isFlexHeight]="true"
         [isNoDataVisible]="!data">
  <ng-template rqCardContent>
    <rq-top-controls-likelihood-reduction-table-widget *ngIf="data"
                                                       [data]="data"
                                                       [count]="tableCount"></rq-top-controls-likelihood-reduction-table-widget>
  </ng-template>
</rq-card>
