import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../../shared/configs/routes.config';

export const isDevelopmentGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	if (!environment.production) {
		return true;
	}
	await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
	return false;
};
