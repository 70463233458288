import { trigger, transition, style, animate, AnimationTriggerMetadata, state } from '@angular/animations';

const cubicBezierEnter = '300ms cubic-bezier(0.25, 1, 0.5, 1)';
const cubicBezierLeave = '200ms cubic-bezier(0.65, 0, 0.35, 1)';

export const slideInFromRightAnimation = trigger('slideInFromRightAnimation', [
	transition(':enter', [
		style({
			transform: 'translateX(100%)'
		}),
		animate(
			cubicBezierEnter,
			style({
				transform: 'translateX(0)'
			})
		)
	]),
	transition(':leave', [
		animate(
			cubicBezierLeave,
			style({
				transform: 'translateX(100%)'
			})
		)
	])
]);

export const slideInFromLeftAnimation = trigger('slideInFromLeftAnimation', [
	transition(':enter', [
		style({
			transform: 'translateX(-100%)'
		}),
		animate(
			cubicBezierEnter,
			style({
				transform: 'translateX(0)'
			})
		)
	]),
	transition(':leave', [
		animate(
			cubicBezierLeave,
			style({
				transform: 'translateX(-100%)'
			})
		)
	])
]);

export function slideInFromLeftWithWidth(expandedWidth: string, collapsedWidth: string): AnimationTriggerMetadata {
	return trigger('sidebarAnimation', [
		state('expanded', style({ width: expandedWidth, opacity: 1 })),
		state('collapsed', style({ width: collapsedWidth, opacity: 1 })),

		transition('expanded <=> collapsed', [animate(cubicBezierEnter)])
	]);
}
