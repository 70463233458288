import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomBarChartDisplayModel } from './custom-bar-chart.displaymodel';

@Component({
	selector: 'rq-custom-bar-chart',
	templateUrl: './custom-bar-chart.component.html',
	styleUrls: ['./custom-bar-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomBarChartComponent {
	@Input()
	public data!: Array<CustomBarChartDisplayModel>;

	@Input()
	public growDirection: 'vertical' | 'horizontal' = 'vertical';
}
