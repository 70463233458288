import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { TableColumnComponent } from '../table-column/table-column.component';
import {
	CalendarFilter,
	DropdownFilter,
	Filter,
	FilterBase,
	FilterOutput,
	FilterRange,
	NumericFilter,
	RangeFilter,
	SearchFilter,
	SelectFilter
} from '../../form-elements/filter/filter.model';
import { Customizer } from '../../../../shared/services/customizer';
import { isNullOrUndefined } from '../../../utils/helpers.utils';

@Component({
	selector: 'rq-table-head-filter',
	templateUrl: './table-head-filter.component.html',
	styleUrls: ['./table-head-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeadFilterComponent {
	@ViewChild('filterButton')
	public filterButtonRef?: ElementRef;

	@Input()
	public isDisabled!: boolean;

	@Input()
	public column!: TableColumnComponent;

	@Input()
	public filterOutput = {} as FilterOutput;

	@Output()
	public readonly filterChange = new EventEmitter<FilterOutput>();

	public isPopupExpanded!: boolean;

	public isActive!: boolean;

	public alignLeft = true;

	constructor(private customizer: Customizer, public el: ElementRef) {}

	@HostListener('window:resize')
	public onResize() {
		this.adjustAlignment();
	}

	public numberFilter(value: FilterBase) {
		return value as NumericFilter;
	}

	public dropdownFilter(value: FilterBase) {
		return value as DropdownFilter;
	}

	public rangeFilter(value: FilterBase) {
		return value as RangeFilter;
	}

	public calendarFilter(value: FilterBase) {
		return value as CalendarFilter;
	}

	public selectFilter(value: FilterBase) {
		return value as SelectFilter;
	}

	public triggerValueChange(value: unknown, hasAutoClose = true) {
		this.filterOutput[this.column.field].value = value;
		this.filterChange.emit(this.filterOutput);

		if (hasAutoClose) {
			this.closePopup();
		}
	}

	public clear() {
		if (this.filterOutput[this.column.field].value !== null) {
			this.filterOutput[this.column.field].value = null;
			this.filterChange.emit(this.filterOutput);
		}

		this.closePopup();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.isDisabled?.currentValue && this.column && this.filterOutput) {
			this.updateActiveStateFilter();
		}

		if (changes.column?.currentValue) {
			this.setupFilters(changes.column.currentValue as TableColumnComponent);
		}
	}

	public closePopup() {
		this.isPopupExpanded = false;
	}

	public openFilter() {
		this.isPopupExpanded = !this.isPopupExpanded;
		this.adjustAlignment();
	}

	private updateActiveStateFilter() {
		const value = this.filterOutput[this.column.field]?.value;
		this.isActive = !isNullOrUndefined(value) && value !== '';
	}

	private adjustAlignment() {
		const popup = this.el.nativeElement.querySelector('.filter-button') as HTMLElement;
		if (!popup) {
			return;
		}

		const rect = popup.getBoundingClientRect();
		this.alignLeft = rect.left > 300;
	}

	private setupFilters(column: TableColumnComponent) {
		if (!column?.filter) {
			return;
		}

		switch (column.filter) {
			case 'string': {
				this.setupDefaultStringFilter(column);
				break;
			}
			case 'numeric': {
				this.setupDefaultNumericFilter(column);
				break;
			}
			case 'range': {
				this.setupDefaultRangeFilter(column);
				break;
			}
			case 'select': {
				this.setupDefaultSelectFilter(column);
				break;
			}
			case 'dropdown': {
				this.setupDefaultDropdownFilter(column);
				break;
			}
			case 'calendar': {
				this.setupDefaultCalendarFilter(column);
				break;
			}
			default: {
				throw new Error('Invalid filter type');
			}
		}

		if (!this.filterOutput[column.field]) {
			this.filterOutput[column.field] = new Filter<unknown>();
			this.filterOutput[column.field].value = column.filterSetup.value;
		}

		this.setupPreselectedValue(column);
	}

	private setupDefaultStringFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new SearchFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;
			column.filterSetup = filterSetup;
		}
	}

	private setupDefaultNumericFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new NumericFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;

			column.filterSetup = filterSetup;
		}
	}

	private setupDefaultRangeFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new RangeFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;

			column.filterSetup = filterSetup;
		}
	}

	private setupDefaultSelectFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new SelectFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;

			column.filterSetup = filterSetup;
		}
	}

	private setupDefaultDropdownFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new DropdownFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;

			column.filterSetup = filterSetup;
		}
	}

	private setupDefaultCalendarFilter(column: TableColumnComponent) {
		if (!column.filterSetup) {
			const filterSetup = new CalendarFilter();
			filterSetup.displayName = column.title;
			filterSetup.placeholder = column.title;
			filterSetup.format = this.customizer.date.format;
			filterSetup.datePattern = this.customizer.date.format;

			if (column.preselectedValue) {
				this.filterOutput[column.field] = new Filter(column.preselectedValue);
			}

			column.filterSetup = filterSetup;
		}
	}

	private setupPreselectedValue(column: TableColumnComponent) {
		if (column.preselectedValue) {
			this.checkPreselectedValueToBeValid(column);
			this.filterOutput[column.field] = new Filter(column.preselectedValue);
		}
	}

	private checkPreselectedValueToBeValid(column: TableColumnComponent) {
		switch (column.filter) {
			case 'string': {
				this.checkPreselectedValueForStringFilter(column);
				break;
			}

			case 'numeric': {
				this.checkPreselectedValueForNumericFilter(column);
				break;
			}

			case 'range': {
				this.checkPreselectedValueForRangeFilter(column);
				break;
			}

			case 'calendar': {
				this.checkPreselectedValueForCalendarFilter(column);
				break;
			}

			case 'select': {
				this.checkPreselectedValueForSelectFilter(column);
				break;
			}

			case 'dropdown': {
				this.checkPreselectedValueForDropdownFilter(column);
				break;
			}
			default: {
				break;
			}
		}
	}

	private checkPreselectedValueForSelectFilter(column: TableColumnComponent) {
		const values = column.preselectedValue as Array<unknown>;

		const options: Array<unknown> = [];
		(column.filterSetup as SelectFilter).groups.forEach(g => {
			g.options.forEach(o => {
				options.push(o.value);
			});
		});

		if (!values || !values.every(v => options.includes(v))) {
			throw new Error('For select filter the preselectedValue must be an array of valid options');
		}
	}

	private checkPreselectedValueForRangeFilter(column: TableColumnComponent) {
		const value = column.preselectedValue as FilterRange<unknown>;
		if (value.range?.min === undefined || value.range?.max === undefined) {
			throw new Error('For range filter the preselectedValue must be a FilterRange type');
		}
	}

	private checkPreselectedValueForStringFilter(column: TableColumnComponent) {
		if (typeof column.preselectedValue !== 'string') {
			throw new Error('For text filter the preselectedValue must be a string');
		}
	}

	private checkPreselectedValueForNumericFilter(column: TableColumnComponent) {
		if (typeof column.preselectedValue !== 'number') {
			throw new Error('For numeric filter the preselectedValue must be a number');
		}
	}

	private checkPreselectedValueForDropdownFilter(column: TableColumnComponent) {
		const preselectedOption = (column.filterSetup as DropdownFilter).options?.find(x => x.value === column.preselectedValue);
		if (!preselectedOption) {
			throw new Error('For dropdown filter the preselectedValue must be a valid option');
		}
	}

	private checkPreselectedValueForCalendarFilter(_column: TableColumnComponent) {
		throw new Error('Calendar filter preselectedValue is not supported');
		//There is an issue on calendar filter to update the values.
		// const value = column.preselectedValue as FilterCalendarValue
		// if (value.start === undefined || value.occurrence === undefined) {
		//   throw new Error("For calendar filter the preselectedValue must be a FilterCalendarValue type");
		//}
	}
}
