import { ApplicationEnterpriseProfileType } from '../../server/enums/application-enterprise-profile-type.enum';
import { ControlProfileType } from '../../server/enums/control-profile-type.enum';
import { LossExposureType } from '../../server/enums/loss-exposure-type.enum';
import { NotificationType } from '../../server/enums/notification-type.enum';
import { ScenarioType } from '../../server/enums/scenario-type.enum';
import { BusinessAppWizzardStep } from './_common/enums/business-application-wizard-step.enum';
import { BusinessAppWizzardSubStep } from './_common/enums/business-application-wizard-substep.enum';
import { ImpactVectorTabs } from './_common/enums/impact-vector-dashboard.model';
import { IntegrationWizardStep } from './_common/enums/integration-wizard-step.model';
import {
	LossExposureDataEditWizardStep,
	LossExposureEditWizardStep,
	LossExposureRevenueEditWizardStep,
	SemiAutomatedFairEditWizardStep
} from './_common/enums/loss-exposure-edit.model';
import { LoseExposureSourceType } from './_common/enums/loss-exposure-source-type.model';
import { ModelInsightsTabs } from './_common/enums/page-how-to-model-insights-tabs.enum';
import { ScenarioMultipleLevelsDetails } from './_common/enums/scenarios-multiple-levels-details.model';
import { ThirdPartyWizardStep } from './_common/enums/third-party-wizard-step.enum';
import { ControlProfileWizardStep } from './_common/models/control-profile-wizard-step.model';
import { ScenarioAggregatedFairWizardStep } from './_common/models/scenarios-aggregated-fair-edit.model';
import { ScenarioEnterpriseEditWizardStep } from './_common/models/scenarios-enterprise-edit.model';
import { ScenarioFairWizzardStep } from './_common/models/scenarios-fair-edit.model';
import { ScenarioMachineLearningWizardStep } from './_common/models/scenarios-machine-learning-edit.model';
import { ScenarioMultipleLevelsWizardStep } from './_common/models/scenarios-multiple-levels-edit.model';
import { ScenariosRateOfIncidentEditWizardStep } from './_common/models/scenarios-rate-of-incident-edit.model';
import { ScenarioMitreWizardStep } from './_common/enums/scenario-mitre-wizard-step.enum';
import { ModelTuningTabs } from './model/model-tuning-tab/model-tuning-tabs.enum';

const createFromBusinessApp = 'create/:businessApplicationId';
const scenarioViewDetailsPath = 'details/:scenarioId/:scenarioType';
const editScenarioPath = 'edit/:scenarioId';

export const PRO_ROUTE = {
	mainPath: 'pro',
	path: 'pro/:legalEntityId',
	url: controlAnalysisUrl,
	oldDashboard: {
		path: 'old-dashboard',
		url: oldDashboard
	},
	dashboard: {
		path: 'dashboard',
		url: dashboard
	},

	output: {
		path: 'output',
		url: dataOutputUrl,
		tabs: {
			reports: {
				path: 'reports',
				url: outputReportstUrl
			},
			csvExport: {
				path: 'csv-export',
				url: outputCsvExportUrl
			},
			apiExport: {
				path: 'api-export',
				url: outputApiExportUrl
			}
		}
	},
	scenarios: {
		path: 'scenarios',
		url: scenariosUrl,
		create: {
			url: scenariosCreateUrl
		},
		compare: {
			path: 'compare',
			url: scenariosCompare
		},
		enterprise: {
			path: 'enterprise',
			edit: {
				path: editScenarioPath,
				url: scenariosEnterpriseEditUrl,
				overview: {
					path: `${ScenarioEnterpriseEditWizardStep.Overview}`
				},
				enterpriseControl: {
					path: `${ScenarioEnterpriseEditWizardStep.EnterpriseControls}`
				},
				summary: {
					path: `${ScenarioEnterpriseEditWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosEnterpriseDetailsView
			}
		},
		fair: {
			path: 'fair',
			edit: {
				path: editScenarioPath,
				url: scenariosFairEditUrl,
				overview: {
					path: `${ScenarioFairWizzardStep.Overview}`
				},
				lossEventFrequency: {
					path: `${ScenarioFairWizzardStep.LossEventFrequency}`
				},
				primaryLoss: {
					path: `${ScenarioFairWizzardStep.PrimaryLoss}`
				},
				secondaryRisk: {
					path: `${ScenarioFairWizzardStep.SecondaryRisk}`
				},
				summary: {
					path: `${ScenarioFairWizzardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosFairView
			},
			configuration: {
				path: 'configuration/:scenarioId/:scenarioType',
				url: scenariosFairConfigurationView
			}
		},
		modifiedFair: {
			path: 'modified-fair',
			edit: {
				path: editScenarioPath,
				url: scenariosModifiedFairEditUrl,
				overview: {
					path: `${ScenarioFairWizzardStep.Overview}`
				},
				lossEventFrequency: {
					path: `${ScenarioFairWizzardStep.LossEventFrequency}`
				},
				primaryLoss: {
					path: `${ScenarioFairWizzardStep.PrimaryLoss}`
				},
				secondaryRisk: {
					path: `${ScenarioFairWizzardStep.SecondaryRisk}`
				},
				summary: {
					path: `${ScenarioFairWizzardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosModifiedFairView
			},
			configuration: {
				path: 'configuration/:scenarioId/:scenarioType',
				url: scenariosModifiedFairConfigurationView
			}
		},
		aggregatedFair: {
			path: 'aggregated-fair',
			edit: {
				path: editScenarioPath,
				url: scenariosAggregatedFairEditUrl,
				overview: {
					path: `${ScenarioAggregatedFairWizardStep.Overview}`
				},
				scenariosList: {
					path: `${ScenarioAggregatedFairWizardStep.ScenariosList}`
				},
				summary: {
					path: `${ScenarioAggregatedFairWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosAggregatedFairView
			}
		},
		rateOfIncident: {
			path: 'rate-of-incident',
			edit: {
				path: editScenarioPath,
				url: scenariosRateOfIncidenceEditUrl,
				overview: {
					path: `${ScenariosRateOfIncidentEditWizardStep.Overview}`
				},
				rateOfIncidence: {
					path: `${ScenariosRateOfIncidentEditWizardStep.RateOfIncidence}`
				},
				summary: {
					path: `${ScenariosRateOfIncidentEditWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosRateOfIncidenceView
			}
		},
		machineLearning: {
			path: 'machine-learning',
			edit: {
				path: editScenarioPath,
				url: scenariosMachineLearningEditUrl,
				overview: {
					path: `${ScenarioMachineLearningWizardStep.Overview}`
				},
				identifyBusinessAssets: {
					path: `${ScenarioMachineLearningWizardStep.IdentifyBusinessAssets}`
				},
				identifyDefenses: {
					path: `${ScenarioMachineLearningWizardStep.IdentifyDefenses}`
				},
				identifyThreatVector: {
					path: `${ScenarioMachineLearningWizardStep.IdentifyThreatVector}`
				},
				summary: {
					path: `${ScenarioMachineLearningWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosMachineLearningView
			}
		},
		multipleLevels: {
			path: 'multiple-levels',
			edit: {
				path: editScenarioPath,
				url: scenariosMultipleLevelsEditUrl,
				overview: {
					path: `${ScenarioMultipleLevelsWizardStep.Overview}`
				},
				application: {
					path: `${ScenarioMultipleLevelsWizardStep.Application}`
				},
				identifyDefenses: {
					path: `${ScenarioMultipleLevelsWizardStep.IdentifyDefenses}`
				},
				summary: {
					path: `${ScenarioMultipleLevelsWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosMultipleLevelsView,
				comparison: {
					path: `${ScenarioMultipleLevelsDetails.Comparison}`
				},
				recommendations: {
					path: `${ScenarioMultipleLevelsDetails.Recommendations}`
				}
			}
		},
		mitre: {
			path: 'mitre',
			edit: {
				path: editScenarioPath,
				url: scenariosMitreEditUrl,
				overview: {
					path: `${ScenarioMitreWizardStep.Overview}`
				},
				identifyBusinessAssetsAtRisk: {
					path: `${ScenarioMitreWizardStep.IdentifyBusinessAssetsAtRisk}`
				},
				definingTheAttacker: {
					path: `${ScenarioMitreWizardStep.DefiningTheAttacker}`
				},
				identifyTheScenario: {
					path: `${ScenarioMitreWizardStep.IdentifyTheScenario}`
				},
				identifyControlLevel: {
					path: `${ScenarioMitreWizardStep.ControlMaturityAndObservations}`
				},
				summary: {
					path: `${ScenarioMitreWizardStep.Summary}`
				}
			},
			details: {
				path: scenarioViewDetailsPath,
				url: scenariosMitreDetailsView
			}
		}
	},
	recommendations: {
		path: 'recommendations',
		url: recommendationsUrl,
		tabs: {
			longTerm: {
				path: 'long-term',
				url: recommendationsTabLongTermUrl
			},
			shortTerm: {
				path: 'short-term',
				url: recommendationsTabShortTermUrl
			}
		}
	},
	financial: {
		path: 'financial',
		url: financialUrl,
		tabs: {
			applications: {
				path: 'applications',
				url: financialApplicationsUrl
			},
			financialAnalysis: {
				path: 'financial-analysis',
				url: financialAnalysisUrl
			},
			impactVector: {
				path: 'impact-vector',
				url: financialImpactVectorUrl,
				tab: {
					path: ':tabId',
					url: financialImpactVectorUrlTab
				}
			},
			analysisMatrix: {
				path: 'analysis-matrix',
				url: financialAnalysisMatrixUrl
			}
		}
	},
	technicalAnalysis: {
		path: 'technical-analysis',
		url: technicalAnalysisUrl,
		tabs: {
			technicalAssets: {
				path: ':analysisId/technical-assets',
				url: technicalAssetsUrl
			},
			technicalRisks: {
				path: ':analysisId/technical-risks',
				url: technicalRisksUrl
			},
			endpointProfile: {
				path: 'endpoint-profile',
				url: technicalEndpointProfileUrl
			}
		}
	},
	attackNavigator: {
		path: 'attack-navigator',
		url: attackNavigatorUrl
	},
	mitreDashboard: {
		path: 'mitre-dashboard',
		url: mitreDashboardUrl
	},
	configure: {
		path: 'configure',
		url: configure,
		businessApplication: {
			path: 'business-application',
			url: businessApplicationUrl,
			edit: {
				path: 'edit/:businessApplicationId',
				url: businessApplicationEditUrl,
				generalInformation: {
					path: `${BusinessAppWizzardStep.GeneralInformation}`
				},
				identifyAttackSurface: {
					path: `${BusinessAppWizzardStep.IdentifyAttackSurface}`
				},
				identifyThreats: {
					path: `${BusinessAppWizzardStep.IdentifyThreats}`
				},
				businessAsset: {
					path: `${BusinessAppWizzardStep.BusinessAsset}`
				},
				customModels: {
					path: `${BusinessAppWizzardStep.CustomModels}`
				},
				summary: {
					path: `${BusinessAppWizzardStep.Summary}`
				}
			}
		},
		legalEntity: {
			path: 'legal-entity',
			url: legalEntityUrl
		},
		controlProfile: {
			path: 'control-profile',
			url: controlProfileUrl,
			edit: {
				path: 'edit/:controlProfileId/:controlProfileType',
				url: controlProfileEditUrl,
				generalInformation: {
					path: `${ControlProfileWizardStep.GeneralInformation}`
				},
				controlLevel: {
					path: `${ControlProfileWizardStep.ControlLevel}`
				},
				summary: {
					path: `${ControlProfileWizardStep.Summary}`
				},
				editWithControlProfile: {
					path: ':stepType',
					url: businessApplicationEditWithControlProfileUrl
				}
			},
			create: {
				url: scenariosCreateUrl
			},
			createControlProfileFromThirdParty: {
				path: 'create/:thirdPartyId',
				url: createControlProfileFromThirdPartyUrl
			},
			createFromBusinessApp: {
				path: 'create/:businessApplicationId/:controlProfileType',
				url: createControlProfileFromBusinessAppUrl
			},
			createFromBusinessAppAndEnterpriseFramework: {
				path: 'create/:businessApplicationId/:controlProfileType/:frameworkId',
				url: createControlProfileFromBusinessAppAndEnterpriseFrameworkUrl
			}
		},
		integration: {
			path: 'integration',
			url: integrationUrl,
			edit: {
				path: 'edit/:integrationId',
				url: integrationEditUrl,
				generalInformation: {
					path: `${IntegrationWizardStep.GeneralInformation}`
				},
				scheduler: {
					path: `${IntegrationWizardStep.Scheduler}`
				},
				summary: {
					path: `${IntegrationWizardStep.Summary}`
				}
			}
		},
		dataRecordsProfile: {
			path: 'data-records',
			url: dataRecordsUrl,
			editProfile: {
				path: ':id/edit',
				url: dataRecordsEditUrl
			},
			createProfile: {
				path: 'create',
				url: dataRecordsCreateUrl
			},
			createFromApplication: {
				path: createFromBusinessApp,
				url: dataRecordUrlFromApplication
			}
		},
		endpointProfile: {
			path: 'endpoint-profile',
			url: endpointProfileUrl,
			create: {
				path: 'create',
				url: endpointProfileCreateUrl
			},
			createByApplication: {
				path: createFromBusinessApp,
				url: endpointProfileByApplication
			}
		},
		lossExposures: {
			path: 'other-loss-exposures',
			url: contractProfileUrl,
			createProfile: {
				path: 'create',
				url: createContractProfileUrl
			},
			editProfile: {
				path: ':id/edit',
				url: editContractProfile
			},
			createCustom: {
				path: 'contract-profile-edit/:lossExposureId',
				url: createContractProfileCustomUrl,
				overview: {
					path: `${LossExposureEditWizardStep.Overview}`
				},
				profileEditor: {
					path: `${LossExposureEditWizardStep.ControlSetup}`
				},
				summary: {
					path: `${LossExposureEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createCustomSourceUrl
				}
			},
			revenue: {
				path: 'revenue-edit/:lossExposureId',
				url: createRevenueUrl,
				overview: {
					path: `${LossExposureRevenueEditWizardStep.Overview}`
				},
				revenue: {
					path: `${LossExposureRevenueEditWizardStep.Revenue}`
				},
				applicationType: {
					path: `${LossExposureRevenueEditWizardStep.ApplicationType}`
				},
				applicationCharacteristics: {
					path: `${LossExposureRevenueEditWizardStep.ApplicationCharacteristics}`
				},
				summary: {
					path: `${LossExposureRevenueEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createRevenueSourceUrl
				}
			},
			data: {
				path: 'data-profile-edit/:lossExposureId',
				url: createDataProfileUrl,
				overview: {
					path: `${LossExposureDataEditWizardStep.Overview}`
				},
				dataSetup: {
					path: `${LossExposureDataEditWizardStep.DataSetup}`
				},
				summary: {
					path: `${LossExposureDataEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createDataSourceUrl
				}
			},
			tpData: {
				path: 'tp-data-profile-edit/:lossExposureId',
				url: createTPDataProfileUrl,
				overview: {
					path: `${LossExposureDataEditWizardStep.Overview}`
				},
				dataSetup: {
					path: `${LossExposureDataEditWizardStep.DataSetup}`
				},
				summary: {
					path: `${LossExposureDataEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createTPDataSourceUrl
				}
			},
			indirectOperational: {
				path: 'indirect-operational-edit/:lossExposureId',
				url: indirectOperationalUrl,
				overview: {
					path: `${SemiAutomatedFairEditWizardStep.Overview}`
				},
				tefSetup: {
					path: `${SemiAutomatedFairEditWizardStep.TEFSetup}`
				},
				primaryLoss: {
					path: `${SemiAutomatedFairEditWizardStep.PrimaryLoss}`
				},
				summary: {
					path: `${SemiAutomatedFairEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createIndirectOperationalSourceUrl
				}
			},
			reputation: {
				path: 'reputation-edit/:lossExposureId',
				url: reputationUrl,
				overview: {
					path: `${SemiAutomatedFairEditWizardStep.Overview}`
				},
				tefSetup: {
					path: `${SemiAutomatedFairEditWizardStep.TEFSetup}`
				},
				primaryLoss: {
					path: `${SemiAutomatedFairEditWizardStep.PrimaryLoss}`
				},
				summary: {
					path: `${SemiAutomatedFairEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createReputationSourceUrl
				}
			},
			ipTheft: {
				path: 'iptheft-edit/:lossExposureId',
				url: ipTheftUrl,
				overview: {
					path: `${SemiAutomatedFairEditWizardStep.Overview}`
				},
				tefSetup: {
					path: `${SemiAutomatedFairEditWizardStep.TEFSetup}`
				},
				primaryLoss: {
					path: `${SemiAutomatedFairEditWizardStep.PrimaryLoss}`
				},
				summary: {
					path: `${SemiAutomatedFairEditWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: createIpTheftSourceUrl
				}
			}
		},
		enterpriseControl: {
			path: 'enterprise-control',
			url: '/configure/enterprise-control',
			create: {
				path: `enterprise-control/${createFromBusinessApp}`,
				url: createEnterpriseControlProfile
			}
		}
	},
	usersManagement: {
		path: 'user-management',
		url: usersManagementUrl,
		create: {
			path: 'create',
			url: usersManagementCreateUrl
		},
		edit: {
			path: 'edit/:userId',
			url: usersManagementEditUrl
		}
	},
	user: {
		path: 'user',
		url: userUrl,
		notifications: {
			path: 'notifications',
			url: notificationsUrl
		},
		preferences: {
			path: 'preferences',
			url: preferencesUrl
		},
		activityLog: {
			path: 'activity-log',
			url: activityLogUrl
		}
	},
	help: {
		path: 'help',
		url: helpUrl,
		analysisGuide: {
			path: 'analysis-guide',
			url: helpAnalysisGuideUrl
		},
		modelInsights: {
			path: 'model-insights',
			url: helpModelInsightsUrl,
			tabs: {
				path: ':id',
				url: helpModelInsightsTabsUrl,
				attackModeling: {
					path: `${ModelInsightsTabs.AttackModeling}`
				},
				financialModeling: {
					path: `${ModelInsightsTabs.FinancialModeling}`
				},
				lossModelDetails: {
					path: `${ModelInsightsTabs.LossModelDetails}`
				},
				mitre: {
					path: `${ModelInsightsTabs.MITRE}`
				},
				philosophy: {
					path: `${ModelInsightsTabs.Philosophy}`
				}
			}
		}
	},
	thirdParty: {
		path: 'third-party',
		url: thirdPartyUrl,
		tabs: {
			overview: {
				path: 'overview',
				url: thirdPartyOverviewUrl,
				fairDetails: {
					path: 'modified-fair-details/:scenarioId',
					url: thirdPartyFairDetails
				},
				coreDetails: {
					path: 'core-details/:vectorId',
					url: thirdPartyCoreDetails
				}
			},
			securityScorecard: {
				path: 'security-scorecard',
				url: thirdPartySecurityScorecardUrl
			},
			partners: {
				path: 'partners',
				url: thirdPartyPartnersUrl,
				wizard: {
					path: 'edit/:thirdPartyId',
					url: thirdPartyPartnersEditUrl,
					generalInformation: {
						path: `${ThirdPartyWizardStep.GeneralInformation}`
					},
					identifyBusinessAssetsAtRisk: {
						path: `${ThirdPartyWizardStep.IdentifyBusinessAssetsAtRisk}`
					},
					identifyAttackSurface: {
						path: `${ThirdPartyWizardStep.IdentifyAttackSurface}`
					},
					summary: {
						path: `${ThirdPartyWizardStep.Summary}`
					},
					editWithControlProfile: {
						path: ':stepType',
						url: thirdPartyEditControlProfileUrl
					},
					editWithBusinessAsset: {
						path: ':stepType',
						url: thirdPartyBusinessAssetUrl
					}
				}
			}
		}
	},
	model: {
		path: 'model',
		url: modelUrl,
		tabs: {
			modelTuning: {
				path: 'model-tuning',
				url: modelModelTuningUrl,
				tabs: {
					path: ':id',
					url: modelModelTuningTabsUrl,
					annualAttackRateOfIncidence: {
						path: `${ModelTuningTabs.AnnualAttackRateOfIncidence}`
					},
					cveWeighting: {
						path: `${ModelTuningTabs.CveWeighting}`
					},
					fairLossMagnitudeValues: {
						path: `${ModelTuningTabs.FairLossMagnitudeValues}`
					},
					lossLimits: {
						path: `${ModelTuningTabs.LossLimits}`
					},
					lossVariables: {
						path: `${ModelTuningTabs.LossVariables}`
					},
					semiFairTEF: {
						path: `${ModelTuningTabs.SemiFairTEF}`
					},
					endpointScoringWeighting: {
						path: `${ModelTuningTabs.EndpointScoringWeighting}`
					},
					quantitativeScales: {
						path: `${ModelTuningTabs.QuantitativeScales}`
					},
					technicalPrevalence: {
						path: `${ModelTuningTabs.TechniquePrevalence}`
					}
				}
			},
			applicationSettings: {
				path: 'application-settings',
				url: modelApplicationSettingsUrl
			},
			qualitativeScale: {
				path: 'qualitative-scale',
				url: qualitativeScaleUrl
			}
		}
	}
};

export function controlAnalysisUrl(legalEntityId: string) {
	return `/${PRO_ROUTE.mainPath}/${legalEntityId}`;
}

export function thirdPartyCoreDetails(legalEntityId: string, vectorId: string) {
	return `${thirdPartyOverviewUrl(legalEntityId)}/core-details/${vectorId}`;
}

export function thirdPartyFairDetails(legalEntityId: string, scenarioId: string) {
	return `${thirdPartyOverviewUrl(legalEntityId)}/modified-fair-details/${scenarioId}`;
}

export function thirdPartyUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.thirdParty.path}`;
}

export function thirdPartyOverviewUrl(legalEntityId: string) {
	return `${thirdPartyUrl(legalEntityId)}/${PRO_ROUTE.thirdParty.tabs.overview.path}`;
}

export function thirdPartySecurityScorecardUrl(legalEntityId: string) {
	return `${thirdPartyUrl(legalEntityId)}/${PRO_ROUTE.thirdParty.tabs.securityScorecard.path}`;
}

export function thirdPartyPartnersUrl(legalEntityId: string) {
	return `${thirdPartyUrl(legalEntityId)}/${PRO_ROUTE.thirdParty.tabs.partners.path}`;
}

export function dataOutputUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.output.path}`;
}

export function outputReportstUrl(legalEntityId: string) {
	return `${dataOutputUrl(legalEntityId)}/${PRO_ROUTE.output.tabs.reports.path}`;
}

export function outputCsvExportUrl(legalEntityId: string) {
	return `${dataOutputUrl(legalEntityId)}/${PRO_ROUTE.output.tabs.csvExport.path}`;
}

export function outputApiExportUrl(legalEntityId: string) {
	return `${dataOutputUrl(legalEntityId)}/${PRO_ROUTE.output.tabs.apiExport.path}`;
}

export function reportsUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/reports`;
}

export function helpUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.help.path}`;
}

export function helpAnalysisGuideUrl(legalEntityId: string) {
	return `${helpUrl(legalEntityId)}/${PRO_ROUTE.help.analysisGuide.path}`;
}

export function helpModelInsightsUrl(legalEntityId: string) {
	return `${helpUrl(legalEntityId)}/${PRO_ROUTE.help.modelInsights.path}`;
}

export function helpModelInsightsTabsUrl(legalEntityId: string, menuTab: ModelInsightsTabs) {
	return `${helpModelInsightsUrl(legalEntityId)}/${menuTab}`;
}

export function activityLogUrl(legalEntityId: string) {
	return `${userUrl(legalEntityId)}/${PRO_ROUTE.user.activityLog.path}`;
}

export function applicationSettingsUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/settings/application-settings`;
}

export function preferencesUrl(legalEntityId: string) {
	return `${userUrl(legalEntityId)}/${PRO_ROUTE.user.preferences.path}`;
}

export function userUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.user.path}`;
}

export function usersManagementUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.usersManagement.path}`;
}

export function notificationsUrl(legalEntityId: string, name?: string, date?: string, type?: NotificationType) {
	if (name && date && type) {
		return `${userUrl(legalEntityId)}/${PRO_ROUTE.user.notifications.path}?name=${name}&date=${date}&type=${type}`;
	} else {
		return `${userUrl(legalEntityId)}/${PRO_ROUTE.user.notifications.path}`;
	}
}

export function thirdPartyPartnersEditUrl(legalEntityId: string, thirdPartyId: string, stepType: ThirdPartyWizardStep) {
	return `${thirdPartyPartnersUrl(legalEntityId)}/edit/${thirdPartyId}/${stepType}`;
}

export function thirdPartyBusinessAssetUrl(
	legalEntityId: string,
	thirdPartyId: string,
	lossExposureId: string,
	lossExposureType: LossExposureType
) {
	return `${thirdPartyPartnersEditUrl(
		legalEntityId,
		thirdPartyId,
		ThirdPartyWizardStep.IdentifyBusinessAssetsAtRisk
	)}?lossExposureId=${lossExposureId}&lossExposureType=${lossExposureType}`;
}

export function thirdPartyEditControlProfileUrl(legalEntityId: string, thirdPartyId: string, controlProfileId: string) {
	return `${thirdPartyPartnersUrl(legalEntityId)}/edit/${thirdPartyId}/${
		ThirdPartyWizardStep.IdentifyAttackSurface
	}?controlProfileId=${controlProfileId}&profileType=${ApplicationEnterpriseProfileType.Inherent}`;
}

export function integrationUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.integration.path}`;
}

export function integrationEditUrl(legalEntityId: string, integrationId: string, stepType: IntegrationWizardStep) {
	return `${integrationUrl(legalEntityId)}/edit/${integrationId}/${stepType}`;
}

export function endpointProfileCreateUrl(legalEntityId: string) {
	return `${endpointProfileUrl(legalEntityId)}/${PRO_ROUTE.configure.endpointProfile.create.path}`;
}

export function endpointProfileUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.endpointProfile.path}`;
}

export function contractProfileUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.lossExposures.path}`;
}

export function createContractProfileUrl(legalEntityId: string) {
	return `${contractProfileUrl(legalEntityId)}/create`;
}

export function createContractProfileCustomUrl(legalEntityId: string, lossExposureId: string, step: LossExposureEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/contract-profile-edit/${lossExposureId}/${step}`;
}

export function createRevenueUrl(legalEntityId: string, lossExposureId: string, step: LossExposureRevenueEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/revenue-edit/${lossExposureId}/${step}`;
}

export function createDataProfileUrl(legalEntityId: string, lossExposureId: string, step: LossExposureDataEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/data-profile-edit/${lossExposureId}/${step}`;
}

export function createTPDataProfileUrl(legalEntityId: string, lossExposureId: string, step: LossExposureDataEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/tp-data-profile-edit/${lossExposureId}/${step}`;
}

export function indirectOperationalUrl(legalEntityId: string, lossExposureId: string, step: SemiAutomatedFairEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/indirect-operational-edit/${lossExposureId}/${step}`;
}

export function reputationUrl(legalEntityId: string, lossExposureId: string, step: SemiAutomatedFairEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/reputation-edit/${lossExposureId}/${step}`;
}

export function ipTheftUrl(legalEntityId: string, lossExposureId: string, step: SemiAutomatedFairEditWizardStep) {
	return `${contractProfileUrl(legalEntityId)}/iptheft-edit/${lossExposureId}/${step}`;
}

export function createCustomSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: LossExposureEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${createContractProfileCustomUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createRevenueSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: LossExposureRevenueEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${createRevenueUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createDataSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: LossExposureDataEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${createDataProfileUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createTPDataSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: LossExposureDataEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${createTPDataProfileUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createIndirectOperationalSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: SemiAutomatedFairEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${indirectOperationalUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createReputationSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: SemiAutomatedFairEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${reputationUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function createIpTheftSourceUrl(
	legalEntityId: string,
	lossExposureId: string,
	lossType: SemiAutomatedFairEditWizardStep,
	sourceType: LoseExposureSourceType,
	sourceId: string
) {
	return `${ipTheftUrl(legalEntityId, lossExposureId, lossType)}?sourceType=${sourceType}&sourceId=${sourceId}`;
}

export function dataRecordsCreateUrl(legalEntityId: string) {
	return `${dataRecordsUrl(legalEntityId)}/create`;
}

export function dataRecordsEditUrl(legalEntityId: string, dataRecordId: string) {
	return `${dataRecordsUrl(legalEntityId)}/${dataRecordId}/edit'`;
}

export function dataRecordsUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.dataRecordsProfile.path}`;
}

export function controlProfileUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.controlProfile.path}`;
}

export function controlProfileCreateUrl(legalEntityId: string, type: ControlProfileType) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.controlProfile.path}?controlProfileType=${type}`;
}

export function legalEntityUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/legal-entity`;
}

export function businessApplicationUrl(legalEntityId: string) {
	return `${configure(legalEntityId)}/${PRO_ROUTE.configure.businessApplication.path}`;
}

export function configure(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/configure`;
}

export function oldDashboard(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.oldDashboard.path}`;
}

export function dashboard(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.dashboard.path}`;
}

export function financialUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.financial.path}`;
}

export function financialApplicationsUrl(legalEntityId: string) {
	return `${financialUrl(legalEntityId)}/${PRO_ROUTE.financial.tabs.applications.path}`;
}

export function financialAnalysisUrl(legalEntityId: string) {
	return `${financialUrl(legalEntityId)}/${PRO_ROUTE.financial.tabs.financialAnalysis.path}`;
}

export function financialImpactVectorUrl(legalEntityId: string) {
	return `${financialUrl(legalEntityId)}/${PRO_ROUTE.financial.tabs.impactVector.path}`;
}

export function financialImpactVectorUrlTab(legalEntityId: string, tabId?: ImpactVectorTabs) {
	return `${financialUrl(legalEntityId)}/${PRO_ROUTE.financial.tabs.impactVector.path}/${tabId}`;
}

export function financialAnalysisMatrixUrl(legalEntityId: string) {
	return `${financialUrl(legalEntityId)}/${PRO_ROUTE.financial.tabs.analysisMatrix.path}`;
}

export function recommendationsUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.recommendations.path}`;
}

export function recommendationsTabLongTermUrl(legalEntityId: string) {
	return `${recommendationsUrl(legalEntityId)}/${PRO_ROUTE.recommendations.tabs.longTerm.path}`;
}

export function recommendationsTabShortTermUrl(legalEntityId: string) {
	return `${recommendationsUrl(legalEntityId)}/${PRO_ROUTE.recommendations.tabs.shortTerm.path}`;
}

export function modelUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.model.path}`;
}

export function modelModelTuningUrl(legalEntityId: string) {
	return `${modelUrl(legalEntityId)}/${PRO_ROUTE.model.tabs.modelTuning.path}`;
}

export function modelModelTuningTabsUrl(legalEntityId: string, tab: ModelTuningTabs) {
	return `${modelUrl(legalEntityId)}/${PRO_ROUTE.model.tabs.modelTuning.path}/${tab}`;
}

export function modelApplicationSettingsUrl(legalEntityId: string) {
	return `${modelUrl(legalEntityId)}/${PRO_ROUTE.model.tabs.applicationSettings.path}`;
}

export function qualitativeScaleUrl(legalEntityId: string) {
	return `${modelUrl(legalEntityId)}/${PRO_ROUTE.model.tabs.qualitativeScale.path}`;
}

export function technicalAnalysisUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/${PRO_ROUTE.technicalAnalysis.path}`;
}

export function technicalAssetsUrl(analysisId: string, legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/technical-analysis/${analysisId}/technical-assets`;
}

export function technicalRisksUrl(analysisId: string, legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/technical-analysis/${analysisId}/technical-risks`;
}

export function technicalEndpointProfileUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/technical-analysis/endpoint-profile`;
}

export function attackNavigatorUrl(legalEntityId: string, scenarioId?: string) {
	if (scenarioId) {
		return `${controlAnalysisUrl(legalEntityId)}/attack-navigator?scenarioId=${scenarioId}`;
	} else {
		return `${controlAnalysisUrl(legalEntityId)}/attack-navigator`;
	}
}

export function mitreDashboardUrl(legalEntityId: string, scenarioId?: string) {
	if (scenarioId) {
		return `${controlAnalysisUrl(legalEntityId)}/mitre-dashboard?scenarioId=${scenarioId}`;
	} else {
		return `${controlAnalysisUrl(legalEntityId)}/mitre-dashboard`;
	}
}

export function scenariosEnterpriseDetailsView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/enterprise/details/${scenarioId}/${scenarioType}`;
}

export function scenariosRateOfIncidenceView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/rate-of-incident/details/${scenarioId}/${scenarioType}`;
}

export function scenariosFairView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/fair/details/${scenarioId}/${scenarioType}`;
}

export function scenariosFairConfigurationView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/fair/configuration/${scenarioId}/${scenarioType}`;
}

export function scenariosModifiedFairConfigurationView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/modified-fair/configuration/${scenarioId}/${scenarioType}`;
}

export function scenariosModifiedFairView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/modified-fair/details/${scenarioId}/${scenarioType}`;
}

export function businessApplicationEditUrl(
	legalEntityId: string,
	businessApplicationId: string,
	stepType: BusinessAppWizzardStep,
	substep?: BusinessAppWizzardSubStep
) {
	const substepParam = substep ? `?substep=${substep}` : '';
	return `${businessApplicationUrl(legalEntityId)}/edit/${businessApplicationId}/${stepType}${substepParam}`;
}

export function businessApplicationEditWithControlProfileUrl(
	legalEntityId: string,
	businessApplicationId: string,
	stepType: BusinessAppWizzardStep,
	controlProfileId: string,
	profileType: number
) {
	return `${businessApplicationEditUrl(
		legalEntityId,
		businessApplicationId,
		stepType
	)}?controlProfileId=${controlProfileId}&profileType=${profileType}`;
}

export function endpointProfileByApplication(legalEntityId: string, businessApplicationId: string) {
	return `${endpointProfileUrl(legalEntityId)}/create/${businessApplicationId}`;
}

export function controlProfileEditUrl(
	legalEntityId: string,
	controlProfileId: string,
	controlProfileType: ControlProfileType,
	stepType: ControlProfileWizardStep
) {
	return `${controlProfileUrl(legalEntityId)}/edit/${controlProfileId}/${controlProfileType}/${stepType}`;
}

export function createEnterpriseControlProfile(legalEntityId: string, businessApplicationId: string) {
	return `${configure(legalEntityId)}/enterprise-control/create/${businessApplicationId}`;
}

export function createControlProfileFromThirdPartyUrl(legalEntityId: string, thirdPartyId: string) {
	return `${controlProfileUrl(legalEntityId)}/create/${thirdPartyId}`;
}

export function createControlProfileFromBusinessAppUrl(
	legalEntityId: string,
	businessApplicationId: string,
	controlProfileType: ControlProfileType
) {
	return `${controlProfileUrl(legalEntityId)}/create/${businessApplicationId}/${controlProfileType}`;
}

export function createControlProfileFromBusinessAppAndEnterpriseFrameworkUrl(
	legalEntityId: string,
	businessApplicationId: string,
	frameworkId: string
) {
	return `${controlProfileUrl(legalEntityId)}/create/${businessApplicationId}/${ControlProfileType.Enterprise}/${frameworkId}`;
}

export function dataRecordUrlFromApplication(legalEntityId: string, businessApplicationId: string) {
	return `${dataRecordsUrl(legalEntityId)}/create/${businessApplicationId}`;
}

export function editContractProfile(legalEntityId: string, businessApplicationId: string) {
	return `${contractProfileUrl(legalEntityId)}/${businessApplicationId}/edit`;
}

export function usersManagementCreateUrl(legalEntityId: string) {
	return `${usersManagementUrl(legalEntityId)}/${PRO_ROUTE.usersManagement.create.path}`;
}

export function usersManagementEditUrl(legalEntityId: string, userId: string) {
	return `${usersManagementUrl(legalEntityId)}/edit/${userId}`;
}

export function dataExportUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/data-export`;
}

export function scenariosUrl(legalEntityId: string) {
	return `${controlAnalysisUrl(legalEntityId)}/scenarios`;
}

export function scenariosCompare(legalEntityId: string) {
	return `${scenariosUrl(legalEntityId)}/compare`;
}

export function scenariosCreateUrl(legalEntityId: string, type: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}?scenarioType=${type}`;
}

export function scenariosEnterpriseEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioEnterpriseEditWizardStep) {
	return `${scenariosUrl(legalEntityId)}/enterprise/edit/${scenarioId}/${stepType}`;
}

export function scenariosRateOfIncidenceEditUrl(
	legalEntityId: string,
	scenarioId: string,
	stepType: ScenariosRateOfIncidentEditWizardStep
) {
	return `${scenariosUrl(legalEntityId)}/rate-of-incident/edit/${scenarioId}/${stepType}`;
}

export function scenariosFairEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioFairWizzardStep) {
	return `${scenariosUrl(legalEntityId)}/fair/edit/${scenarioId}/${stepType}`;
}

export function scenariosModifiedFairEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioFairWizzardStep) {
	return `${scenariosUrl(legalEntityId)}/modified-fair/edit/${scenarioId}/${stepType}`;
}

export function scenariosAggregatedFairEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioAggregatedFairWizardStep) {
	return `${scenariosUrl(legalEntityId)}/aggregated-fair/edit/${scenarioId}/${stepType}`;
}

export function scenariosAggregatedFairView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/aggregated-fair/details/${scenarioId}/${scenarioType}`;
}

export function scenariosMachineLearningEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioMachineLearningWizardStep) {
	return `${scenariosUrl(legalEntityId)}/machine-learning/edit/${scenarioId}/${stepType}`;
}

export function scenariosMachineLearningView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/machine-learning/details/${scenarioId}/${scenarioType}`;
}

export function scenariosMultipleLevelsEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioMultipleLevelsWizardStep) {
	return `${scenariosUrl(legalEntityId)}/multiple-levels/edit/${scenarioId}/${stepType}`;
}

export function scenariosMultipleLevelsView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/multiple-levels/details/${scenarioId}/${scenarioType}`;
}

export function scenariosMitreEditUrl(legalEntityId: string, scenarioId: string, stepType: ScenarioMitreWizardStep) {
	return `${scenariosUrl(legalEntityId)}/mitre/edit/${scenarioId}/${stepType}`;
}

export function scenariosMitreDetailsView(legalEntityId: string, scenarioId: string, scenarioType: ScenarioType) {
	return `${scenariosUrl(legalEntityId)}/mitre/details/${scenarioId}/${scenarioType}`;
}
