import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterSearchBoxConfig, FilterSelectGroup, FilterSelectOption } from '../../../form-elements/filter/filter.model';

@Component({
	selector: 'rq-table-filter-select',
	templateUrl: './table-filter-select.component.html',
	styleUrls: ['./table-filter-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TableFilterSelectComponent),
			multi: true
		}
	]
})
export class TableFilterSelectComponent implements ControlValueAccessor {
	@Input()
	public groups: FilterSelectGroup<unknown>[] = [];

	@Input()
	public isDisabled!: boolean;

	@Input()
	public maxSelected?: number;

	@Output()
	public readonly clearAction = new EventEmitter();

	public searchBoxConfig: FilterSearchBoxConfig = {
		searchValue: '',
		trigger: 'change',
		placeholder: 'global_search_label'
	};

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	private _value!: unknown;

	public get selected() {
		let selectedOptions: FilterSelectOption<unknown>[] = [];

		if (this.groups) {
			this.groups.forEach(x => (selectedOptions = selectedOptions.concat(x.options.filter(y => y.isSelected))));
		}

		return selectedOptions;
	}

	public get totalOptions() {
		return this.groups?.reduce((count, group) => count + group.options.length, 0) ?? 0;
	}

	public get value() {
		return this._value;
	}

	public set value(value: unknown) {
		this._value = value;
	}

	@HostListener('click')
	public setTouchedState() {
		this.value = this.selected.map(x => x.value);

		this.onTouchedCallback(this.value);
	}

	public clear() {
		if (this.groups && !this.isDisabled) {
			this.groups.forEach(group => {
				group.options.forEach(option => (option.isSelected = false));
			});

			this.clearAction.emit();
		}
	}

	public isGroupVisible(group: FilterSelectGroup<unknown>) {
		return group.options.filter(x => !x.isSelected && this.isPartOfSearch(x)).length > 0;
	}

	public isPartOfSearch(option: FilterSelectOption<unknown>) {
		let isPartOfSearch = true;

		if (this.searchBoxConfig.searchValue) {
			isPartOfSearch = option.displayName.toLowerCase().includes(this.searchBoxConfig.searchValue.toLowerCase());
		}

		return isPartOfSearch;
	}

	public triggerSelectChange() {
		Promise.resolve().then(() => {
			const value = this.selected.map(x => x.value);
			this.value = value.length ? value : null;

			this.onChangeCallback(this.value);
		});
	}

	public registerOnChange(fn: (value: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value: unknown) => void) {
		this.onTouchedCallback = fn;
	}

	public writeValue(values: string | string[]): void {
		if (!this.groups || !values) {
			return;
		}

		const valueSet = new Set(Array.isArray(values) ? values : [values]);

		this.groups.forEach(({ options }) => {
			options?.forEach(option => {
				option.isSelected = valueSet.has(option.value as string);
			});
		});
	}
}
