import { Component, OnInit } from '@angular/core';
import { StandardBase } from 'src/app/routes/control-analysis/_common/standard.base';
import { LastAnalysisStore } from 'src/app/routes/control-analysis/_common/store/last-analysis.store';
import { CveRecommendationDataService } from 'src/app/server/services/analysis/cve-recommendation-data.service';
import { TranslationService } from '../../services/translation.service';
import { NewBarChartDisplayModel } from 'src/app/standalone/components/new-charts/new-bar-chart/new-bar-chart.displaymodel';
import { NewBarChartConfig } from 'src/app/standalone/components/new-charts/new-bar-chart/new-bar-chart.config';
import { WidgetsService } from '../widgets.service';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';

@Component({
	selector: 'rq-short-term-recommendations-widget',
	templateUrl: './short-term-recommendations.widget.html',
	styleUrls: ['./short-term-recommendations.widget.scss']
})
export class ShortTermRecommendationsWidget extends StandardBase implements OnInit {
	public data!: Array<NewBarChartDisplayModel> | null;

	public config = new NewBarChartConfig();

	constructor(
		private cveRecommendationDataService: CveRecommendationDataService,
		private lastAnalysisStore: LastAnalysisStore,
		private translationService: TranslationService,
		private widgetsService: WidgetsService
	) {
		super();
	}

	private get analysisId() {
		return this.lastAnalysisStore.lastAnalysis?.id ?? '';
	}

	public async ngOnInit() {
		this.subscribeToRefresh();

		await this.initData();
	}

	private async initData() {
		const recommendations = await this.cveRecommendationDataService.getTopByCveScoreEffect(this.analysisId);

		this.data = recommendations.map(item => {
			return {
				label: this.translationService.get(item.cveGroup),
				value: item.contributoryEffect
			};
		});
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetsService.refresh$.subscribe((type: UiWidgetType) => {
				if (type === UiWidgetType.ShortTermRecommendations) {
					this.data = null;
					this.initData();
				}
			})
		);
	}
}
