import { Component } from '@angular/core';
import { IBubbleChart, IBubbleChartGroup } from '../../../standalone/components/charts/bubble-chart/models/bubble-chart.interface';
import { BubbleChartConfig } from '../../../standalone/components/charts/bubble-chart/models/bubble-chart.config';
import { FormatterType } from '../../enums/formatter-type.enum';
import { BaseComponent } from '../../components/base.component';
import { WidgetsService } from '../widgets.service';
import { CountLegendUiModel } from '../../components/count-legend/count-legend.ui-model';
import { COLORS } from '../../../standalone/utils/colors.utils';
import { DropdownOption } from '../../../standalone/components/form-elements/dropdown/dropdown.model';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';
import { AnalysisAggregatedThreatDataService } from '../../../server/services/analysis/analysis-aggregated-threat-data.service';
import { AnalysisAggregatedThreatFilterModel } from '../../../server/models/analysis-aggregated-threat-filter-model.model';
import { AttackDataService } from '../../../server/services/configuration/attack-data.service';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { AnalysisAggregatedThreatsModel } from '../../../server/models/analysis-aggregated-threats-model.model';

const CUSTOM_OPTION = 'Custom';

@Component({
	selector: 'rq-key-risks-by-likelihood',
	templateUrl: './key-risks-by-likelihood.component.html',
	styleUrls: ['./key-risks-by-likelihood.component.scss']
})
export class KeyRisksByLikelihoodWidget extends BaseComponent {
	public chartData!: IBubbleChart | null;

	public chartConfig!: BubbleChartConfig;

	public legendData: Array<CountLegendUiModel> = [];

	public dropdownOptions: Array<DropdownOption<string | undefined>> = [];

	public selectedOption?: string;

	private attacks!: Array<AttackViewModel>;

	private allChartGroups!: Array<IBubbleChartGroup>;

	private frequencyAxis!: {
		xAxiesLabels: Array<string>;
		xMinValue: number;
		xMaxValue: number;
	};

	constructor(
		private widgetsService: WidgetsService,
		private numberFormatterService: NumberFormatterService,
		private analysisAggregatedThreatDataService: AnalysisAggregatedThreatDataService,
		private attackDataService: AttackDataService
	) {
		super();
	}

	public async ngOnInit() {
		this.subscribeToRefresh();

		await this.initData();
	}

	public dropdownOptionChange(value: string) {
		this.chartData = null;
		this.selectedOption = value;

		const filteredGroups = this.allChartGroups.filter(x => x.id === this.selectedOption);

		this.chartData = {
			groups: filteredGroups.length ? filteredGroups : this.allChartGroups,
			...this.frequencyAxis
		};
	}

	private async loadData(attackId?: string) {
		if (this.urlLegalEntityId) {
			const filter = new AnalysisAggregatedThreatFilterModel();

			if (attackId === CUSTOM_OPTION) {
				filter.showScenarios = true;
				filter.attackId = undefined;
			} else {
				filter.showScenarios = undefined;
				filter.attackId = attackId;
			}

			const data = await this.analysisAggregatedThreatDataService.getAnalysisAggregatedThreats(this.urlLegalEntityId, filter, {
				skip: 0,
				take: 10
			});

			const groupedItems = this.groupItems(data.values);
			this.setLegendData(groupedItems);
			this.setChartData(groupedItems);
		}
	}

	private async initData() {
		if (this.urlLegalEntityId) {
			this.attacks = await this.attackDataService.getByLegalEntity(this.urlLegalEntityId);
			await this.loadData();
			this.setupDropdownOptions(this.attacks);
			this.selectedOption = undefined;
		}

		this.isComponentLoaded = true;
	}

	private groupItems(data: Array<AnalysisAggregatedThreatsModel>) {
		const groupItems: Record<string, Array<IChartItem>> = {};

		this.attacks.forEach(attack => {
			const items = data.filter(d => d.attackId === attack.id);
			if (items.length) {
				groupItems[attack.id] = items.map((item, index) => {
					return this.createMapItem(item, index);
				});
			}
		});

		const customItems = data.filter(d => !d.attackId);

		if (customItems.length) {
			groupItems[CUSTOM_OPTION] = customItems.map((item, index) => {
				return this.createMapItem(item, index);
			});
		}

		return groupItems;
	}

	private setLegendData(groupItems: Record<string, Array<IChartItem>>) {
		this.attacks.forEach(x => {
			if (groupItems[x.id]) {
				this.legendData.push({
					label: x.name,
					color: this.getColor(x.name),
					value: groupItems[x.id].length
				});
			}
		});

		if (groupItems[CUSTOM_OPTION]) {
			this.legendData.push({
				label: CUSTOM_OPTION,
				color: this.getColor(CUSTOM_OPTION),
				value: groupItems[CUSTOM_OPTION].length
			});
		}
	}

	private setChartData(groupItems: Record<string, Array<IChartItem>>) {
		const config = new BubbleChartConfig();
		config.yAxisFormatter = FormatterType.Percent;
		config.yAxisLabel = 'Likelihood';
		config.invertXAxies = false;
		this.chartConfig = config;

		const combined = Object.values(groupItems).flat();
		const maxImpact = Math.max(...combined.map(item => item.impact));
		const minImpact = Math.min(...combined.map(item => item.impact));

		const maxFrequency = Math.max(...combined.map(item => item.frequency));
		const maxFrequencyItem = combined.find(x => x.frequency === maxFrequency);

		const groups: Array<IBubbleChartGroup> = [];

		this.attacks.forEach(attack => {
			if (groupItems[attack.id]) {
				groups.push(<IBubbleChartGroup>{
					id: attack.id,
					name: attack.name,
					color: this.getColor(attack.name),
					items: groupItems[attack.id].map(x => this.createChartItem(x, attack.name, minImpact, maxImpact))
				});
			}
		});

		if (groupItems[CUSTOM_OPTION]) {
			groups.push(<IBubbleChartGroup>{
				id: CUSTOM_OPTION,
				name: CUSTOM_OPTION,
				color: this.getColor(CUSTOM_OPTION),
				items: groupItems[CUSTOM_OPTION].map(x => this.createChartItem(x, CUSTOM_OPTION, minImpact, maxImpact))
			});
		}

		this.allChartGroups = groups;

		const filteredGroups = this.allChartGroups.filter(x => x.id === this.selectedOption);

		this.frequencyAxis = {
			xAxiesLabels: ['Imminent', 'Frequency', maxFrequencyItem?.frequencyLabel ?? ''],
			xMinValue: 0,
			xMaxValue: maxFrequencyItem?.frequency ?? 10
		};

		this.chartData = {
			groups: filteredGroups.length ? filteredGroups : this.allChartGroups,
			...this.frequencyAxis
		};
	}

	private createChartItem(data: IChartItem, label: string, minImpact: number, maxImpact: number) {
		return {
			id: data.id,
			xAxiesValue: data.frequency,
			yAxiesValue: data.likelihood,
			bubbleValue: ((data.impact - minImpact) / (maxImpact - minImpact)) * 99 + 1,
			tooltipTemplate: this.createChartTooltipTemplate(data.label, label, data.impact, data.likelihood, data.frequencyLabel)
		};
	}

	private createMapItem(item: AnalysisAggregatedThreatsModel, index: number) {
		return <IChartItem>{
			id: `${index}-${item.displayName}`,
			label: item.displayName,
			frequency: item.lossEventFrequency ?? 0,
			frequencyLabel: item.lossEventFrequencyLabel,
			likelihood: item.probabilityOfSuccess ?? 0,
			impact: item.singleLossAmount ?? 0
		};
	}

	private setupDropdownOptions(attacks: Array<AttackViewModel>) {
		const options: Array<DropdownOption<string | undefined>> = [new DropdownOption('All Types', undefined)];
		attacks.forEach(x => {
			if (this.allChartGroups.find(g => g.id === x.id)) {
				options.push(new DropdownOption(x.name, x.id));
			}
		});

		if (this.allChartGroups.find(g => g.id === CUSTOM_OPTION)) {
			options.push(new DropdownOption(CUSTOM_OPTION, CUSTOM_OPTION));
		}

		this.dropdownOptions = options;
	}

	private getColor(attackType: string) {
		const colors = new Map<string, string>();
		colors.set('Data Breach', COLORS.infoBackground);
		colors.set('DDoS', COLORS.royalBackground);
		colors.set('Ransomware', COLORS.clearBackground);
		colors.set(CUSTOM_OPTION, COLORS.neutralContrastBackground);

		return colors.get(attackType) ?? COLORS.neutralContrastBackground;
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetsService.refresh$.subscribe(async (type: UiWidgetType) => {
				if (type === UiWidgetType.KeyRisksByLikelihood) {
					this.isComponentLoaded = false;
					await this.initData();
				}
			})
		);
	}

	private createChartTooltipTemplate(name: string, attackType: string, impact: number, likelihood: number, frequencyLabel: string) {
		return `
		<div style="display: flex; flex-direction: column; gap:var(--spacer-lg)">
		<div>
		  <p style="color: var(--text-secondary); font-weight: var(--font-weight-medium)">${name}</p>
		  <p style="color: var(--text-neutral-contrast)">${attackType}</p>
		</div>
  
		<div style="display: flex; gap:var(--spacer-lg)">
		  <div class="p-r-md" style="border-right: 1px solid var(--border-neutral)">
			<p style="color: var(--text-neutral-contrast)">Impact</p>
			<p style="color: var(--text-secondary); font-weight: var(--font-weight-medium)">${this.numberFormatterService.currency(impact)}</p>
		  </div>
  
		  <div class="p-r-md" style="border-right: 1px solid var(--border-neutral)">
			<p style="color: var(--text-neutral-contrast)">Likelihood</p>
			<p style="color: var(--text-secondary); font-weight: var(--font-weight-medium)">${this.numberFormatterService.percent(likelihood)}</p>
		  </div>
  
		  <div class="p-r-md">
			<p style="color: var(--text-neutral-contrast)">Frequency</p>
			<p style="color: var(--text-secondary); font-weight: var(--font-weight-medium)">${frequencyLabel}</p>
		  </div>
		</div>
	  </div>
		`;
	}
}

interface IChartItem {
	id: string;
	label: string;
	frequency: number;
	frequencyLabel: string;
	likelihood: number;
	impact: number;
}
