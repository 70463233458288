<section class="search-wrapper"
         *ngIf="totalOptions > 5">
  <rq-filter-search [(ngModel)]="searchBoxConfig.searchValue"
                    [isDisabled]="isDisabled"
                    [trigger]="searchBoxConfig.trigger"
                    placeholder="{{searchBoxConfig.placeholder | translate}}">
  </rq-filter-search>
</section>

<section class="filter-dropdown-options">
  <ng-container *ngIf="selected.length > 0">
    <ng-container *ngFor="let option of selected">
      <div class="filter-item">
        <label class="truncate-1">
          <input type="checkbox"
                 [disabled]="isDisabled"
                 (ngModelChange)="triggerSelectChange()"
                 [(ngModel)]="option.isSelected" />
          <span [title]="option.displayName">{{option.displayName}}</span>
        </label>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngFor="let group of groups">
    <div *ngIf="isGroupVisible(group)">
      <strong class="truncate-1 m-y-sm"
              [title]="group.title"
              *ngIf="group.title">{{group.title | translate}}</strong>
      <ng-container *ngFor="let option of group.options">
        <ng-container *ngIf="isPartOfSearch(option)">
          <div class="filter-item"
               *ngIf="!option.isSelected">
            <label class="truncate-1">
              <input type="checkbox"
                     [disabled]="(maxSelected !== undefined && selected.length >= maxSelected) || isDisabled"
                     (ngModelChange)="triggerSelectChange()"
                     [(ngModel)]="option.isSelected" />
              <span [title]="option.displayName">{{option.displayName}}</span>
            </label>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</section>

<section class="clear-wrapper">
  <button type="button"
          e2e-test="clear-button"
          class="btn-secondary-sm action-button"
          [class.disabled]="isDisabled"
          (click)="clear()">Clear</button>
</section>
