import { FormatterType } from 'src/app/shared/enums/formatter-type.enum';

export class NewBarChartConfig {
	public isStacked?: boolean;

	public isTooltipVisible?: boolean;

	public xAxisLabel?: string;

	public yAxisLabel?: string;

	public xAxisFormatter?: FormatterType;

	public yAxisFormatter?: FormatterType;

	public isHorizontal?: boolean;

	public colors?: Array<string>;

	constructor() {
		this.xAxisFormatter = FormatterType.Currency;
		this.yAxisFormatter = FormatterType.Currency;
	}
}
