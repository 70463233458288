<form #myForm="ngForm"
      class="search-wrapper numeric"
      [class.disabled]="isDisabled">
  <input #inputEl
         *ngIf="!isFormatted"
         name="inputEl"
         type="number"
         placeholder="Search"
         class="form-control"
         [disabled]="isDisabled"
         [(ngModel)]="value"
         (ngModelChange)="triggerFilterChange('change')"
         (blur)="triggerFilterChange('blur')"
         (keyup)="triggerChangeOnEnter($event)" />

  <input #inputEl
         *ngIf="isFormatted"
         name="inputEl"
         type="text"
         placeholder="Search"
         class="form-control"
         [disabled]="isDisabled"
         [(ngModel)]="value"
         (ngModelChange)="triggerFilterChange('change')"
         (blur)="triggerFilterChange('blur')"
         (keyup)="triggerChangeOnEnter($event)"
         [dropSpecialCharacters]="false"
         [allowNegativeNumbers]="allowNegativeNumbers"
         [suffix]="suffix"
         [prefix]="prefix"
         [mask]="inputMask"
         thousandSeparator="," />

  <rq-icon *ngIf="!hasValue"
           color="color-accent"
           class="filter-icon"
           href="icon-search"></rq-icon>

  <rq-icon *ngIf="hasValue"
           color="color-accent"
           (mousedown)="!isDisabled && clear($event)"
           class="filter-icon pointer"
           href="icon-close"></rq-icon>
</form>
