import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { UserPreferencesViewModel } from '../../models/user-preferences-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { UserPreferencesUpdateViewModel } from '../../models/user-preferences-update-view-model.model';
import { UserUIWidgetViewModel } from '../../models/user-uiwidget-view-model.model';

@Injectable()
export class UserPreferencesDataService {
	constructor(private httpService: RQHttpService) {}

	public get(options = new HttpOptionsViewModel()): Promise<UserPreferencesViewModel> {
		return this.httpService
			.request<UserPreferencesViewModel>('get', environment.api + '/api/Identity/UserPreferences/Get', options)
			.toPromise();
	}

	public update(updateModel: UserPreferencesUpdateViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService.request<IActionResult>('put', environment.api + '/api/Identity/UserPreferences/Update', options).toPromise();
	}

	public create(id: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = id;

		return this.httpService.request<IActionResult>('post', environment.api + '/api/Identity/UserPreferences/Create', options).toPromise();
	}

	public getUIWidgets(options = new HttpOptionsViewModel()): Promise<UserUIWidgetViewModel[]> {
		return this.httpService
			.request<UserUIWidgetViewModel[]>('get', environment.api + '/api/Identity/UserPreferences/GetUIWidgets', options)
			.toPromise();
	}

	public updateUIWidgets(widgets: UserUIWidgetViewModel[], options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = widgets;

		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Identity/UserPreferences/UpdateUIWidgets', options)
			.toPromise();
	}

	public revertWidgetsToDefaults(options = new HttpOptionsViewModel()): Promise<IActionResult> {
		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Identity/UserPreferences/RevertWidgetsToDefaults', options)
			.toPromise();
	}
}
