<section class="search-wrapper"
         *ngIf="options && options.length > 5">
  <rq-filter-search [(ngModel)]="searchBoxConfig.searchValue"
                    [isDisabled]="isDisabled"
                    [trigger]="searchBoxConfig.trigger"
                    placeholder="{{searchBoxConfig.placeholder | translate}}">
  </rq-filter-search>
</section>


<section class="filter-dropdown-options">
  <ng-content *ngIf="!options"></ng-content>
  <ng-container *ngIf="options">
    <ng-container *ngFor="let option of options">
      <ng-container *ngIf="isPartOfSearch(option)">
        <p class="p-xs dropdown-option"
           [class.dropdown-option-active]="option.isSelected"
           (click)="triggerChange(option)"
           [rqEllipsis]='option.displayName'
           [charactersCountLimit]='25'>
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</section>

<section class="clear-wrapper">
  <button type="button"
          e2e-test="clear-button"
          class="btn-secondary-sm action-button"
          [class.disabled]="isDisabled"
          (click)="clear()">Clear</button>
</section>
