<rq-card [title]="'Long Term Recommendations'"
         [isLoaded]="data"
         [isFlexHeight]="true"
         [isNoDataVisible]="!data">
  <ng-template rqCardContent>
    <rq-long-term-recommendations-table-widget (viewAll)="viewAll()"
                                               *ngIf="data"
                                               [data]="data"
                                               [count]="tableCount"></rq-long-term-recommendations-table-widget>
  </ng-template>
</rq-card>
