import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisRiskOverviewModel } from '../../models/analysis-risk-overview-model.model';

@Injectable()
export class AnalysisRiskOverviewDataService {
	constructor(private httpService: RQHttpService) {}

	public get(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<AnalysisRiskOverviewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<AnalysisRiskOverviewModel>('get', environment.api + '/api/Analysis/AnalysisRiskOverview/Get', options)
			.toPromise();
	}
}
