import {
	ChangeDetectorRef,
	ContentChild,
	ContentChildren,
	Directive,
	EventEmitter,
	Input,
	Output,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { PagerConfig, PaginationState } from '../pager/pager.ui-model';
import { TableDataModel } from './models/table-data.model';
import { TableColumnComponent } from './table-column/table-column.component';
import { PagerComponent } from '../pager/pager.component';
import { Filter, FilterOutput } from '../form-elements/filter/filter.model';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TableSelectOutput } from './models/table-select-output.model';
import { TableValue } from './models/table-value.model';
import { TableSelectType } from './models/table-select.enum';

@Directive()
export abstract class TableAbstractComponent {
	@ViewChild(PagerComponent)
	public pager!: PagerComponent;

	@ContentChildren(TableColumnComponent)
	public tableColumns!: QueryList<TableColumnComponent>;

	@ContentChild('rightHeaderTemplate')
	public rightHeaderTemplate!: TemplateRef<unknown>;

	@Input()
	// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	public dataSource!: Array<TableDataModel & Record<any, any>>;

	@Input()
	public hasFilters = false;

	@Input()
	public isSortable = true;

	@Input()
	public hasCheckboxSelection = false;

	@Input()
	public isRowSelectable = false;

	@Input()
	public selectedRowIndex?: number;

	@Input()
	public isAllSelected = false;

	@Input()
	public pagerState = new PaginationState();

	//Works only with UI Only
	@Input()
	public hasInfiniteScroll = false;

	@Input()
	public count = 0;

	@Output()
	public readonly filterChange = new EventEmitter();

	@Output()
	public readonly sortChange = new EventEmitter();

	@Output()
	public readonly pageChange = new EventEmitter();

	@Output()
	public readonly selectChange = new EventEmitter();

	@Output()
	public readonly rowClicked = new EventEmitter();

	public filterOutput: FilterOutput = {};

	public tableData!: Array<TableDataModel>;

	public pagerConfig = new PagerConfig();

	public isLoading = false;

	private loadingFilter?: (data: unknown, columnName: TableColumnComponent[], columnIndex: number) => boolean;

	constructor(private translatePipe: TranslatePipe, private cd: ChangeDetectorRef) {}

	public get isPagerConfigured() {
		return Boolean(this.pagerState && !this.hasInfiniteScroll);
	}

	public get hasRightActionsTemplate() {
		return Boolean(this.rightHeaderTemplate);
	}

	public get hasData() {
		return this.tableData?.length > 0;
	}

	public get numberOfLoadingLines() {
		return this.pagerState?.take ?? 5;
	}

	public get isFirstLoadingVisible() {
		return !this.tableData || (this.tableData.length === 0 && this.isLoading);
	}

	public get isMessageVisible() {
		return this.tableData?.length === 0 && !this.isLoading;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.count?.currentValue !== undefined) {
			const count = changes.count.currentValue as number;
			const label = count === 1 ? 'global_label_record' : 'global_label_records';
			this.pagerConfig.label = `${count.toString()} ${this.translatePipe.transform(label).toLocaleLowerCase()}`;
		}
	}

	public ngAfterContentInit() {
		this.tableColumns.toArray().forEach(column => {
			this.filterOutput[column.field] = new Filter();
		});
	}

	public trackByFn(_index: number, item: TableColumnComponent) {
		return item.title;
	}

	public async toggleLoading<T>(
		//Check for another solution to toggle loading for table
		entity: boolean | (() => Promise<void>) | (() => Promise<unknown>),
		filter?: (data: T, columnName: TableColumnComponent[], columnIndex: number) => boolean
	) {
		this.selectedRowIndex = undefined;
		this.loadingFilter = filter as (data: unknown, columnName: TableColumnComponent[], columnIndex: number) => boolean;
		if (typeof entity !== 'boolean') {
			this.isLoading = true;
			this.cd.detectChanges();
			await entity();
			this.isLoading = false;
		} else {
			this.isLoading = entity;
			this.loadingFilter = this.isLoading && this.loadingFilter ? this.loadingFilter : undefined;
		}

		this.cd.detectChanges();
	}

	public isColumnLoading(data: unknown, columnIndex: number) {
		let isLoading = this.isLoading;
		if (isLoading && this.loadingFilter !== undefined) {
			const headings = this.tableColumns.filter((_, index) => index === columnIndex);
			isLoading = isLoading && this.loadingFilter(data, headings, columnIndex);
		}

		return isLoading;
	}

	public triggerSelectChange(data: TableDataModel, isSelected: boolean) {
		if (this.isAllSelected && !data.isSelected) {
			this.isAllSelected = false;
		}

		const select = new TableSelectOutput(TableSelectType.One, this.isAllSelected);
		select.value = new TableValue(data);
		select.value.isSelected = isSelected;
		this.selectChange.emit(select);
	}

	public triggerRowClick(value: TableDataModel, index: number) {
		if (this.isRowSelectable) {
			this.selectedRowIndex = index;
			this.rowClicked.emit(value);
		}
	}
}
