import { ISpecificThemeVariables, IThemePrimitives } from '../theme.interface';

/* eslint-disable sonarjs/no-duplicate-string */
export const lightThemePrimitives: IThemePrimitives = {
	primary: 'rgba(255, 255, 255, 1)',
	secondary: 'rgba(24, 24, 27, 1)',

	brand: 'rgba(255, 108, 71, 1)',
	'brand-fill': 'rgba(255, 108, 71, 0.2)',
	'brand-overlay': 'rgba(255, 108, 71, 0.1)',
	'brand-contrast': 'rgba(255, 84, 41, 1)',

	neutral: 'rgba(237, 237, 237, 1)',
	'neutral-fill': 'rgba(217, 217, 217, 1)',
	'neutral-overlay': 'rgba(248, 248, 248, 1)',
	'neutral-muted': 'rgba(24, 24, 27, 0.03)',
	'neutral-contrast': 'rgba(108, 109, 122, 1)',

	success: 'rgba(176, 221, 122, 1)',
	'success-fill': 'rgba(176, 221, 122, 0.2)',
	'success-overlay': 'rgba(176, 221, 122, 0.1)',
	'success-contrast': 'rgba(117, 178, 89, 1)',

	warning: 'rgba(255, 202, 40, 1)',
	'warning-fill': 'rgba(255, 202, 40, 0.2)',
	'warning-overlay': 'rgba(255, 202, 40, 0.1)',
	'warning-contrast': 'rgba(204, 143, 0, 1)',

	danger: 'rgba(240, 94, 91, 1)',
	'danger-fill': 'rgba(240, 94, 91, 0.2)',
	'danger-overlay': 'rgba(240, 94, 91, 0.1)',
	'danger-contrast': 'rgba(234, 25, 21, 1)',

	info: 'rgba(66, 165, 245, 1)',
	'info-fill': 'rgba(66, 165, 245, 0.2)',
	'info-overlay': 'rgba(66, 165, 245, 0.1)',
	'info-contrast': 'rgba(25, 118, 210, 1)',

	royal: 'rgba(160, 108, 213, 1)',
	'royal-fill': 'rgba(160, 108, 213, 0.2)',
	'royal-overlay': 'rgba(160, 108, 213, 0.1)',
	'royal-contrast': 'rgba(98, 71, 179, 1)',

	clear: 'rgba(151, 239, 226, 1)',
	'clear-fill': 'rgba(151, 239, 226, 0.2)',
	'clear-overlay': 'rgba(151, 239, 226, 0.1)',
	'clear-contrast': 'rgba(68, 226, 202, 1)'
};

export const lightSpecificThemeVariables: ISpecificThemeVariables = {
	'sidebar-background': 'var(--background-neutral-overlay)',
	'overlay-background': 'rgba(24, 24, 27, 0.5)',
	'shadow-xs': '0px 1px 3px 0px rgba(24, 24, 27, 0.05), 0px 1px 2px -1px rgba(24, 24, 27, 0.05)',
	'shadow-sm': '0 4px 10px 0 rgba(24, 24, 27, 0.10)',
	'shadow-md': '0 8px 16px -5px rgba(24, 24, 27, 0.15)',
	'shadow-left': '-6px 0 10px 0 rgba(24, 24, 27, 0.15)',
	'shadow-right': '6px 0 10px 0 rgba(24, 24, 27, 0.15)'
};
