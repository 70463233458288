import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
	selector: 'rq-filter-string',
	templateUrl: './filter-string.component.html',
	styleUrls: ['./filter-string.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterStringComponent),
			multi: true
		}
	]
})
export class FilterStringComponent implements ControlValueAccessor {
	@ViewChild(NgForm)
	public form!: NgForm;

	@Input()
	public isDisabled!: boolean;

	@Output()
	public readonly clearAction = new EventEmitter();

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	private _value!: string;

	public get isValidForSearch() {
		return Boolean(this.value?.length) || this.form?.dirty;
	}

	public get value() {
		return this._value;
	}

	public set value(value: string) {
		this._value = value;
	}

	@HostListener('click')
	public setTouchedState() {
		if (this.onTouchedCallback) {
			this.onTouchedCallback(this.value);
		}
	}

	public writeValue(searchValue: string): void {
		this.value = searchValue;
	}

	public search() {
		if (!this.form.dirty) {
			return;
		}

		this.onChangeCallback(this.value);
		this.form.form.markAsPristine();
	}

	public clear() {
		if (this.isValidForSearch) {
			this.value = '';
		}

		this.clearAction.emit();
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public triggerChangeOnEnter(keyEvent: KeyboardEvent) {
		if (keyEvent.key === 'Enter' && this.isValidForSearch) {
			this.search();
		}
	}

	public registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}
}
