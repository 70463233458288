export enum UiWidgetType {
	'RiskOverview' = 1,
	'TopControlsLikelihoodReduction' = 2,
	'EndpointDistributionByScore' = 3,
	'KeyRisksByLikelihood' = 4,
	'MostImpactfulRisks' = 5,
	'MITREOverview' = 6,
	'ShortTermRecommendations' = 7,
	'LongTermRecommendations' = 8
}
