import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterSearchBoxConfig, FilterSelectOption } from '../../../form-elements/filter/filter.model';

@Component({
	selector: 'rq-table-filter-dropdown',
	templateUrl: './table-filter-dropdown.component.html',
	styleUrls: ['./table-filter-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TableFilterDropdownComponent),
			multi: true
		}
	]
})
export class TableFilterDropdownComponent implements ControlValueAccessor {
	@Input()
	public options?: FilterSelectOption<unknown>[];

	@Input()
	public isDisabled!: boolean;

	@Output()
	public readonly clearAction = new EventEmitter();

	public searchBoxConfig: FilterSearchBoxConfig = {
		searchValue: '',
		trigger: 'change',
		placeholder: 'global_search_label'
	};

	private onChangeCallback?: (_: unknown) => void;

	private onTouchedCallback?: (_: unknown) => void;

	private _value!: unknown;

	public get value() {
		return this._value;
	}

	public set value(value: unknown) {
		this._value = value;
	}

	@HostListener('click')
	public setTouchedState() {
		if (this.onTouchedCallback) {
			this.onTouchedCallback(this.options);
		}
	}

	public writeValue(value: unknown): void {
		this.setSelectedOption(value);
	}

	public triggerChange(option: FilterSelectOption<unknown>) {
		this.writeValue(option.value);

		if (this.onChangeCallback) {
			this.onChangeCallback(option.value);
		}
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}

	public isPartOfSearch(option: FilterSelectOption<unknown>) {
		if (this.searchBoxConfig) {
			let isPartOfSearch = true;

			if (this.searchBoxConfig.searchValue) {
				isPartOfSearch = option.displayName.toLowerCase().includes(this.searchBoxConfig.searchValue.toLowerCase());
			}

			return isPartOfSearch;
		}

		return true;
	}

	public clear() {
		if (!this.isDisabled) {
			this.clearAction.emit();
		}
	}

	private setSelectedOption(value: unknown) {
		if (this.options) {
			this.options.forEach(option => {
				option.isSelected = option.value === value;

				if (option.isSelected) {
					this.value = option.value;
				}
			});
		}
	}
}
