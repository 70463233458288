import { FormatterType } from '../../../../../shared/enums/formatter-type.enum';
import { COLORS } from '../../../../utils/colors.utils';

export class BubbleChartConfig {
	public isLegendVisible?: boolean;

	public isLegendTooltipVisible?: boolean;

	public xAxisLabel?: string;

	public xAxisFormatter?: FormatterType;

	public invertXAxies?: boolean;

	public yAxisLabel?: string;

	public yAxisFormatter?: FormatterType;

	public bubbleFormatter?: FormatterType;

	public bubbleSizeMultiplier!: number;

	public colors?: Array<string>;

	constructor() {
		this.bubbleFormatter = FormatterType.Percent;
		this.colors = COLORS.rainbowColorScheme;
		this.bubbleSizeMultiplier = 1;
	}
}
