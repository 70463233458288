import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RQ_ROUTES } from '../configs/routes.config';
import { getRouteParams } from '../utils/route.utils';
import { LegalEntityDataService } from '../../server/services/configuration/legal-entity-data.service';
import { LegalEntityStatusType } from '../../server/enums/legal-entity-status-type.enum';

export const legalEntityAccess = async (routeToActivate: ActivatedRouteSnapshot): Promise<boolean> => {
	const router = inject(Router);
	const legalEntityDataService = inject(LegalEntityDataService);

	const legalEntityId = getRouteParams(routeToActivate).legalEntityId as string;
	const legalEntity = await legalEntityDataService.getById(legalEntityId);

	const isAllowed = isStatusAllowed(legalEntity.state);

	if (!isAllowed) {
		await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
		return false;
	}

	return true;
};

function isStatusAllowed(state: LegalEntityStatusType) {
	const notAllowedStates = [LegalEntityStatusType.InDeletion, LegalEntityStatusType.Archived];

	return !notAllowedStates.includes(state);
}
