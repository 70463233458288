import { trigger, state, style, transition, animate } from '@angular/animations';

export const popupAnimation = trigger('popupAnimation', [
	state(
		'void',
		style({
			transform: 'translateY(-8px)',
			opacity: 0
		})
	),
	state(
		'*',
		style({
			transform: 'translateY(0)',
			opacity: 1
		})
	),
	transition(':enter', [animate('300ms cubic-bezier(0.25, 1, 0.5, 1)')]),
	transition(':leave', [animate('200ms cubic-bezier(0.65, 0, 0.35, 1)')])
]);
