import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'any'
})
export class AnyPipe implements PipeTransform {
	public transform(items: unknown[], value: unknown): boolean {
		return items?.some(item => item === value) ?? false;
	}
}
