<ng-container *ngFor="let item of items">
  <div [title]="item.mitreId"
       (click)="showDetails(item)"
       class="attack-item"
       [style.backgroundColor]="!isSubitem ? item.bgColor : ''"
       [style.color]="!isSubitem ? item.color: ''"
       [style.fill]="!isSubitem ? item.color : ''"
       [class.pointer]="hasDetails && !hasCheckboxSelection"
       [class.selected]="hasCheckboxSelection && item.isSelected"
       [class.disabled]="!hasCheckboxSelection && !item.financialValue">
    <div *ngIf="!isSubitem"
         class="item-border"
         [style.borderColor]="item.borderColor"
         [class.selected]="item.isSelected"></div>

    <div class="flex-grow-1">
      <div class="item-actions">
        <div class="item-checkbox"
             [class.readonly]="isReadOnly"
             *ngIf="hasCheckboxSelection && !isReadOnly">
          <label>
            <input (ngModelChange)="triggerSelectChange(item, $event)"
                   [(ngModel)]="item.isSelected"
                   type="checkbox" />
            <span></span>
          </label>
        </div>

        <div class="subitems-badge"
             *ngIf="!hasCheckboxSelection && item.items">{{item.items.length}}</div>
        <div class="d-flex align-items-center"
             *ngIf="item.items">
          <div class="subitems-badge"
               *ngIf="hasCheckboxSelection">{{item.items.length}}</div>
          <rq-icon *ngIf="item.items"
                   class="icon toggle-item"
                   href="{{item.isExpanded ? 'icon-minus' : 'icon-plus'}}"
                   (click)="toggleItem(item)"></rq-icon>
        </div>
      </div>

      <div class="item-content"
           [class.m-l-xs]="isSubitem">
        <div class="m-b-sm">{{item.name}}</div>
        <div *ngIf="!hasCheckboxSelection && showFinancialValue"
             class="m-b-sm item-revenue"><strong>{{item.financialValue | rqCurrency}}</strong></div>
      </div>
    </div>
  </div>

  <rq-attack-navigator-items *ngIf="item.isExpanded"
                             [isSubitem]="true"
                             [hasCheckboxSelection]="hasCheckboxSelection"
                             [items]="item.items"
                             (selectChange)="selectChange.emit($event)">
  </rq-attack-navigator-items>
</ng-container>
