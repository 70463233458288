<div class="chart-container">
  <div *ngFor="let item of data"
       class="bar-container"
       [style.width]="growDirection === 'horizontal' ? item.percent + '%' : '100%'">
    <div class="bar-details">
      <div [title]="item.label"
           class="bar-details-label truncate-1 m-b-lg">{{ item.label }}</div>
      <div [title]="item.value"
           class="bar-details-value truncate-1">{{ item.value | rqNumber }}</div>
      <div class="bar-details-percent">{{ item.percent | rqPercent }}</div>
    </div>
    <div class="bar-background">
      <div class="bar"
           [style.height]="growDirection === 'vertical' ? item.percent + '%' : '70%'"
           [style.backgroundColor]="item.color"></div>
    </div>
  </div>
  <div class="border"></div>
</div>
