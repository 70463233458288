import { Component } from '@angular/core';
import { ShareModule } from '../../shared/share.module';
import { CachingService } from '../../system/services/caching.service';
import { CACHE_KEY } from '../../shared/configs/cache.config';
import { IApiMonitor } from './api-monitor.interface';
import { HomeService } from '../../shared/services/recheck/home.service';

@Component({
	standalone: true,
	selector: 'rq-api-monitor',
	templateUrl: './api-monitor.component.html',
	styleUrls: ['./api-monitor.component.scss'],
	imports: [ShareModule]
})
export class ApiMonitorComponent {
	public apiCalls: Array<IApiMonitor> = [];

	public slowestApiCalls: Array<IApiMonitor> = [];

	public mostUsedApiCalls: Array<IApiMonitor> = [];

	public datePattern = 'MMMM D, YYYY LT';

	constructor(private cachingService: CachingService, private homeService: HomeService) {}

	public ngOnInit() {
		this.getData();
	}

	public clearLogs() {
		this.cachingService.removeItem(CACHE_KEY.ApiPerformanceLogs);
		this.getData();
	}

	public backToApp() {
		this.homeService.navigateHome();
	}

	public getMethodColor(method: string): string {
		switch (method.toUpperCase()) {
			case 'GET':
				return 'badge-success';
			case 'POST':
				return 'badge-info';
			case 'PUT':
				return 'badge-primary';
			case 'PATCH':
				return 'badge-warning';
			case 'DELETE':
				return 'badge-danger';
			case 'OPTIONS':
				return 'badge-royal';
			case 'HEAD':
				return 'badge-secondary';
			default:
				return '';
		}
	}

	private getData() {
		const data = (this.cachingService.getItem(CACHE_KEY.ApiPerformanceLogs) as Array<IApiMonitor>) ?? [];
		data.sort((a, b) => b.time - a.time);
		this.apiCalls = data;
		this.slowestApiCalls = data.slice(0, 5);
		this.mostUsedApiCalls = this.getMostUsedCalls(data);
	}

	private getMostUsedCalls(data: Array<IApiMonitor>) {
		const uniqueData = [...new Map(data.map(x => [x.url, x])).values()];
		uniqueData.sort((a, b) => b.count - a.count);
		return uniqueData.slice(0, 5);
	}
}
