import { Component, EventEmitter, Output } from '@angular/core';
import { TableBase2Component } from 'src/app/standalone/components/table/table-base2.component';
import { MostImpactfulRisksDisplayModel } from '../../_common/models/most-impactful-risks.displaymodel';

@Component({
	selector: 'rq-most-impactful-risks-table',
	templateUrl: './most-impactful-risks-table.widget.html',
	styleUrls: ['./most-impactful-risks-table.widget.scss']
})
export class MostImpactfulRisksTableWidget extends TableBase2Component<MostImpactfulRisksDisplayModel, unknown> {
	@Output()
	public readonly viewThreat = new EventEmitter<MostImpactfulRisksDisplayModel>();

	public redirectToDetails(item: MostImpactfulRisksDisplayModel) {
		this.viewThreat.emit(item);
	}
}
