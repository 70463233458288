<ng-container *ngIf="isLoading">
  <rq-loader [type]="isPage || isContent ? 'spinner' : 'card'"></rq-loader>
</ng-container>

<ng-container *ngIf="!isLoading">
  <!-- CARD TITLE -->
  <ng-container *ngIf="title || customHeader?.templateRef">
    <ng-container [ngTemplateOutlet]="customHeader?.templateRef || defaultHeader"></ng-container>
  </ng-container>

  <ng-container *ngIf="!isNoDataVisible">
    <!-- CARD CONTENT -->
    <div class="card-content"
         [class.card-content-flex]="isPage || isContent"
         [class.card-content-padding]="!isPage && !isContent">
      <ng-container [ngTemplateOutlet]="widgetContent?.templateRef || defaultHeader"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isNoDataVisible">
    <section class="no-data-wrapper">
      <ng-container [ngTemplateOutlet]="noDataContent?.templateRef || defaultNoDataTemplate"></ng-container>
    </section>
  </ng-container>
</ng-container>


<!-- TEMPLATES -->
<ng-template #defaultHeader>
  <header class="card-header">
    <ng-container [ngTemplateOutlet]="customTitle?.templateRef || defaultTitle"></ng-container>
    <ng-container *ngIf="customAction?.templateRef">
      <ng-container [ngTemplateOutlet]="customAction?.templateRef || null"></ng-container>
    </ng-container>
  </header>

  <ng-template #defaultTitle>
    <p class="card-header-title truncate-1"
       title="{{title | translate}}">{{title | translate}}</p>
  </ng-template>
</ng-template>

<ng-template #defaultNoDataTemplate>
  <ng-container *ngIf="!isPage && !isContent">
    <section class="m-y-xl">
      <rq-not-available-sign></rq-not-available-sign>
      <p class="text-description m-t-lg">No data available</p>
    </section>
  </ng-container>

  <ng-container *ngIf="isPage || isContent">
    <img src="{{'../../../../../../assets/images/no-risk-data-placeholder.svg'}}"
         alt="No Data">
  </ng-container>
</ng-template>
