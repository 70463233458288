import { Component } from '@angular/core';
import { TableBase2Component } from 'src/app/standalone/components/table/table-base2.component';
import { TopControlsLikelihoodReductionDisplayModel } from '../../_common/models/top-controls-likelihood-reduction.displaymodel';

@Component({
	selector: 'rq-top-controls-likelihood-reduction-table-widget',
	templateUrl: './top-controls-likelihood-reduction-table.widget.html',
	styleUrls: ['./top-controls-likelihood-reduction-table.widget.scss']
})
export class TopControlsLikelihoodReductionTableWidget extends TableBase2Component<TopControlsLikelihoodReductionDisplayModel, unknown> {}
