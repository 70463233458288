import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { NewBarChartDisplayModel } from './new-bar-chart.displaymodel';
import { EChartsType, init } from 'echarts';
import { ChartService } from '../../charts/chart.service';
import { NewBarChartConfig } from './new-bar-chart.config';
import { COLORS } from 'src/app/standalone/utils/colors.utils';

@Component({
	selector: 'rq-new-bar-chart',
	templateUrl: './new-bar-chart.component.html',
	styleUrls: ['./new-bar-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChartService]
})
export class NewBarChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	public data!: Array<NewBarChartDisplayModel>;

	@Input()
	public config!: NewBarChartConfig;

	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	private chart?: EChartsType;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		this.resize();
	}

	public ngOnChanges(changes: SimpleChanges) {
		const data = changes.data?.currentValue as Array<NewBarChartDisplayModel>;

		if (data && this.config) {
			this.initChart(data);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private initChart(data: Array<NewBarChartDisplayModel>) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				xAxis: this.setXAxisConfig(data),
				yAxis: this.setYAxisConfig(),
				grid: this.setGridConfig(),
				series: this.setSeriesConfig(data)
			};
			this.chart.setOption(options);
		} else {
			this.chart?.dispose();
		}
	}

	private setXAxisConfig(data: Array<NewBarChartDisplayModel>) {
		return {
			type: 'category',
			data: data.map(item => item.label),
			axisTick: {
				show: false
			},
			axisLabel: {
				interval: 0,
				fontSize: 12,
				fontWeight: 500,
				fontFamily: 'Inter, sans-serif',
				color: COLORS.secondaryText,
				overflow: 'breakAll',
				formatter: (value: string) => value.replace(/\n/g, ''),
				width: (this.chartContainer.nativeElement.scrollWidth / data.length) * (85 / 100)
			},
			axisLine: {
				lineStyle: {
					color: COLORS.neutralContrastBackground
				}
			}
		};
	}

	private setYAxisConfig() {
		return {
			type: 'value',
			axisLabel: {
				formatter: this.chartService.getFormatter(this.config.yAxisFormatter),
				fontFamily: 'Inter, sans-serif',
				fontSize: 12,
				fontWeight: '500',
				lineStyle: {
					type: 'dashed'
				}
			},
			splitLine: {
				lineStyle: {
					color: COLORS.neutralFill,
					type: 'dashed'
				}
			}
		};
	}

	private setGridConfig() {
		return {
			left: 0,
			right: 0,
			bottom: 8,
			top: 8,
			containLabel: true
		};
	}

	private setSeriesConfig(data: Array<NewBarChartDisplayModel>) {
		return [
			{
				type: 'bar',
				data: data.map(item => item.value),
				barWidth: 40,
				itemStyle: {
					borderRadius: [4, 4, 4, 4],
					color: COLORS.infoBackground
				},
				emphasis: {
					disabled: true
				}
			}
		];
	}

	private resize() {
		if (this.chart) {
			this.chart.resize();

			this.initChart(this.data);
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}
