import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CachingService } from 'src/app/system/services/caching.service';
import { CACHE_KEY } from '../../../../shared/configs/cache.config';

@Injectable({
	providedIn: 'root'
})
export class SidebarStateStore {
	public readonly isSidebarExtended$: Observable<boolean>;

	private _isSidebarExtended$ = new BehaviorSubject<boolean>(false);

	constructor(private cachingService: CachingService) {
		const savedState = this.cachingService.getItem<boolean>(CACHE_KEY.SidebarExpanded);
		if (savedState !== null) {
			this._isSidebarExtended$.next(savedState);
		} else {
			this.cachingService.setItem(CACHE_KEY.SidebarExpanded, true, true);
		}

		this.isSidebarExtended$ = this._isSidebarExtended$.asObservable();
	}

	public get state() {
		return this._isSidebarExtended$.getValue();
	}

	public setSidebarState(isExpanded: boolean) {
		this._isSidebarExtended$.next(isExpanded);
		this.cachingService.setItem(CACHE_KEY.SidebarExpanded, isExpanded, true);
	}
}
