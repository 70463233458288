import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RiskOverviewDetailsDisplayModel } from 'src/app/shared/widgets/_common/models/risk-overview-details.diplaymodel';

@Component({
	selector: 'rq-risk-overview-likelihood-widget',
	templateUrl: './risk-overview-likelihood.widget.html',
	styleUrls: ['./risk-overview-likelihood.widget.scss']
})
export class RiskOverviewLikelihoodWidget {
	@Input()
	public data?: RiskOverviewDetailsDisplayModel;

	@Output()
	public readonly redirect = new EventEmitter<RiskOverviewDetailsDisplayModel>();

	public redirectToRisk(data: RiskOverviewDetailsDisplayModel) {
		this.redirect.emit(data);
	}
}
