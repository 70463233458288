import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnyPipe } from './pipes/any.pipe';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from '../standalone/directives/click-outside.directive';
import { AuthorizeDirective } from './core/roles/authorize.directive';
import { ScrollTopOnNavigationDirective } from './directives/scroll-top-on-navigation.directive';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { DeleteAlertDeniedComponent } from './components/delete-alert-denied/delete-alert-denied.component';
import { CloneModalComponent } from './components/clone-modal/clone-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomModelsFacade } from './facades/custom-models.facade';
import { ControlSubTypeTranslate } from './pipes/translate-pipes/control-subtype-label.pipe';
import { ApplicationTypeTranslate } from './pipes/translate-pipes/application-type-label.pipe';
import { AttackSubTypeTranslate } from './pipes/translate-pipes/attack-subtype-label.pipe';
import { CustomModelsCreateModalComponent } from './business-components/custom-models-create-modal/custom-models-create-modal.component';
import { EnterpriseControlsBadgeComponent } from './business-components/enterprise-controls-badge/enterprise-controls-badge.component';
import { HeadingComponent } from './business-components/heading/heading.component';
import { HeadingTitleDirective, HeadingSubtitleDirective } from './business-components/heading/heading.directive';
import { LogoComponent } from './business-components/logo/logo.component';
import { LogoutConfirmationModalComponent } from './business-components/header/logout-confirmation-modal/logout-confirmation-modal.component';
import { NotificationPopupComponent } from './business-components/header/notification-popup/notification-popup.component';
import { StartingPageSettingsComponent } from './business-components/starting-page-settings/starting-page-settings.component';
import { StartingPageComponent } from './business-components/starting-page/starting-page.component';
import { ExchangeRateComponent } from './business-components/exchange-rate/exchange-rate.component';
import { NotificationTableComponent } from './business-components/notification-table/notification-table.component';
import { UserEditFormComponent } from './business-components/user-edit-form/user-edit-form.component';
import { CharacteristicsOfLossTableComponent } from './business-components/characteristics-of-loss-card/characteristics-of-loss-table.component';
import { ScenariosEnterpriseViewComponent } from './business-components/scenarios-enterprise-view/scenarios-enterprise-view.page';
import { ScenariosFairViewComponent } from './business-components/scenarios-fair-view/scenarios-fair-view.page';
import { ScenariosRateOfIncidentViewComponent } from './business-components/scenarios-rate-of-incident-view/scenarios-rate-of-incident-view.page';
import { DashboardAttackCardComponent } from './business-components/dashboard-attack-card/dashboard-attack-card.component';
import { AnalysisThreatsTableComponent } from './business-components/analysis-top-threats-table/analysis-threats-table.component';
import { ApplicationRiskDetailsTableComponent } from './business-components/application-risk-details-table/application-risk-details-table.component';
import { ApplicationSummaryBarChartComponent } from './business-components/application-summary-bar-chart/application-summary-bar-chart.component';
import { DashboardAllContentComponent } from './business-components/dashboard-all-content/dashboard-all-content.component';
import { DashboardAttackContentComponent } from './business-components/dashboard-attack-content/dashboard-attack-content.component';
import { DashboardCustomContentComponent } from './business-components/dashboard-custom-content/dashboard-custom-content.component';
import { RecommendationAnalysisViewComponent } from './business-components/recommendation-analysis-view/recommendation-analysis-view.component';
import { StepHowComponent } from './business-components/recommendation-analysis-view/step-how/step-how.component';
import { StepWhatComponent } from './business-components/recommendation-analysis-view/step-what/step-what.component';
import { StepWhereComponent } from './business-components/recommendation-analysis-view/step-where/step-where.component';
import { ApplicationListModalComponent } from './business-components/recommendation-analysis-view/step-where/application-list-modal/application-list-modal.component';
import { ApplicationListComponent } from './business-components/recommendation-analysis-view/step-where/application-list/application-list.component';
import { ControlOptionContentComponent } from './business-components/recommendation-analysis-view/step-how/control-option-content/control-option-content.component';
import { RecommendationApplicationTableComponent } from './business-components/recommendation-application-table/recommendation-application-table.component';
import { RecommendationDetailedTableComponent } from './business-components/recommendation-detailed-table/recommendation-detailed-table.component';
import { RecommendationRoiCalculatorTableComponent } from './business-components/recommendation-roi-calculator-table/recommendation-roi-calculator-table.component';
import { TopControlsReductionByPercentageComponent } from './business-components/top-controls-reduction-by-percentage/top-controls-reduction-by-percentage.component';
import { AnalysisInfoCardComponent } from './business-components/dashboard-attack-card/analysis-info-card/analysis-info-card.component';
import { DashboardTopThreatsComponent } from './business-components/dashboard-top-threats/dashboard-top-threats.component';
import { ApplicationsRisksViewComponent } from './business-components/applications-risks-view/applications-risks-view.component';
import { ApplicationsModalComponent } from './business-components/recommendation-detailed-table/applications-modal/applications-modal.component';
import { AnalysisRecommendationLevelComponent } from './business-components/analysis-recommendation-level/analysis-recommendation-level.component';
import { ApplicationDetailsTableComponent } from './business-components/recommendation-detailed-table/applications-modal/application-details-table/application-details-table.component';
import { EvidencesDetailsModalComponent } from './business-components/evidences-details-modal/evidences-details-modal.component';
import { EvidencesListComponent } from './business-components/evidences-details-modal/evidences-list/evidences-list.component';
import { ControlLevelInfoComponent } from './business-components/evidences-details-modal/control-level-info/control-level-info.component';
import { FooterComponent } from './business-components/footer/footer.component';
import { HeaderComponent } from './business-components/header/header.component';
import { NavigationItemComponent } from './business-components/navigation-sidebar/navigation-item/navigation-item.component';
import { NavigationSidebarComponent } from './business-components/navigation-sidebar/navigation-sidebar.component';
import { LegalEntitiesDropdownComponent } from './business-components/legal-entities-dropdown/legal-entities-dropdown.component';
import { PointSliderComponent } from './components/point-slider/point-slider.component';
import { AnalysisSummaryCategoryTypeTranslate } from './pipes/translate-pipes/analysis-summary-category-type-label.pipe';
import { AnalysisSummaryTypeTranslate } from './pipes/translate-pipes/analysis-summary-type-label.pipe';
import { AnalysisSummaryTimeframeIconTypeTranslate } from './pipes/translate-pipes/analysis-summary-timeframe-icon-label.pipe';
import { LegalEntityValidatorsService } from './async-validators/legal-entity-validators.service';
import { NotificationsModalComponent } from './business-components/notifications-modal/notifications-modal.component';
import { UsersManagementTableComponent } from './business-components/users-management-table/users-management-table.component';
import { UnlockUserModalComponent } from './business-components/unlock-user-modal/unlock-user-modal.component';
import { DeactivateUserDialogComponent } from './business-components/deactivate-user-dialog/deactivate-user-dialog.component';
import { ResetPasswordConfirmationModalComponent } from './business-components/reset-password-confirmation-modal/reset-password-confirmation-modal.component';
import { UserManagementFacade } from './facades/user-management.facade';
import { ApplicationEnterpriseProfileTypeTranslate } from './pipes/translate-pipes/application-enterprise-profile-type-label.pipe';
import { ApplicationManagedDatumTranslate } from './pipes/translate-pipes/application-managed-datum-label.pipe';
import { ApplicationStatusTranslate } from './pipes/translate-pipes/application-status-label.pipe';
import { AttackGoalTypeTranslate } from './pipes/translate-pipes/attack-goal-type-label.pipe';
import { BusinessAssetStatusTypeTranslate } from './pipes/translate-pipes/business-asset-status-type-label.pipe';
import { BusinessEntityTypeTranslate } from './pipes/translate-pipes/business-entity-type-label.pipe';
import { CalculationTypeTranslate } from './pipes/translate-pipes/calculation-type-label.pipe';
import { ConfidenceTranslate } from './pipes/translate-pipes/confidence-label.pipe';
import { ContractCostTranslate } from './pipes/translate-pipes/contract-cost-label.pipe';
import { ContractProfileTypeTranslate } from './pipes/translate-pipes/contract-profile-type-label.pipe';
import { ControlProfileTypeTranslate } from './pipes/translate-pipes/control-profile-type-label.pipe';
import { CustomModelTypeTranslate } from './pipes/translate-pipes/custom-models-type-label.pipe';
import { CustomVariableTypeTranslate } from './pipes/translate-pipes/custom-variable-type-label.pipe';
import { CveSeverityTypeTranslate } from './pipes/translate-pipes/cve-severity-type-label.pipe';
import { CveVariationTypeTranslate } from './pipes/translate-pipes/cve-variation-type-label.pipe';
import { DataProfileTypeTranslate } from './pipes/translate-pipes/data-profile-type-label.pipe';
import { DraftLabelTranslate } from './pipes/translate-pipes/draft-label.pipe';
import { EndpointScoreTypeTranslate } from './pipes/translate-pipes/endpoint-score-types-label.pipe';
import { ExploitabilityTypeTranslate } from './pipes/translate-pipes/exploitability-type-label.pipe';
import { FairLossMagnitudeSubtypeTranslate } from './pipes/translate-pipes/fair-loss-magnitude-subtype-label.pipe';
import { FairLossMagnitudeTypeTranslate } from './pipes/translate-pipes/fair-loss-magnitude-type-label.pipe';
import { FairTypeTranslate } from './pipes/translate-pipes/fair-type-label.pipe';
import { ImpactVectorRiskTypeTranslate } from './pipes/translate-pipes/impact-vector-risk-type-label.pipe';
import { IntegrationTypeTranslate } from './pipes/translate-pipes/integration-type-label.pipe';
import { LegalEntityStatusTranslate } from './pipes/translate-pipes/legal-entity-status-label.pipe';
import { LegalEntityTypeTranslate } from './pipes/translate-pipes/legal-entity-type-label.pipe';
import { LicenseTypeTranslate } from './pipes/translate-pipes/license-type-label.pipe';
import { LossExposureTypeTranslate } from './pipes/translate-pipes/loss-exposure-type-label.pipe';
import { LossMagnitudeTypeTranslate } from './pipes/translate-pipes/loss-magnitude-type-label.pipe';
import { LossSubTypeTranslate } from './pipes/translate-pipes/loss-subtype-label.pipe';
import { NotificationTypeTranslate } from './pipes/translate-pipes/notification-type-label.pipe';
import { OtherLossExposureStatusTranslate } from './pipes/translate-pipes/other-loss-exposure-status-label.pipe';
import { ProviderTypeTranslate } from './pipes/translate-pipes/provider-type-label.pipe';
import { SummaryCountComponent } from './business-components/summary-count/summary-count.component';
import { RecordCountTranslate } from './pipes/translate-pipes/record-count-label.pipe';
import { ScenarioAnalysisStatusTranslate } from './pipes/translate-pipes/scenario-analysis-status-label.pipe';
import { ScenarioConfigStatusTranslate } from './pipes/translate-pipes/scenario-config-status-label.pipe';
import { StatusTranslate } from './pipes/translate-pipes/status-label.pipe';
import { TargetEndpointStatusTypeTranslate } from './pipes/translate-pipes/target-endpoint-status-type-label.pipe';
import { ThirdPartyAnalysisStatusTranslate } from './pipes/translate-pipes/third-party-analysis-status-label.pipe';
import { ScenarioTypeTranslate } from './pipes/translate-pipes/scenario-type-label.pipe';
import { ThirdPartyConfigStatusTranslate } from './pipes/translate-pipes/third-party-config-status-label.pipe';
import { ThreatCapabilityTypeLabelTranslate } from './pipes/translate-pipes/threat-capability-type-label.pipe';
import { ThirdPartyLossExposureTranslate } from './pipes/translate-pipes/third-party-loss-exposure-label.pipe';
import { TuningApplicationTypeTranslate } from './pipes/translate-pipes/tuning-application-type-label.pipe';
import { TechnicalRiskScoreTypeTranslate } from './pipes/translate-pipes/technical-risk-score-label.pipe';
import { SurecloudTypeTranslate } from './pipes/translate-pipes/surecloud-type-label.pipe';
import { ApplicationProfileTypeTranslate } from './pipes/translate-pipes/application-profile-type-label.pipe';
import { AttackNavigatorComponent } from './business-components/attack-navigator/attack-navigator.component';
import { AttackNavigatorItemsComponent } from './business-components/attack-navigator/attack-navigator-items/attack-navigator-items.component';
import { MitigationTypeTranslate } from './pipes/translate-pipes/mitigation-specifics-type-label.pipe';
import { QualitativeScaleRiskLevelTranslate } from './pipes/translate-pipes/qualitative-scale-risk-level-label.pipe';
import { ImpactVectorsTableComponent } from './business-components/impact-vectors-table/impact-vectors-table.component';
import { AuditTableNewComponent } from './business-components/audit-table-new/audit-table-new.component';
import { AuditBehaviorTypeTranslate } from './pipes/translate-pipes/audit-behavior-type-label.pipe';
import { StandaloneModule } from '../standalone/standalone.module';
import { RqCurrencyPipe } from '../standalone/pipes/rq-currency.pipe';
import { RqDatePipe } from '../standalone/pipes/rq-date.pipe';
import { RqNumberPipe } from '../standalone/pipes/rq-number.pipe';
import { RqPercentPipe } from '../standalone/pipes/rq-percent.pipe';
import { EllipsisDirective } from '../standalone/directives/ellipsis.directive';
import { LoadingInlineDirective } from '../standalone/directives/loading-inline.directive';
import { PreventDefaultDirective } from '../standalone/directives/prevent-default.directive';
import { TranslatePipe } from '../standalone/pipes/translate.pipe';
import { IconComponent } from '../standalone/components/icon/icon.component';
import { InfiniteScrollDirective } from '../standalone/directives/infinite-scroll.directive';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { LoadingSpinnerComponent } from '../standalone/components/loading-spinner/loading-spinner.component';
import { LoaderComponent } from '../standalone/components/loader/loader.component';
import { PageTitleActionDirective } from './components/page-title/page-title-action-directive';
import { HasLicenseDirective } from './core/licenses/has-license.directive';
import { HasFeatureDirective } from './core/feature-flag/has-feature.directive';
import { UserPopupComponent } from './business-components/header/user-popup/user-popup.component';
import { AnalysisSummaryComponent } from './business-components/header/analysis-summary/analysis-summary.component';
import { E2eDirective } from '../standalone/directives/e2e.directive';
import { EndpointsWidget } from './widgets/endpoints/endpoints.widget';
import { WidgetsCustomizerComponent } from './components/widgets-customizer/widgets-customizer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RiskOverviewFrequencyWidget } from './widgets/risk-overview/risk-overview-frequency/risk-overview-frequency.widget';
import { RiskOverviewMaximumImpactWidget } from './widgets/risk-overview/risk-overview-maximum-impact/risk-overview-maximum-impact.widget';
import { RiskOverviewRevenueWidget } from './widgets/risk-overview/risk-overview-revenue/risk-overview-revenue.widget';
import { RiskOverviewWidget } from './widgets/risk-overview/risk-overview.widget';
import { RiskOverviewLikelihoodWidget } from './widgets/risk-overview/risk-overview-likelihood/risk-overview-likelihood.widget';
import { LongTermRecommendationsWidget } from './widgets/long-term-recommendations/long-term-recommendations.widget';
import { LongTermRecommendationsTableWidget } from './widgets/long-term-recommendations/long-term-recommendations-table/long-term-recommendations-table.widget';
import { LinkComponent } from './components/link/link.component';
import { MostImpactfulRisksTableWidget } from './widgets/most-impactful-risks/most-impactful-risks-table/most-impactful-risks-table.widget';
import { MostImpactfulRisksWidget } from './widgets/most-impactful-risks/most-impactful-risks.widget';
import { KeyRisksByLikelihoodWidget } from './widgets/key-risks-by-likelihood/key-risks-by-likelihood.component';
import { TopControlsLikelihoodReductionWidget } from './widgets/top-controls-likelihood-reduction/top-controls-likelihood-reduction.widget';
import { TopControlsLikelihoodReductionTableWidget } from './widgets/top-controls-likelihood-reduction/top-controls-likelihood-reduction-table/top-controls-likelihood-reduction-table.widget';
import { ShortTermRecommendationsWidget } from './widgets/short-term-recommendations/short-term-recommendations.widget';
import { CountLegendComponent } from './components/count-legend/count-legend.component';
import { MitreOverviewWidget } from './widgets/mitre-overview/mitre-overview.widget';

const pipes = [
	AnyPipe,
	AnalysisSummaryCategoryTypeTranslate,
	AnalysisSummaryTimeframeIconTypeTranslate,
	AnalysisSummaryTypeTranslate,
	ApplicationEnterpriseProfileTypeTranslate,
	ApplicationManagedDatumTranslate,
	ApplicationStatusTranslate,
	ApplicationTypeTranslate,
	AttackGoalTypeTranslate,
	AttackSubTypeTranslate,
	BusinessAssetStatusTypeTranslate,
	BusinessEntityTypeTranslate,
	CalculationTypeTranslate,
	ConfidenceTranslate,
	ContractCostTranslate,
	ContractProfileTypeTranslate,
	ControlProfileTypeTranslate,
	ControlSubTypeTranslate,
	CustomModelTypeTranslate,
	CustomVariableTypeTranslate,
	CveSeverityTypeTranslate,
	CveVariationTypeTranslate,
	DataProfileTypeTranslate,
	DraftLabelTranslate,
	EndpointScoreTypeTranslate,
	ExploitabilityTypeTranslate,
	FairLossMagnitudeSubtypeTranslate,
	FairLossMagnitudeTypeTranslate,
	FairTypeTranslate,
	ImpactVectorRiskTypeTranslate,
	LegalEntityStatusTranslate,
	LegalEntityTypeTranslate,
	LicenseTypeTranslate,
	LossExposureTypeTranslate,
	LossMagnitudeTypeTranslate,
	LossSubTypeTranslate,
	NotificationTypeTranslate,
	OtherLossExposureStatusTranslate,
	ProviderTypeTranslate,
	RecordCountTranslate,
	ScenarioAnalysisStatusTranslate,
	ScenarioConfigStatusTranslate,
	ScenarioTypeTranslate,
	StatusTranslate,
	TargetEndpointStatusTypeTranslate,
	TechnicalRiskScoreTypeTranslate,
	ThirdPartyAnalysisStatusTranslate,
	ThirdPartyConfigStatusTranslate,
	ThirdPartyLossExposureTranslate,
	ThreatCapabilityTypeLabelTranslate,
	TuningApplicationTypeTranslate,
	LegalEntityStatusTranslate,
	AnalysisSummaryCategoryTypeTranslate,
	AnalysisSummaryTypeTranslate,
	AnalysisSummaryTimeframeIconTypeTranslate,
	IntegrationTypeTranslate,
	CveVariationTypeTranslate,
	SurecloudTypeTranslate,
	ExploitabilityTypeTranslate,
	ApplicationProfileTypeTranslate,
	ProviderTypeTranslate,
	QualitativeScaleRiskLevelTranslate,
	AuditBehaviorTypeTranslate,
	MitigationTypeTranslate
];

const asyncValidatorsServices = [LegalEntityValidatorsService];

const components = [
	AuthorizeDirective,
	HasFeatureDirective,
	ScrollTopOnNavigationDirective,
	DeleteAlertDeniedComponent,
	DeleteDialogComponent,
	CloneModalComponent,
	FooterComponent,
	HeaderComponent,
	LogoComponent,
	NavigationSidebarComponent,
	LegalEntitiesDropdownComponent,
	PointSliderComponent,
	NotificationsModalComponent,
	UsersManagementTableComponent,
	UnlockUserModalComponent,
	DeactivateUserDialogComponent,
	ResetPasswordConfirmationModalComponent,
	RecommendationRoiCalculatorTableComponent,
	RecommendationApplicationTableComponent,
	SummaryCountComponent,
	PageTitleComponent,
	PageTitleActionDirective,
	HasLicenseDirective,
	AttackNavigatorComponent,
	WidgetsCustomizerComponent,
	LinkComponent
];

const businessComponents = [
	EnterpriseControlsBadgeComponent,
	HeadingComponent,
	HeadingTitleDirective,
	HeadingSubtitleDirective,
	StartingPageComponent,
	StartingPageSettingsComponent,
	CustomModelsCreateModalComponent,
	ExchangeRateComponent,
	NotificationTableComponent,
	UserEditFormComponent,
	CharacteristicsOfLossTableComponent,
	ScenariosEnterpriseViewComponent,
	ScenariosFairViewComponent,
	ScenariosRateOfIncidentViewComponent,
	DashboardAttackCardComponent,
	AnalysisThreatsTableComponent,
	ApplicationRiskDetailsTableComponent,
	ApplicationSummaryBarChartComponent,
	DashboardAllContentComponent,
	DashboardAttackContentComponent,
	DashboardCustomContentComponent,
	RecommendationAnalysisViewComponent,
	RecommendationDetailedTableComponent,
	TopControlsReductionByPercentageComponent,
	DashboardTopThreatsComponent,
	ApplicationsRisksViewComponent,
	AnalysisRecommendationLevelComponent,
	ImpactVectorsTableComponent,
	AuditTableNewComponent,
	EvidencesDetailsModalComponent,
	AnalysisSummaryComponent
];

const widgets = [
	RiskOverviewWidget,
	RiskOverviewRevenueWidget,
	RiskOverviewMaximumImpactWidget,
	RiskOverviewLikelihoodWidget,
	RiskOverviewFrequencyWidget,
	MostImpactfulRisksWidget,
	MostImpactfulRisksTableWidget,
	EndpointsWidget,
	TopControlsLikelihoodReductionWidget,
	TopControlsLikelihoodReductionTableWidget,
	LongTermRecommendationsWidget,
	LongTermRecommendationsTableWidget,
	ShortTermRecommendationsWidget,
	KeyRisksByLikelihoodWidget,
	MitreOverviewWidget
];

const facades = [CustomModelsFacade, UserManagementFacade];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		StandaloneModule,
		DragDropModule,
		RqCurrencyPipe,
		RqDatePipe,
		RqNumberPipe,
		RqPercentPipe,
		TranslatePipe,
		EllipsisDirective,
		LoadingInlineDirective,
		EllipsisDirective,
		E2eDirective,
		ClickOutsideDirective,
		PreventDefaultDirective,
		IconComponent,
		LoadingSpinnerComponent,
		LoaderComponent,
		InfiniteScrollDirective
	],
	declarations: [
		...components,
		...businessComponents,
		...pipes,
		...widgets,
		StepHowComponent,
		StepWhatComponent,
		StepWhereComponent,
		ApplicationListModalComponent,
		ApplicationListComponent,
		ControlOptionContentComponent,
		AnalysisInfoCardComponent,
		ApplicationsModalComponent,
		ApplicationDetailsTableComponent,
		EvidencesDetailsModalComponent,
		EvidencesListComponent,
		ControlLevelInfoComponent,
		NavigationItemComponent,
		NotificationPopupComponent,
		LogoutConfirmationModalComponent,
		AttackNavigatorComponent,
		AttackNavigatorItemsComponent,
		UserPopupComponent,
		CountLegendComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		RqCurrencyPipe,
		RqDatePipe,
		RqNumberPipe,
		RqPercentPipe,
		TranslatePipe,
		EllipsisDirective,
		LoadingInlineDirective,
		PreventDefaultDirective,
		E2eDirective,
		ClickOutsideDirective,
		IconComponent,
		LoadingSpinnerComponent,
		LoaderComponent,
		StandaloneModule,
		...components,
		...businessComponents,
		...pipes,
		...widgets
	],
	providers: [...facades, ...pipes, ...asyncValidatorsServices]
})
export class ShareModule {}
