import { Component } from '@angular/core';
import { CustomBarChartDisplayModel } from '../../../standalone/components/new-charts/custom-bar-chart/custom-bar-chart.displaymodel';
import { BaseComponent } from '../../components/base.component';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../../configs/routes.config';
import { LastAnalysisStore } from '../../../routes/control-analysis/_common/store/last-analysis.store';
import { WidgetsService } from '../widgets.service';
import { AnalysisEndpointDataService } from '../../../server/services/analysis/analysis-endpoint-data.service';
import { AnalysisEndpointScoreDistributionViewModel } from '../../../server/models/analysis-endpoint-score-distribution-view-model.model';
import { TechnicalRiskScaleType } from '../../../server/enums/technical-risk-scale-type.enum';
import { TechnicalRiskScoreTypeTranslate } from '../../pipes/translate-pipes/technical-risk-score-label.pipe';
import { UiWidgetType } from 'src/app/server/enums/ui-widget-type.enum';

@Component({
	selector: 'rq-endpoints-widget',
	templateUrl: './endpoints.widget.html',
	styleUrls: ['./endpoints.widget.scss']
})
export class EndpointsWidget extends BaseComponent {
	public data!: Array<CustomBarChartDisplayModel> | null;

	public totalCount!: number;

	constructor(
		private router: Router,
		private widgetsService: WidgetsService,
		private technicalRiskScoreTypeTranslate: TechnicalRiskScoreTypeTranslate,
		private lastAnalysisStore: LastAnalysisStore,
		private analysisEndpointDataService: AnalysisEndpointDataService
	) {
		super();
	}

	private get lastAnalysisId() {
		return this.lastAnalysisStore.lastAnalysis?.id ?? '';
	}

	public ngOnInit() {
		this.subscribeToRefresh();
		this.initData();
	}

	public viewAll() {
		if (this.urlLegalEntityId && this.lastAnalysisId) {
			this.router.navigateByUrl(RQ_ROUTES.pro.technicalAnalysis.tabs.technicalAssets.url(this.lastAnalysisId, this.urlLegalEntityId));
		}
	}

	private async initData() {
		if (this.urlLegalEntityId) {
			const data = await this.analysisEndpointDataService.getByTechnicalScoreDistribution(this.urlLegalEntityId);
			this.setChartData(data);
		}
	}

	private setChartData(data: AnalysisEndpointScoreDistributionViewModel) {
		const colors = new Map<TechnicalRiskScaleType, string>([
			[TechnicalRiskScaleType.Critical, '--background-danger-contrast'],
			[TechnicalRiskScaleType.High, '--background-danger'],
			[TechnicalRiskScaleType.Medium, '--background-warning'],
			[TechnicalRiskScaleType.Low, '--background-success-contrast'],
			[TechnicalRiskScaleType.None, '--background-neutral']
		]);

		this.totalCount = data.totalCount;

		const customOrder: TechnicalRiskScaleType[] = [
			TechnicalRiskScaleType.Critical,
			TechnicalRiskScaleType.High,
			TechnicalRiskScaleType.Medium,
			TechnicalRiskScaleType.Low,
			TechnicalRiskScaleType.None
		];

		data.distribution.sort((a, b) => {
			return customOrder.indexOf(a.type) - customOrder.indexOf(b.type);
		});

		this.data = data.distribution.map(x => {
			const colorVariable = colors.get(x.type) ?? '--background-neutral';
			return <CustomBarChartDisplayModel>{
				label: this.technicalRiskScoreTypeTranslate.transform(x.type),
				value: x.count,
				percent: (x.count * 100) / this.totalCount,
				color: getComputedStyle(document.documentElement).getPropertyValue(colorVariable)
			};
		});
	}

	private subscribeToRefresh() {
		this.subscriptions.push(
			this.widgetsService.refresh$.subscribe((type: UiWidgetType) => {
				if (type === UiWidgetType.EndpointDistributionByScore) {
					this.data = null;
					this.initData();
				}
			})
		);
	}
}
