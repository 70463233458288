<ngx-slider [(value)]="lowValue"
            *ngIf="highValue !== undefined && highValue !== null"
            [(highValue)]="highValue"
            (valueChange)="onChange()"
            (highValueChange)="onChange()"
            [options]="options"
            [class.disabled]="isDisabled">
</ngx-slider>

<ngx-slider [(value)]="lowValue"
            *ngIf="highValue === undefined || highValue === null"
            (valueChange)="onChange()"
            [options]="options"
            [class.disabled]="isDisabled">
</ngx-slider>
